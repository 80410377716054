import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

const LoadingSpinner = () => {
  return (
    <Box display="flex" justifyContent="center" alignContent="center" my={6}>
      <CircularProgress />
    </Box>
  );
};

export default LoadingSpinner;
