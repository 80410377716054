import { TableCell, TableRow, Typography } from "@mui/material";
import { useClientSWR } from "../client";
import { PMBCompany } from "../client/types";
import { countryFormatter } from "../constants/countries";
import { stateFormatter } from "../constants/statesAndProvinces";
import { DummyRows } from "./dummy-rows";
import { CompanyFetchErrorCard } from "./info-card";
import { NavLinkButton } from "./nav-link-button";
import { TableColumnHeadCell, TableRowHeadCell } from "./table-elements";
import { Pageable, usePageable } from "./use-pageable";
import { ViewTable } from "./view-table";
import { ViewTablePagination } from "./view-table-pagination";

/**
 * A stateless element that renders companies that are passed to it.
 */
export function CompaniesTable(props: {
  companies?: { totalElements?: number; content: PMBCompany[] };
  pageable: Pageable;
}) {
  const { getSortLabelProps, pageSize } = props.pageable;
  const ROW_HEIGHT = 75;

  return (
    <ViewTable
      head={
        <TableRow>
          <TableColumnHeadCell sort={getSortLabelProps("name")} width="20%">
            Name
          </TableColumnHeadCell>

          <TableColumnHeadCell
            sort={getSortLabelProps("address.addressLineOne")}
            width="16%"
          >
            Address
          </TableColumnHeadCell>

          <TableColumnHeadCell
            sort={getSortLabelProps("address.city")}
            width="16%"
          >
            City
          </TableColumnHeadCell>

          <TableColumnHeadCell
            sort={getSortLabelProps("address.state")}
            width="16%"
          >
            State
          </TableColumnHeadCell>

          <TableColumnHeadCell
            sort={getSortLabelProps("address.country")}
            width="16%"
          >
            Country
          </TableColumnHeadCell>

          <TableColumnHeadCell width="16%" />
        </TableRow>
      }
      body={
        !props.companies ? (
          <DummyRows columns={6} rows={pageSize} rowHeight={ROW_HEIGHT} />
        ) : (
          props.companies.content.map((company) => {
            return (
              <TableRow key={company.id} sx={{ height: ROW_HEIGHT }} hover>
                <TableRowHeadCell>
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    component="span"
                  >
                    {company.name}
                  </Typography>
                </TableRowHeadCell>

                <TableCell>
                  {company.address.addressLineOne}
                  <br />
                  {company.address.addressLineTwo}
                </TableCell>

                <TableCell>{company.address.city}</TableCell>
                <TableCell>
                  {stateFormatter.format(company.address.state)}
                </TableCell>
                <TableCell>
                  {countryFormatter.format(company.address.country)}
                </TableCell>

                <TableCell align="right">
                  <NavLinkButton
                    variant="outlined"
                    to={`/company/${company.id}/profile`}
                  >
                    View
                  </NavLinkButton>
                </TableCell>
              </TableRow>
            );
          })
        )
      }
      pagination={
        props.companies && (
          <ViewTablePagination
            {...props.pageable.paginationProps}
            totalElements={props.companies.totalElements}
          />
        )
      }
    />
  );
}

/**
 * A stateful element that performs a request to the backend, handles errors and
 * pending states, and renders a table of companies once ready.
 */
export function CompaniesTableFetch() {
  const pageable = usePageable({ initialSortProp: "name" });
  const { data, error } = useClientSWR("/companies", {
    params: { query: pageable.query },
  });

  if (error) {
    return <CompanyFetchErrorCard error={error} />;
  }

  return <CompaniesTable companies={data} pageable={pageable} />;
}
