// Find the description here: https://rune.ableneo.com/display/PMB/Device%2C+Device+Model%2C+Software+Version

import { PMBSchemas } from "../../client/types";
import { EnumFormatter } from "../../utils/enum-formatter";

export enum DeviceStatusKey {
  LEGACY = "LEGACY",
  DRAFT = "DRAFT",
  PENDING_INSTALLATION = "PENDING_INSTALLATION",
  CONNECTED = "CONNECTED",
  DISABLED = "DISABLED",
}

/**
 * @deprecated Use {@link deviceStatusV2}
 */
export const deviceStatus = {
  [DeviceStatusKey.LEGACY]: "Legacy",
  [DeviceStatusKey.DRAFT]: "Draft",
  [DeviceStatusKey.PENDING_INSTALLATION]: "Installation",
  [DeviceStatusKey.CONNECTED]: "Connected",
  [DeviceStatusKey.DISABLED]: "Disabled",
};

type DeviceStatus = PMBSchemas["Device"]["status"];

export const deviceStatusV2 = new EnumFormatter<DeviceStatus>({
  LEGACY: "Legacy",
  DRAFT: "Draft",
  PENDING_INSTALLATION: "Installation",
  CONNECTED: "Connected",
  DISABLED: "Disabled",
});
