import { Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { NavLink } from "react-router-dom";
import { PMBSchemas } from "../client/types";
import { RoleGuard } from "./role-guard";
import { useLocationSubscription } from "../hooks/useLocationSubscription";
import { ErrorCard } from "./info-card";
import LoadingSpinner from "./loading-spinner";
import { useCompanySubscriptions } from "../hooks/useCompanySubscriptions";
import { EnumMapper } from "../utils/enum-formatter";

type BillingStatusType =
  | PMBSchemas["SubscriptionWarningType"]
  | "PENDING_ACTIVATION"
  | "MULTIPLE_WARNINGS";

const content = {
  warnings: new EnumMapper<
    BillingStatusType,
    { label: string; button: string }
  >(
    {
      PENDING_ACTIVATION: {
        label: "Your subscription is not set up",
        button: "Set up subscription",
      },
      PAYMENT_OVERDUE: {
        label: "Payment on your account is overdue",
        button: "Resolve payment",
      },
      MISSING_PAYMENT_METHOD: {
        label: "Missing payment method",
        button: "Set up payment method",
      },
      MULTIPLE_WARNINGS: {
        label: "Your subscription is not set up correctly",
        button: "View details",
      },
    },
    {
      label: `Unknown warning`,
      button: ``,
    },
  ),
};
/**
 * Stateless component which displays Toast message containing
 * the error message regarding the subscription status.
 */
export function LocationBillingToast(props: { locationId: number }) {
  const { data, error } = useLocationSubscription(props.locationId);

  if (error) {
    return (
      <ErrorCard
        heading="Error, fetch location subscription"
        message={error.message}
      />
    );
  }

  if (!data) {
    return <LoadingSpinner />;
  }

  const pendingSubscription =
    data.status === "PENDING_ACTIVATION" ? data : null;
  if (pendingSubscription) {
    return (
      <BillingToast
        redirectUri={`/location/${props.locationId}/subscription`}
        billingStatus={"PENDING_ACTIVATION"}
      />
    );
  }

  const faultySubscription =
    data.warnings && data.status !== "MANUAL"
      ? { subscription: data, warnings: data.warnings }
      : null;
  if (faultySubscription) {
    return (
      <BillingToast
        redirectUri={`/location/${props.locationId}/subscription`}
        billingStatus={
          faultySubscription.warnings.length > 1
            ? "MULTIPLE_WARNINGS"
            : faultySubscription.warnings[0]
        }
      />
    );
  }

  return null;
}

/**
 * Stateless component which displays Toast message containing
 * the error message regarding the subscription status.
 */
export function CompanyBillingToast(props: { companyId: number }) {
  const { data, error } = useCompanySubscriptions(props.companyId);

  if (error) {
    return (
      <ErrorCard
        heading="Error, fetch company subscriptions"
        message={error.message}
      />
    );
  }

  if (!data) {
    return <LoadingSpinner />;
  }

  const pendingSubscriptions = data.flatMap((sub) =>
    sub.status === "PENDING_ACTIVATION" ? sub : [],
  );
  if (pendingSubscriptions.length > 0) {
    return (
      <BillingToast
        redirectUri={`/company/${props.companyId}/subscriptions`}
        billingStatus={
          pendingSubscriptions.length > 1
            ? "MULTIPLE_WARNINGS"
            : "PENDING_ACTIVATION"
        }
      />
    );
  }

  const faultySubscriptions = data.flatMap((sub) =>
    sub.status !== "MANUAL" &&
    sub.warnings !== undefined &&
    sub.warnings.length > 0
      ? { subscription: sub, warnings: sub.warnings }
      : [],
  );
  if (faultySubscriptions.length > 0) {
    return (
      <BillingToast
        redirectUri={`/company/${props.companyId}/subscriptions`}
        billingStatus={
          faultySubscriptions.length > 1
            ? "MULTIPLE_WARNINGS"
            : faultySubscriptions[0].warnings[0]
        }
      />
    );
  }

  return null;
}

/**
 * Stateless component which will shows the Billing Toast
 * for selected Subscription with specific Status.
 */
function BillingToast(props: {
  billingStatus: BillingStatusType;
  redirectUri: string;
}) {
  const { label, button } = content.warnings.get(props.billingStatus);

  if (!label || !button) {
    return null;
  }

  return (
    <RoleGuard
      allowedRoles={[
        "COMPANY_ADMIN",
        "LOCATION_ADMIN",
        "PMB_ADMIN",
        "LOCATION_VIEWER",
      ]}
    >
      <Box
        sx={{
          display: "flex",
          flexFlow: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "4px",
          p: "8px 36px 8px 16px",
          borderRadius: "12px",
          backgroundColor: "#C54600",
          mb: "32px",
        }}
      >
        <Typography variant="h5" color="white">
          {label}
        </Typography>
        <RoleGuard allowedRoles={["COMPANY_ADMIN"]}>
          <Link
            variant="button"
            sx={{
              padding: "8px",
              border: "1px solid transparent",
              "&:hover": {
                borderRadius: "8px",
                border: "1px solid white",
                textDecoration: "none",
              },
            }}
            component={NavLink}
            to={props.redirectUri}
          >
            <Typography variant="body1" color="white">
              {button}
            </Typography>
          </Link>
        </RoleGuard>
      </Box>
    </RoleGuard>
  );
}
