import styled from "@emotion/styled";
import {
  Box,
  Button as MuiButton,
  Grid,
  Modal,
  Typography as MuiTypography,
} from "@mui/material";
import MuiHighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useFormikContext } from "formik";
import LoadingSpinner from "../ui/loading-spinner";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  p: 4,
  borderRadius: "12px",
  background: "#FFF",
  boxShadow: "1px 1px 14px 2px rgba(0, 0, 0, 0.25)",
  overflowY: "auto",
  maxHeight: "80vh",
};

const Typography = styled(MuiTypography)`
  color: #6b6b6b;
`;

const HighlightOffIcon = styled(MuiHighlightOffIcon)`
  width: 24px;
  color: #6b6b6b;
  cursor: pointer;

  &:hover {
    color: #000;
  }
`;

type BtnType = {
  disabled: boolean;
};

const ModalButton = styled(MuiButton)`
  width: 100%;
  padding: 8px 16px;
  align-items: center;
  border-radius: 4px;
  background: #e0e0e0;
  color: #000000de;
`;

const DeleteButton = styled(MuiButton)`
  width: 100%;
  padding: 8px 16px;
  align-items: center;
  border-radius: 4px;
  background: #c54600;
  color: #e0e0e0;

  &:hover {
    color: #c54600;
  }
`;

const ModalActionButton = styled(ModalButton)<BtnType>`
  background: ${(props) => (props.disabled ? "#e0e0e0" : "#2D51FF")};
  color: ${(props) => (props.disabled ? "#000" : "#fff")};
  border: 1px solid transparent;

  &:hover {
    color: #2d51ff;
    border: 1px solid #2d51ff;
  }
`;

const FormikModal = (props: {
  children: string | JSX.Element | JSX.Element[];
  title: string;
  submitBtnText: string;
  open: boolean;
  handleClose: () => void;
  handleDelete?: () => void;
}) => {
  const { touched, isValid, errors, dirty, handleSubmit, isSubmitting } =
    useFormikContext();

  const formikValid = touched && isValid && errors && dirty;

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit}>
          <Box sx={style}>
            <Grid container spacing={4} p={4}>
              <Grid container item justifyContent={"space-between"} p={2}>
                <Grid item xs={11}>
                  <Typography id="modal-modal-title" variant="h5">
                    {props.title}
                  </Typography>
                </Grid>
                <Grid item xs={1} textAlign={"right"}>
                  <HighlightOffIcon onClick={props.handleClose} />
                </Grid>
              </Grid>

              <Grid container xs={12} item p={2} mb={6}>
                {props.children}
              </Grid>

              <Grid container item spacing={4} p={2}>
                <Grid
                  item
                  sx={{
                    width: {
                      lg: "250px",
                      xs: "100%",
                    },
                  }}
                >
                  {isSubmitting ? (
                    <LoadingSpinner />
                  ) : (
                    <ModalActionButton
                      type="submit"
                      disabled={!formikValid || isSubmitting}
                    >
                      {props.submitBtnText}
                    </ModalActionButton>
                  )}
                </Grid>
                <Grid
                  item
                  sx={{
                    width: {
                      lg: "250px",
                      xs: "100%",
                    },
                  }}
                >
                  <ModalButton onClick={props.handleClose}>Cancel</ModalButton>
                </Grid>
                {props.handleDelete ? (
                  <Grid
                    item
                    sx={{
                      width: {
                        lg: "250px",
                        xs: "100%",
                      },
                    }}
                  >
                    <DeleteButton
                      onClick={() => {
                        props.handleDelete?.();
                        props.handleClose();
                      }}
                    >
                      Delete
                    </DeleteButton>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Box>
        </form>
      </Modal>
    </>
  );
};

export default FormikModal;
