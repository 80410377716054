import { Clear, FilterAlt } from "@mui/icons-material";
import { Box, ButtonGroup, IconButton, Paper, Typography } from "@mui/material";
import { ReactNode } from "react";
import { DialogButton, RenderDialogFn } from "./dialog-button";

export function FilterCard(props: {
  filterActive: boolean;
  initialStateLabel: string;
  onClearFilters: () => void;
  renderFilterDialog: RenderDialogFn;
  renderExportDialog?: RenderDialogFn;
  children: ReactNode;
}) {
  const filterDialogButton = (
    <DialogButton
      variant="outlined"
      sx={{ minWidth: "6rem" }}
      renderDialog={props.renderFilterDialog}
    >
      Filter
      <FilterAlt sx={{ marginLeft: 1 }} fontSize="small" />
    </DialogButton>
  );

  return (
    <Paper
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr auto",
        alignItems: "center",
        gap: 2,
        marginBottom: 4,
        padding: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Typography
          variant="body2"
          component="span"
          fontWeight="bold"
          sx={{ marginRight: 2 }}
        >
          {props.filterActive ? "Active filters:" : props.initialStateLabel}
        </Typography>

        {props.children}
      </Box>

      <Box sx={{ display: "flex", gap: 2, alignSelf: "start" }}>
        {props.filterActive && (
          <IconButton onClick={props.onClearFilters}>
            <Clear fontSize="small" />
          </IconButton>
        )}

        {!props.renderExportDialog ? (
          filterDialogButton
        ) : (
          <ButtonGroup>
            <DialogButton
              variant="outlined"
              renderDialog={props.renderExportDialog}
            >
              Export
            </DialogButton>

            {filterDialogButton}
          </ButtonGroup>
        )}
      </Box>
    </Paper>
  );
}
