import { Error as ErrorIcon } from "@mui/icons-material";
import {
  Button,
  ButtonProps,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { SetupIntent } from "@stripe/stripe-js";
import { FormEventHandler, useCallback, useEffect, useState } from "react";
import { unpackResponse, useClient } from "../client";
import { stripe } from "../stripe";
import { caughtValueToString } from "../utils/caught-error";
import { localStorageKeys, setLocalStorage } from "../utils/local-storage";
import { waitForStripeWebhooks } from "../utils/prototyping";
import { FormDialog } from "./form-dialog";
import { useAsyncState } from "./use-async-state";
import { useDialogState } from "./use-dialog-state";

export function getStripeReturnUrl(companyId: number) {
  return `${window.location.origin}/company/${companyId}/payment-methods/verify`;
}

export async function post(url: string, body: unknown = {}): Promise<any> {
  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw new Error("Response failed.");
  }

  return response.json();
}

function useStripeForm(props: {
  onSuccess: (setupIntent: SetupIntent) => Promise<void>;
  returnUrl: string;
}) {
  const { onSuccess, returnUrl } = props;
  const { isPending, setStateFromPromise, error } =
    useAsyncState<SetupIntent>();

  const elements = useElements();
  const stripe = useStripe();

  const handleSubmit: FormEventHandler = useCallback(
    async (event) => {
      event.preventDefault();

      async function confirmSetup() {
        if (!stripe || !elements) {
          throw new Error("Stripe or Elements instances not initialized.");
        }

        const { setupIntent, error } = await stripe.confirmSetup({
          elements,
          redirect: "if_required",
          confirmParams: {
            // If redirect is required, this will be the destination to which our
            // app will get redirected to.
            return_url: returnUrl,
          },
        });

        if (error) {
          throw error.message;
        }

        return setupIntent;
      }

      async function performSetup() {
        const setupIntent = await confirmSetup();
        await onSuccess(setupIntent);
        return setupIntent;
      }

      try {
        await setStateFromPromise(performSetup());
      } catch {}
    },
    [elements, onSuccess, returnUrl, setStateFromPromise, stripe],
  );

  return {
    status: typeof error === "string" ? error : undefined,
    isSubmitting: isPending,
    handleSubmit,
  };
}

function AddPaymentMethodDialog(props: {
  companyId: number;
  isOpen: boolean;
  stripeReturnUrl: string;
  onClose: () => void;
  onSuccess: () => void;
}) {
  const client = useClient();
  const form = useStripeForm({
    returnUrl: props.stripeReturnUrl,
    onSuccess: async (setupIntent) => {
      await Promise.all([
        waitForStripeWebhooks(),
        unpackResponse(
          client.PATCH(
            "/companies/{companyId}/setup-intents/{setupIntentId}/confirm",
            {
              params: {
                path: {
                  companyId: props.companyId,
                  setupIntentId: setupIntent.id,
                },
              },
            },
          ),
        ),
      ]);

      props.onSuccess();
      props.onClose();
    },
  });

  return (
    <FormDialog
      {...props}
      padding={10}
      maxWidth="sm"
      title="Set up payment method"
      submitLabel="Save"
      buttonVariant="contained"
      form={form}
    >
      <Typography sx={{ marginBottom: 8 }}>
        Note that 3% processing fee with be applied for credit card payments.
      </Typography>
      <PaymentElement />
    </FormDialog>
  );
}

export function SetUpPaymentMethodFetch(props: {
  companyId: number;
  stripeReturnUrl: string;
  onSuccess: () => void;
  onClose?: () => void;
}) {
  const client = useClient();
  const { companyId } = props;
  const { openDialog, ...dialogState } = useDialogState();
  const { data, error, isPending, setStateFromPromise } = useAsyncState<{
    clientSecret?: string;
  }>();

  const fetchSetupIntentAndOpenDialog = useCallback(async () => {
    const promise = unpackResponse(
      client.POST("/companies/{companyId}/setup-intents", {
        params: { path: { companyId } },
      }),
    );

    try {
      await setStateFromPromise(promise);
      openDialog();
    } catch (error) {
      console.error(error);
    }
  }, [client, companyId, openDialog, setStateFromPromise]);

  useEffect(() => {
    fetchSetupIntentAndOpenDialog();
  }, [fetchSetupIntentAndOpenDialog, props]);

  // Trigger close on modal and set local storage value for global info
  const handleClose = () => {
    dialogState.onClose();
    props.onClose?.();
    setLocalStorage(localStorageKeys.get("PAYMENT_SETUP_DIALOG_TRIGGERED"), 1);
  };

  return (
    <>
      {dialogState.isOpen && data?.clientSecret && (
        <Elements
          stripe={stripe}
          options={{
            clientSecret: data.clientSecret,
            appearance: { theme: "stripe" },
          }}
        >
          <AddPaymentMethodDialog
            {...dialogState}
            {...props}
            onClose={handleClose}
          />
        </Elements>
      )}
      {isPending ? (
        <CircularProgress size="1em" sx={{ marginRight: 4 }} />
      ) : error ? (
        <Tooltip title={caughtValueToString(error)}>
          <ErrorIcon color="error" sx={{ marginRight: 2 }} />
        </Tooltip>
      ) : null}
    </>
  );
}

export function SetUpPaymentMethodButton(
  props: Omit<ButtonProps, "onClick"> & {
    companyId: number;
    stripeReturnUrl: string;
    onPaymentMethodAdded: () => void;
  },
) {
  const { companyId, onPaymentMethodAdded, stripeReturnUrl, ...buttonProps } =
    props;
  const [showPaymentMethodDialog, setShowPaymentMethodDialog] = useState(false);

  return (
    <>
      {showPaymentMethodDialog && (
        <SetUpPaymentMethodFetch
          onClose={() => setShowPaymentMethodDialog(false)}
          companyId={props.companyId}
          onSuccess={props.onPaymentMethodAdded}
          stripeReturnUrl={stripeReturnUrl}
        />
      )}

      <Button {...buttonProps} onClick={() => setShowPaymentMethodDialog(true)}>
        Add payment method
      </Button>
    </>
  );
}
