import { Typography } from "@mui/material";
import { useFormik } from "formik";
import { object, string } from "yup";
import { unpackResponse, useClient } from "../client";
import { PMBSchemas } from "../client/types";
import { caughtValueToString } from "../utils/caught-error";
import { formatDate } from "../utils/formatters";
import { FormDialog } from "./form-dialog";
import {
  RadioGroupField,
  RadioGroupOption,
  getRadioGroupFieldProps,
} from "./radio-group-field";

export function PaymentFrequencyDialog(props: {
  onClose: () => void;
  isOpen: boolean;
  location: PMBSchemas["LocationBase"];
  subscription: PMBSchemas["Subscription"];
  revalidateView: () => void;
}) {
  const { subscription, location } = props;
  const client = useClient();

  const formik = useFormik({
    initialValues: {
      paymentFrequency: subscription.paymentFrequency,
    },
    validationSchema: object().shape({
      paymentFrequency: string().required(),
    }),
    async onSubmit(values) {
      try {
        await unpackResponse(
          client.PATCH("/locations/{locationId}/subscription/confirm", {
            params: { path: { locationId: location.id } },
            body: values,
          }),
        );

        props.revalidateView();
        props.onClose();
      } catch (error) {
        formik.setStatus(caughtValueToString(error));
      }
    },
  });

  return (
    <FormDialog
      {...props}
      padding={10}
      title="Change payment frequency"
      submitLabel="Save"
      buttonVariant="contained"
      form={formik}
    >
      <RadioGroupField
        {...getRadioGroupFieldProps(formik, "paymentFrequency")}
        label="New payment frequency"
      >
        <RadioGroupOption<PMBSchemas["Subscription"]["paymentFrequency"]>
          value="MONTHLY"
          label="Monthly payments"
        />
        <RadioGroupOption<PMBSchemas["Subscription"]["paymentFrequency"]>
          value="ANNUAL"
          label="Annual payments - save 10%💡"
        />
      </RadioGroupField>

      <Typography sx={{ marginTop: 8 }}>
        {`Selected payment frequency will apply from the next subscription period`}
        {props.subscription.nextPaymentDate
          ? `, which starts ${formatDate(
              props.subscription.nextPaymentDate,
              "long",
            )}.`
          : "."}
      </Typography>
    </FormDialog>
  );
}
