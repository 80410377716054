import { Card as MuiCard, styled } from "@mui/material";

interface PmbCardProps {
  editable?: boolean;
  illustration?: string;
}

const PmbCard = styled(MuiCard)<PmbCardProps>`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  position: relative;
  border: 1px solid transparent;
  border-radius: 12px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);

  cursor: ${({ editable }) => (editable ? "pointer" : "default")};

  &:hover {
    border: ${({ editable }) =>
      editable ? "1px solid #2d51ff" : "1px solid transparent"};
  }
`;

export default PmbCard;
