import { useCallback } from "react";
import { PMBSchemas } from "../client/types";
import useSWR from "swr";
import { unpackResponse, useClient } from "../client";
import { useUserInfo } from "../ui/user-info-context";

/**
 * Custom SWR for Subscription Form.
 *
 * This SWR is very custom with set of undefined props.
 * We need to get the data for the Subscription Form as simple
 * as possible, so there is this SWR which is handling all
 * the necessary fetching.
 */
export const useSubscriptionSWR = (props: {
  locationId: number;
  subscription: PMBSchemas["Subscription"];
}) => {
  const client = useClient();
  const { principal } = useUserInfo();

  // The fetcher function has to be stabilized with useCallback to make
  // sure it doesn't change on every render.
  const fetcher = useCallback(async (): Promise<
    PMBSchemas["PaymentStructure"]
  > => {
    const customPaymentStructure = {
      paymentFrequency: props.subscription.paymentFrequency,
      discountType: props.subscription.discountType,
      discountValidTo: props.subscription.discountValidTo,
      percentageDiscount: props.subscription.percentageDiscount,
      lumpsumDiscount: props.subscription.lumpsumDiscount,
      accountsManagement: props.subscription.accountsManagement,
    };

    const data = await unpackResponse(
      client.POST("/locations/{locationId}/custom-payment-structure", {
        params: { path: { locationId: props.locationId } },
        body: customPaymentStructure,
      }),
    );
    return data;
  }, [client, props.locationId, props.subscription]);

  const swrKey = `subscription-${principal.id}-${props.locationId ?? "0"}`;

  return useSWR(swrKey, fetcher);
};
