import { useClientSWR } from "../client";

export const useCompanyPaymentMethods = (props: { companyId: number }) => {
  const { data, error, mutate } = useClientSWR(
    "/companies/{companyId}/payment-methods",
    {
      params: { path: { companyId: props.companyId } },
    },
  );

  return {
    data,
    error,
    mutate,
  };
};
