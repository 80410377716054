import { EnumFormatter } from "./enum-formatter";

// Function to set data in local storage
export const setLocalStorage = (key: string, data: any) => {
  localStorage.setItem(key, JSON.stringify(data));
};

// Function to get data from local storage
export const getLocalStorage = (key: string): any => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

// Function to remove data from local storage
export const removeLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

// Local storage keys
type LocalStorageKeyType = "PAYMENT_SETUP_DIALOG_TRIGGERED";
export const localStorageKeys = new EnumFormatter<LocalStorageKeyType>({
  PAYMENT_SETUP_DIALOG_TRIGGERED: "PAYMENT_SETUP_DIALOG_TRIGGERED",
});

// Remove custom data from local storage
export const clearLocalStorage = () => {
  localStorageKeys.enums.forEach((key) => removeLocalStorage(key));
};
