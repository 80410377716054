import { MoreHoriz } from "@mui/icons-material";
import { IconButton, SxProps, Theme } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useId,
  useState,
} from "react";

export type DialogState = {
  isOpen: boolean;
  onClose: () => void;
};

type RenderDialogFn = (dialogProps: DialogState) => ReactElement;

function noop() {
  console.warn("You have called a noop function.");
}

const MoreMenuContext =
  createContext<(renderDialog: RenderDialogFn) => void>(noop);

type MoreMenuDialogState =
  | { isOpen: true; render: RenderDialogFn }
  | { isOpen: false };

export function MoreMenu(props: {
  children: ReactNode;
  renderButton?: (props: {
    id: string;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    "aria-controls": string;
    "aria-haspopup": boolean;
    "aria-expanded": boolean;
  }) => ReactElement;
}) {
  const [dialog, setRenderDialog] = useState<MoreMenuDialogState>({
    isOpen: false,
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const openDialog = (render: RenderDialogFn) => {
    setRenderDialog({ isOpen: true, render });
    closeMenu();
  };

  const closeDialog = () => {
    setRenderDialog({ isOpen: false });
  };

  const buttonId = useId();
  const menuId = useId();

  const buttonProps = {
    id: buttonId,
    "aria-controls": menuId,
    "aria-haspopup": true,
    "aria-expanded": open,
    onClick: openMenu,
  };

  return (
    <MoreMenuContext.Provider value={openDialog}>
      {dialog?.isOpen && dialog.render({ isOpen: true, onClose: closeDialog })}

      {props.renderButton ? (
        props.renderButton(buttonProps)
      ) : (
        <IconButton {...buttonProps} aria-label="Actions">
          <MoreHoriz />
        </IconButton>
      )}

      <Menu
        id={menuId}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        MenuListProps={{
          "aria-labelledby": buttonId,
          sx: { minWidth: "12rem" },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
      >
        {props.children}
      </Menu>
    </MoreMenuContext.Provider>
  );
}

export function MoreMenuDialogItem(props: {
  children: string;
  renderDialog: RenderDialogFn;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}) {
  const { renderDialog, ...pass } = props;
  const setOpen = useContext(MoreMenuContext);
  return <MenuItem onClick={() => setOpen(renderDialog)} {...pass} />;
}
