import { Box, FormControl, FormLabel } from "@mui/material";
import { ReactNode } from "react";

export function ChipsGroup(props: { label: string; children: ReactNode }) {
  return (
    <FormControl>
      <FormLabel sx={{ marginBottom: 2, color: "info.main", fontSize: 12 }}>
        {props.label}
      </FormLabel>

      <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
        {props.children}
      </Box>
    </FormControl>
  );
}
