import { AccountBalance } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Paper,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useFormik } from "formik";
import { ChangeEventHandler } from "react";
import { Link, useParams } from "react-router-dom";
import { unpackResponse, useClient, useClientSWR } from "../../client";
import { PMBSchemas } from "../../client/types";
import { caughtValueToString } from "../../utils/caught-error";
import {
  SetUpPaymentMethodButton,
  getStripeReturnUrl,
} from "../add-payment-method-dialog";
import { ConfirmDialog } from "../confirm-dialog";
import { FormDialog } from "../form-dialog";
import { CompanyFetchErrorCard } from "../info-card";
import LoadingSpinner from "../loading-spinner";
import { MoreMenu, MoreMenuDialogItem } from "../more-menu";
import { ViewHeader } from "../view-header";
import {
  brandIcon,
  getBankAccountName,
  getCardName,
} from "./stripe-brand-formatter";
import { waitForStripeWebhooks } from "../../utils/prototyping";

function CheckboxField(props: {
  label: string;
  checked: boolean;
  name: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={props.checked}
          onChange={props.onChange}
          name={props.name}
        />
      }
      label={props.label}
    />
  );
}

function RemovePaymentMethodDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  revalidateView: () => void;
  companyId: number;
  paymentMethod: PMBSchemas["PaymentMethod"];
  paymentMethodName: string;
}) {
  const { companyId, paymentMethod } = props;
  const client = useClient();

  return (
    <ConfirmDialog
      {...props}
      title={`Remove ${props.paymentMethodName}?`}
      description={getDescription(paymentMethod)}
      submitLabel="Remove"
      submitColor="error"
      onSubmit={async () => {
        await Promise.all([
          waitForStripeWebhooks(),
          unpackResponse(
            client.DELETE(
              "/companies/{companyId}/payment-methods/{paymentMethodId}",
              {
                params: {
                  path: { companyId, paymentMethodId: paymentMethod.id },
                },
              },
            ),
          ),
        ]);

        props.revalidateView();
      }}
    />
  );
}

type FormValues = {
  isCompanyDefault: boolean;
  isLocationOverrideFor: Record<number, boolean>;
};

function getDescription(paymentMethod: PMBSchemas["PaymentMethod"]) {
  if (paymentMethod.isCompanyDefault) {
    return `You are about to remove the company default payment method. Unless another company default is configured or location-specific payment methods are set, your payments may not be correctly processed.`;
  }

  if ((paymentMethod.isLocationOverrideFor?.length ?? 0) > 0) {
    return `You are about to remove a payment method that is used by one or more locations. Once removed, your company default payment method will be used to process future payments.`;
  }

  return `This payment method is currently not used and can be safely removed.`;
}

function valuesToPayload(
  values: FormValues,
): PMBSchemas["UpdatePaymentMethodRequest"] {
  const isLocationOverrideFor: number[] = [];
  for (const key in values.isLocationOverrideFor) {
    if (values.isLocationOverrideFor[key]) {
      isLocationOverrideFor.push(Number(key));
    }
  }

  return {
    isCompanyDefault: values.isCompanyDefault,
    isLocationOverrideFor,
  };
}

function ManagePaymentMethodDialog(props: {
  paymentMethodName: string;
  isOpen: boolean;
  onClose: () => void;
  companyId: number;
  paymentMethod: PMBSchemas["PaymentMethod"];
  revalidateView: () => void;
  locations: PMBSchemas["LocationBase"][];
}) {
  const { paymentMethod, companyId } = props;
  const locationOverridesIds =
    paymentMethod.isLocationOverrideFor?.map((l) => l.id) ?? [];
  const client = useClient();

  const formik = useFormik<FormValues>({
    initialValues: {
      isCompanyDefault: paymentMethod.isCompanyDefault,
      isLocationOverrideFor: Object.fromEntries(
        props.locations.map((location) => {
          return [location.id, locationOverridesIds.includes(location.id)];
        }),
      ),
    },
    async onSubmit(values) {
      try {
        const paymentMethodId = paymentMethod.id;
        const body = valuesToPayload(values);

        await unpackResponse(
          client.PUT(
            "/companies/{companyId}/payment-methods/{paymentMethodId}",
            { params: { path: { companyId, paymentMethodId } }, body },
          ),
        );

        props.revalidateView();
        props.onClose();
      } catch (error) {
        formik.setStatus(caughtValueToString(error));
      }
    },
  });
  return (
    <FormDialog
      {...props}
      title={`Manage ${props.paymentMethodName}`}
      submitLabel="Save"
      form={formik}
    >
      <Stack sx={{ paddingTop: 2 }} gap={5}>
        <div>
          <Typography
            variant="body1"
            fontWeight={700}
            marginBottom={1}
            component="h3"
          >
            Company Default
          </Typography>
          <Typography variant="body2">
            Unless otherwise specified, all location subscriptions are settled
            using the company default payment method.
          </Typography>
          <Box sx={{ marginTop: 2 }}>
            <CheckboxField
              label="Use as company default payment method"
              checked={formik.values.isCompanyDefault}
              name="isCompanyDefault"
              onChange={() =>
                formik.setFieldValue(
                  "isCompanyDefault",
                  !formik.values.isCompanyDefault,
                )
              }
            />
          </Box>
        </div>

        <div>
          <Typography
            variant="body1"
            fontWeight={700}
            marginBottom={1}
            component="h3"
          >
            Location Overrides
          </Typography>
          <Typography variant="body2">
            If you wish to use this card instead of the company default for one
            or more locations, you may select them below.
          </Typography>
          <Stack sx={{ marginTop: 2 }}>
            {props.locations.map((location) => {
              const formikKey = `isLocationOverrideFor.${location.id}`;
              const checked = formik.values.isLocationOverrideFor[location.id];
              return (
                <CheckboxField
                  key={formikKey}
                  label={`Use for ${location.name}`}
                  name={formikKey}
                  checked={checked}
                  onChange={() => formik.setFieldValue(formikKey, !checked)}
                />
              );
            })}
          </Stack>
        </div>
      </Stack>
    </FormDialog>
  );
}

type StripePaymentMethod = NonNullable<
  PMBSchemas["PaymentMethod"]["stripePaymentMethod"]
>;

function getPaymentMethodIdentifiers(paymentMethod: StripePaymentMethod) {
  if (paymentMethod.type === "card" && paymentMethod.card) {
    const card = paymentMethod.card;

    return {
      name: getCardName(card),
      hint: `Expires ${card.exp_month}/${card.exp_year}`,
      imgUrl: brandIcon.get(card.brand),
    };
  }

  if (
    paymentMethod.type === "us_bank_account" &&
    paymentMethod.us_bank_account
  ) {
    const account = paymentMethod.us_bank_account;

    return {
      name: getBankAccountName(account),
      hint: `Authorized transfer`,
      imgUrl: `/static/cards/card.png`,
    };
  }

  return {
    name: `Unknown payment method type`,
    hint: "",
    imgUrl: "",
  };
}

function CompanyPaymentMethodsView(props: {
  company: PMBSchemas["Company"];
  paymentMethodsInfo: PMBSchemas["PaymentMethodsResponse"];
  companyLocations: PMBSchemas["CompanyLocationPage"];
  revalidateView: () => void;
}) {
  const { paymentMethods = [], setupIntentVerificationRequests = [] } =
    props.paymentMethodsInfo;

  const breadcrumb = [
    {
      label: "Companies",
      url: `/company/list`,
    },
    {
      label: props.company.name,
      url: `/company/${props.company.id}/profile`,
    },
    {
      label: "Payment Methods",
    },
  ];

  return (
    <>
      <ViewHeader
        title={`${props.company.name} Payment Methods`}
        breadcrumb={breadcrumb}
        button={
          <SetUpPaymentMethodButton
            companyId={props.company.id}
            stripeReturnUrl={getStripeReturnUrl(props.company.id)}
            onPaymentMethodAdded={props.revalidateView}
            variant="contained"
          />
        }
      />

      <Stack gap={2}>
        {setupIntentVerificationRequests?.map((verificationRequest) => {
          return (
            <Paper>
              <Alert
                key={verificationRequest.created}
                icon={<AccountBalance fontSize="small" />}
                severity="info"
                action={
                  <Button
                    component="a"
                    size="small"
                    variant="contained"
                    color="success"
                    target="_blank"
                    rel="noreferrer noopener"
                    href={verificationRequest.hosted_verification_url!}
                    sx={{ marginTop: "-2px" }}
                  >
                    Verify payment method
                  </Button>
                }
              >
                You have a pending payment method verification.
              </Alert>
            </Paper>
          );
        })}

        {paymentMethods.map((paymentMethod) => {
          const { name, hint, imgUrl } = getPaymentMethodIdentifiers(
            paymentMethod.stripePaymentMethod!,
          );

          const hasChipsSection =
            paymentMethod.isCompanyDefault ||
            (paymentMethod.isLocationOverrideFor?.length ?? 0) > 0;

          return (
            <Paper
              sx={{ padding: 4 }}
              key={paymentMethod.stripePaymentMethod.id!}
            >
              <Stack direction={"row"} alignItems={"center"} gap={4}>
                <img
                  src={imgUrl}
                  width="324"
                  height="228"
                  alt=""
                  style={{
                    borderRadius: "3px",
                    width: "4rem",
                    height: "auto",
                  }}
                />

                <div>
                  <Typography variant="body1">{name}</Typography>
                  <Typography variant="body2">{hint}</Typography>
                </div>

                <Box sx={{ marginLeft: "auto" }}>
                  <MoreMenu>
                    <MoreMenuDialogItem
                      renderDialog={(dialogProps) => (
                        <ManagePaymentMethodDialog
                          {...dialogProps}
                          companyId={props.company.id}
                          paymentMethodName={name}
                          locations={props.companyLocations.content}
                          paymentMethod={paymentMethod}
                          revalidateView={props.revalidateView}
                        />
                      )}
                    >
                      Manage
                    </MoreMenuDialogItem>

                    <Divider />

                    <MoreMenuDialogItem
                      renderDialog={(dialogProps) => (
                        <RemovePaymentMethodDialog
                          {...dialogProps}
                          paymentMethod={paymentMethod}
                          paymentMethodName={name}
                          revalidateView={props.revalidateView}
                          companyId={props.company.id}
                        />
                      )}
                    >
                      Remove
                    </MoreMenuDialogItem>
                  </MoreMenu>
                </Box>
              </Stack>

              {hasChipsSection && (
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  gap={2}
                  sx={{
                    marginTop: 4,
                    paddingTop: 4,
                    borderTop: "1px solid hsl(0 0% 90%)",
                  }}
                >
                  {paymentMethod.isCompanyDefault && (
                    <Chip color="primary" label="Company Default" />
                  )}

                  {paymentMethod.isLocationOverrideFor?.map((location) => {
                    return (
                      <Link
                        to={`/location/${location.id}/subscription`}
                        key={location.id}
                      >
                        <Chip label={`Used for: ${location.name}`} />
                      </Link>
                    );
                  })}
                </Stack>
              )}
            </Paper>
          );
        })}
      </Stack>
    </>
  );
}

export function CompanyPaymentMethodsFetch() {
  const params = useParams<"companyId">();
  const companyId = Number(params.companyId);

  const {
    data: company,
    error: companyError,
    mutate: companyMutate,
  } = useClientSWR("/companies/{companyId}", {
    params: { path: { companyId } },
  });
  const {
    data: paymentMethodsInfo,
    error: paymentMethodsError,
    mutate: paymentMethodsMutate,
  } = useClientSWR("/companies/{companyId}/payment-methods", {
    params: { path: { companyId } },
  });
  const {
    data: companyLocations,
    error: locationsError,
    mutate: locationsMutate,
  } = useClientSWR("/companies/{companyId}/locations", {
    params: { path: { companyId }, query: { page_size: 1000 } },
  });

  if (company && paymentMethodsInfo && companyLocations) {
    return (
      <CompanyPaymentMethodsView
        company={company}
        paymentMethodsInfo={paymentMethodsInfo}
        companyLocations={companyLocations}
        revalidateView={() => {
          companyMutate();
          paymentMethodsMutate();
          locationsMutate();
        }}
      />
    );
  }

  const error = companyError || paymentMethodsError || locationsError;
  if (error) {
    return <CompanyFetchErrorCard error={error} />;
  }

  return <LoadingSpinner />;
}
