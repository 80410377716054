import { unpackResponse, useClient } from "../client";
import { ConfirmDialog } from "./confirm-dialog";

export function SettleManuallyDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  paymentId: number;
}) {
  const client = useClient();
  const { onSuccess, isOpen, onClose } = props;

  return (
    <ConfirmDialog
      title="Settle manually?"
      description={`⚠️ Warning: Are you sure that you want to proceed and mark this payment request as manually settled? An invoice will be created and attempts to charge the customer account for this payment request will be stopped`}
      isOpen={isOpen}
      submitLabel="Settle manually"
      submitColor="error"
      onClose={onClose}
      onSubmit={async () => {
        await Promise.all([
          unpackResponse(
            client.PATCH("/payments/{paymentId}/settle-manually", {
              params: { path: { paymentId: props.paymentId } },
            }),
          ),
        ]);

        onSuccess();
      }}
    />
  );
}
