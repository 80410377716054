import { Breadcrumbs, Link, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useUserInfo } from "../ui/user-info-context";
import { includesAnyOf } from "../utils/one-of";

export type BreadcrumbData = {
  label: string;
  url?: string;
};

export function PmbBreadcrumb(props: { data?: BreadcrumbData[] }) {
  const { roles } = useUserInfo();
  let crumbData = props.data;

  if (crumbData) {
    if (includesAnyOf(roles, ["LOCATION_ADMIN", "LOCATION_VIEWER"])) {
      /**
       * Location user can't see the link for the "Locations"
       * nor the location name itself in the Breadcrumbs.
       */
      if (
        crumbData.some((crumb) => crumb.label === "Locations") &&
        crumbData.length >= 2
      ) {
        crumbData.shift();
        crumbData.shift();
      }
    }

    if (includesAnyOf(roles, ["COMPANY_ADMIN"])) {
      /**
       * Company user can't see the link for the "Companies"
       * nor the name of the company in the Breadcrumbs.
       */
      if (crumbData.some((crumb) => crumb.label === "Companies")) {
        crumbData = [];
      }

      /**
       * Company user can't see the link for the "Locations"
       * in the Breadcrumbs.
       */
      crumbData = crumbData.filter((crumb) => crumb.label !== "Locations");
    }

    const crumbs = crumbData.map((crumb) => {
      if (crumb.url) {
        return (
          <Link
            key={crumb.label}
            variant="caption"
            component={NavLink}
            to={crumb.url}
          >
            {crumb.label}
          </Link>
        );
      } else {
        return (
          <Typography key={crumb.label} variant="caption">
            {crumb.label}
          </Typography>
        );
      }
    });

    return (
      <Breadcrumbs aria-label="Breadcrumb" sx={{ marginBottom: 4 }}>
        <Link key="dashboard" variant="caption" component={NavLink} to="/">
          Home
        </Link>
        {crumbs}
      </Breadcrumbs>
    );
  }

  return (
    <Breadcrumbs aria-label="Breadcrumb" sx={{ marginBottom: 4 }}>
      <Link key="dashboard" variant="caption" component={NavLink} to="/">
        Home
      </Link>
    </Breadcrumbs>
  );
}
