import {
  Autocomplete,
  SxProps,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { FormikValues, useFormik } from "formik";
import { FocusEventHandler, SyntheticEvent } from "react";

/**
 * Retrieves props that are suitable for use in Autocomplete fields
 *
 * Notably, this field getter encapsulates logic regarding visibility of
 * errors and helper text, as well as a custom onChange handler that fits
 * the type signature of the MUI Autocomplete component.
 */
export function getAutocompleteSelectFieldProps<
  T extends FormikValues,
  Name extends string & keyof T,
>(formik: ReturnType<typeof useFormik<T>>, name: Name) {
  const wasTouched = formik.touched[name];
  const validationHint = formik.errors[name];

  return {
    name,
    value: formik.values[name],
    onBlur: () => formik.setFieldTouched(name),
    onChange: (
      _event: SyntheticEvent<Element, Event>,
      value: T[Name] | null | undefined,
    ) => {
      formik.setFieldValue(name, value ? value : null);
    },
    error: !!(wasTouched && validationHint),
    helperText: wasTouched && validationHint,
  };
}

export function AsyncAutocompleteSelectField<
  T extends { label: string },
>(props: {
  value: T | null;
  options?: T[];
  onChange: (
    event: SyntheticEvent<Element, Event>,
    value: T | null | undefined,
  ) => void;
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  name: string;
  label: string;
  error: boolean;
  placeholder: string;
  loadingText: string;
  helperText?: string;
  disabled?: boolean;
  sx?: SxProps;
}) {
  const textFieldProps: TextFieldProps = {
    name: props.name,
    value: props.value,
    label: props.label,
    helperText: props.helperText,
    InputLabelProps: { shrink: true },
    variant: "standard",
    fullWidth: true,
    error: props.error,
  };

  if (!props.options) {
    return (
      <TextField {...textFieldProps} disabled placeholder={props.loadingText} />
    );
  }

  return (
    <Autocomplete
      value={props.value}
      options={props.options}
      getOptionLabel={(option) => option.label}
      onChange={props.onChange}
      disabled={props.disabled}
      sx={props.sx}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            {...textFieldProps}
            onBlur={props.onBlur}
            placeholder={props.placeholder}
          />
        );
      }}
    />
  );
}
