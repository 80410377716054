import {
  AccountInfo,
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { b2cConfig } from "../config";

interface AccountContextInterface {
  account: AccountInfo | null;
  token: string;
  logout: () => void;
}

const AccountContext = createContext<AccountContextInterface | null>(null);

export function AccountProvider({ children }: { children: ReactNode }) {
  const { instance, inProgress, accounts } = useMsal();
  const [account] = accounts;
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const logout = useCallback(async () => {
    try {
      await instance.logoutRedirect({
        account: instance.getActiveAccount(),
      });
    } catch (error) {
      console.error("Error during logout:", error);
    }
  }, [instance]);

  useEffect(() => {
    if (!accessToken && inProgress === InteractionStatus.None) {
      const accessTokenRequest = {
        scopes: b2cConfig.scopes,
        account,
      };

      instance
        .acquireTokenSilent(accessTokenRequest)
        .then(
          ({ accessToken }) => {
            setAccessToken(accessToken);
            return accessToken;
          },
          (error: unknown) => {
            if (error instanceof InteractionRequiredAuthError) {
              return instance
                .acquireTokenPopup(accessTokenRequest)
                .then(({ accessToken }) => {
                  setAccessToken(accessToken);
                  return accessToken;
                });
            }

            return Promise.reject(error);
          },
        )
        .catch((error) => console.error(error));
    }
  }, [instance, account, inProgress, accessToken, logout]);

  return (
    <AccountContext.Provider
      value={!accessToken ? null : { account, token: accessToken, logout }}
    >
      {accessToken
        ? children
        : "Please wait while getting authentication token ..."}
    </AccountContext.Provider>
  );
}

export const useMsalAccount = (): AccountContextInterface => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error("useAccount must be used within an AccountProvider");
  }
  return context;
};
