import { DatePicker } from "@mui/x-date-pickers";
import { FormikValues, useFormik } from "formik";

const INVALID_MARKER = "INVALID";

/**
 * Checks whether a date instance is a valid date.
 *
 * @example
 * ```ts
 * const invalidDate = new Date("foo");
 * isValidDate(invalidDate) // false
 *
 * * const date = new Date();
 * isValidDate(date) // true
 * ```
 */
export function isValidDate(date: Date) {
  return !isNaN(date.getTime());
}

/**
 * Checks whether a string is not the invalid date marker.
 *
 * @example
 * ```ts
 * isInvalidDateMarker("INVALID") // true
 * ```
 */

export function isInvalidDateMarker(dateString: string) {
  return dateString === INVALID_MARKER;
}

/**
 * Retrieves props that are suitable for use for fields that work with dates.
 *
 * The value will be held as an ISO date inside of formik state, but will be
 * provided as a valid date instance to the component, or null.
 *
 * Note that if an invalid date is supplied from the component, an invalid
 * marker will be held in state to allow users to keep editing their dates
 * but also make distinction in the state between an empty and invalid state.
 */
export function getDateFieldProps<
  T extends FormikValues,
  Name extends string & keyof T,
>(formik: ReturnType<typeof useFormik<T>>, name: Name) {
  const wasTouched = formik.touched[name];
  const validationHint = formik.errors[name];

  function toDate(value: string) {
    if (value && value !== INVALID_MARKER) {
      try {
        const date = new Date(value);
        return isValidDate(date) ? date : null;
      } catch (error) {
        return null;
      }
    }
    return null;
  }

  return {
    name,
    value: toDate(formik.values[name]),
    onBlur: () => formik.setFieldTouched(name),
    onChange: (date: Date | null) => {
      return formik.setFieldValue(
        name,
        date ? (isValidDate(date) ? date.toISOString() : INVALID_MARKER) : "",
      );
    },
    error: !!(wasTouched && validationHint),
    helperText: wasTouched && validationHint,
  };
}

/**
 * A field representing a date.
 *
 * The underlying text field will automatically be set to type
 * number, and optionally you can supply an adornment to clarify
 * the unit.
 *
 * If you are using this field with `formik`, you can use the
 * `getDateFieldProps` helper to retrieve exactly the right props
 * from formik state.
 */
export function DateField(props: {
  label: string;
  value: Date | null;
  helperText?: string;
  error: boolean;
  minDate?: Date;
  onChange: (value: Date | null) => void;
  onBlur?: () => void;
}) {
  const { error, helperText, ...rest } = props;

  return (
    <DatePicker
      {...rest}
      slotProps={{
        field: {
          clearable: true,
          onClear: () => {
            props.onChange(null);
            props.onBlur?.();
          },
        },
        textField: {
          onBlur: props.onBlur,
          error: props.error,
          helperText: props.helperText,
          variant: "standard",
          InputLabelProps: {
            shrink: true,
          },
          fullWidth: true,
        },
      }}
    />
  );
}
