import { Link as ReactRouterLink } from "react-router-dom";
import { Link, LinkProps } from "@mui/material";

export function RouterLink(
  props: Omit<LinkProps, "component"> & {
    to: string;
  },
) {
  const { to, ...rest } = props;
  return <Link component={ReactRouterLink} to={to} {...rest} />;
}
