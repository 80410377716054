import IntlMessageFormat, {
  FormatXMLElementFn,
  PrimitiveType,
} from "intl-messageformat";
import { PMBSchemas } from "../client/types";

export const LOCALE = "en-US";

export function formatPercent(value: number) {
  const formatter = new Intl.NumberFormat(LOCALE, {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return formatter.format(value);
}

export function formatCurrency(
  currency: string,
  value: number,
  {
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
  }: {
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
  } = {},
) {
  const formatter = new Intl.NumberFormat(LOCALE, {
    style: "currency",
    currency,

    minimumFractionDigits,
    maximumFractionDigits,
  });

  return formatter.format(value);
}

export function formatPrice(
  value: number | undefined,
  mode: PMBSchemas["QuantityMode"] = "AMOUNT",
) {
  if (value === undefined) {
    return null;
  }
  switch (mode) {
    case "AMOUNT": {
      return formatCurrency("USD", value);
    }

    case "PERCENTAGE": {
      return formatPercent(value / 100);
    }

    default: {
      return "Unknown quantity mode";
    }
  }
}

export function formatDate(
  dateOrString: Date | string,
  dateStyle: "short" | "medium" | "long",
) {
  const formatter = new Intl.DateTimeFormat(LOCALE, {
    dateStyle,
  });
  return formatter.format(
    typeof dateOrString === "string" ? new Date(dateOrString) : dateOrString,
  );
}

export function formatCardExpiry(date: Date) {
  const formatter = new Intl.DateTimeFormat(LOCALE, {
    year: "2-digit",
    month: "2-digit",
  });
  return formatter.format(date);
}

export type MessageFormat = ReturnType<typeof mf>;

export function mf(message: string) {
  const formatter = new IntlMessageFormat(message, "en-US");

  // We need to wrap the format function to make sure its output is compatible
  // with ReactNode.
  return {
    format<T extends { toString: () => string }>(
      values?: Record<string, PrimitiveType | T | FormatXMLElementFn<T>>,
    ) {
      const result = formatter.format(values);

      if (Array.isArray(result)) {
        return result.map((part) => part.toString()).join("");
      }

      return result.toString();
    },
  };
}
