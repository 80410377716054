import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  Grid,
  CardContent as MuiCardContent,
  Typography,
} from "@mui/material";
import { PMBDevice } from "../../../client/types";
import {
  DeviceStatusKey,
  deviceStatus,
} from "../../../constants/locationConfiguration/deviceStatus";
import { groupBy } from "../../../utils/common";
import { RoleGuard } from "../../../ui/role-guard";

const CardContent = styled(MuiCardContent)`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  position: relative;
  padding: 16px;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
`;

type DeviceStatusInfo = {
  legacy: string | null;
  draft: string | null;
  pending: string | null;
  connected: string | null;
  disabled: string | null;
};

function groupDevicesByCategory(devices: PMBDevice[] = []) {
  const groups = groupBy(devices, (d) => d.deviceCategory);

  return {
    gateway: groups.GATEWAY?.[0] ?? null,
    chargers: groups.CHARGER ?? [],
    screens: groups.SCREEN ?? [],
  };
}

function groupDevicesByStatus(devices: PMBDevice[] = []) {
  const groups = groupBy(devices, (d) => d.status);

  return {
    legacy: groups.LEGACY ?? [],
    draft: groups.DRAFT ?? [],
    pending: groups.PENDING_INSTALLATION ?? [],
    connected: groups.CONNECTED ?? [],
    disabled: groups.DISABLED ?? [],
  };
}

function PmbLocationDevicesCard(props: {
  locationId: number;
  devices?: PMBDevice[];
}) {
  const { locationId, devices } = props;
  const isLoading = !devices;
  const { gateway, chargers, screens } = groupDevicesByCategory(props.devices);

  const getStatusInfo = (devices: PMBDevice[]): DeviceStatusInfo => {
    const groups = groupDevicesByStatus(devices);

    const legacy =
      groups.legacy.length > 0
        ? groups.legacy.length + " " + deviceStatus[DeviceStatusKey.LEGACY]
        : null;

    const draft =
      groups.draft.length > 0
        ? groups.draft.length + " " + deviceStatus[DeviceStatusKey.DRAFT]
        : null;

    const pending =
      groups.pending.length > 0
        ? groups.pending.length +
          " " +
          deviceStatus[DeviceStatusKey.PENDING_INSTALLATION]
        : null;

    const connected =
      groups.connected.length > 0
        ? groups.connected.length +
          " " +
          deviceStatus[DeviceStatusKey.CONNECTED]
        : null;

    const disabled =
      groups.disabled.length > 0
        ? groups.disabled.length + " " + deviceStatus[DeviceStatusKey.DISABLED]
        : null;

    return { legacy, draft, pending, connected, disabled };
  };

  return (
    <Card>
      <CardHeader
        titleTypographyProps={{ variant: "h5" }}
        title="Location Devices"
      />

      <CardContent>
        {isLoading ? (
          <Grid container item xs={12} spacing={2}>
            <Grid item paddingBottom={4}>
              <Typography>Loading devices...</Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container item xs={12} alignItems={"flex-start"}>
            <Grid container item xs={4} spacing={2} pb={4}>
              {!gateway && (
                <Grid item xs={12}>
                  <Typography>No devices yet</Typography>
                </Grid>
              )}
              {gateway && (
                <>
                  <Grid item xs={12} pb={4}>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 800 }}
                      color={"#6B6B6B"}
                    >
                      1 Gateway
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color={"#6B6B6B"}>
                      {getStatusInfo([gateway]).legacy}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container item xs={4} spacing={"8px"} pb={"16px"}>
              {chargers.length > 0 && (
                <>
                  <Grid item xs={12} pb={"16px"}>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 800 }}
                      color={"#6B6B6B"}
                    >
                      {chargers.length} Chargers
                    </Typography>
                  </Grid>
                  {getStatusInfo(chargers).legacy ? (
                    <Grid item xs={12}>
                      <Typography variant="body2" color={"#6B6B6B"}>
                        {getStatusInfo(chargers).legacy}
                      </Typography>
                    </Grid>
                  ) : null}
                  {getStatusInfo(chargers).draft ? (
                    <Grid item xs={12}>
                      <Typography variant="body2" color={"#6B6B6B"}>
                        {getStatusInfo(chargers).draft}
                      </Typography>
                    </Grid>
                  ) : null}
                  {getStatusInfo(chargers).connected ? (
                    <Grid item xs={12}>
                      <Typography variant="body2" color={"#6B6B6B"}>
                        {getStatusInfo(chargers).connected}
                      </Typography>
                    </Grid>
                  ) : null}
                  {getStatusInfo(chargers).pending ? (
                    <Grid item xs={12}>
                      <Typography variant="body2" color={"#6B6B6B"}>
                        {getStatusInfo(chargers).pending}
                      </Typography>
                    </Grid>
                  ) : null}
                  {getStatusInfo(chargers).disabled ? (
                    <Grid item xs={12}>
                      <Typography variant="body2" color={"#6B6B6B"}>
                        {getStatusInfo(chargers).disabled}
                      </Typography>
                    </Grid>
                  ) : null}
                </>
              )}
            </Grid>

            <Grid container item xs={4} spacing={"8px"} pb={"16px"}>
              {screens.length > 0 && (
                <>
                  <Grid item xs={12} pb={"16px"}>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 800 }}
                      color={"#6B6B6B"}
                    >
                      {screens.length} Screens
                    </Typography>
                  </Grid>
                  {getStatusInfo(screens).legacy ? (
                    <Grid item xs={12}>
                      <Typography variant="body2" color={"#6B6B6B"}>
                        {getStatusInfo(screens).legacy}
                      </Typography>
                    </Grid>
                  ) : null}
                  {getStatusInfo(screens).draft ? (
                    <Grid item xs={12}>
                      <Typography variant="body2" color={"#6B6B6B"}>
                        {getStatusInfo(screens).draft}
                      </Typography>
                    </Grid>
                  ) : null}
                  {getStatusInfo(screens).connected ? (
                    <Grid item xs={12}>
                      <Typography variant="body2" color={"#6B6B6B"}>
                        {getStatusInfo(screens).connected}
                      </Typography>
                    </Grid>
                  ) : null}
                  {getStatusInfo(screens).pending ? (
                    <Grid item xs={12}>
                      <Typography variant="body2" color={"#6B6B6B"}>
                        {getStatusInfo(screens).pending}
                      </Typography>
                    </Grid>
                  ) : null}
                  {getStatusInfo(screens).disabled ? (
                    <Grid item xs={12}>
                      <Typography variant="body2" color={"#6B6B6B"}>
                        {getStatusInfo(screens).disabled}
                      </Typography>
                    </Grid>
                  ) : null}
                </>
              )}
            </Grid>
          </Grid>
        )}

        {/* Hide devices: PMB-599 */}
        <RoleGuard allowedRoles={["PMB_ADMIN", "PMB_VIEWER"]}>
          <Button
            component={Link}
            to={`/location/${locationId}/topology`}
            variant={"outlined"}
            color="primary"
            style={{ paddingTop: "8px" }}
          >
            View Details
          </Button>
        </RoleGuard>
      </CardContent>
    </Card>
  );
}

export default PmbLocationDevicesCard;
