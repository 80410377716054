import { useMsal } from "@azure/msal-react";
import styled from "@emotion/styled";
import {
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  Tooltip,
} from "@mui/material";
import React from "react";
import { User } from "react-feather";
import { useNavigate } from "react-router-dom";
import { position } from "../../constants/contactPositions";
import { useUserInfo } from "../../ui/user-info-context";
import { clearLocalStorage } from "../../utils/local-storage";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const { principal } = useUserInfo();

  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);

  const { instance } = useMsal();

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const navigate = useNavigate();

  const toProfile = () => {
    navigate("/profile");
    closeMenu();
  };

  const handleSignOut = async () => {
    // Clean the local storage data when user logout from the application
    clearLocalStorage();

    instance.logoutRedirect({
      account: instance.getActiveAccount(),
    });
  };

  return (
    <React.Fragment>
      <Tooltip
        title={`${principal.fullName} (${position.format(principal.position)})`}
      >
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <User />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={toProfile}>Profile</MenuItem>
        <MenuItem onClick={handleSignOut}>Logout</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
