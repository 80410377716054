import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useId } from "react";

/**
 * Renders a Info Dialog.
 */
export function InfoDialog(props: {
  title: string;
  description: string;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { isOpen, onClose, title, description } = props;

  const titleId = useId();
  const descId = useId();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      aria-labelledby={titleId}
      aria-describedby={descId}
    >
      <DialogTitle id={titleId} fontWeight="bold">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={descId}>{description}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="text" color="info" onClick={onClose} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
