import { PMBPosition } from "../client/types";
import { EnumFormatter } from "../utils/enum-formatter";

export const position = new EnumFormatter<PMBPosition>({
  COMPANY_MANAGER: "Company Manager",
  LOCATION_MANAGER: "Location Manager",
  LOCATION_STAFF: "Location Staff",
  PMB_MANAGER: "PMB Manager",
  PMB_PROJECT_MANAGER: "PMB Project Manager",
  PMB_INSTALLATION_CREW: "PMB Installation Crew",
  PMB_CUSTOMER_CARE: "PMB Customer Care",
  PMB_BILLING: "PMB Billing",
  PMB_SALES_REP: "PMB Sales Rep",
  PMB_ADMINISTRATOR: "PMB Administrator",
});

export function isInternalPMB(position: PMBPosition) {
  return position.startsWith("PMB_");
}
