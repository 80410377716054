import { TextField } from "@mui/material";
import { useFormik } from "formik";
import { object, string } from "yup";
import { useClient } from "../client";
import { caughtValueToString } from "../utils/caught-error";
import { FormDialog } from "./form-dialog";
import { getTextFieldProps } from "./text-field";

export function EditCompanyDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  company: {
    id: number;
    name: string;
  };
}) {
  const client = useClient();
  const { company } = props;
  const formik = useFormik({
    initialValues: {
      name: company.name,
    },
    validationSchema: object().shape({
      name: string().required("Company name cannot be empty."),
    }),
    async onSubmit(values) {
      try {
        await client.PATCH("/companies/{companyId}", {
          params: { path: { companyId: company.id } },
          body: values,
        });

        props.onSuccess();
        props.onClose();
      } catch (error) {
        formik.setStatus(caughtValueToString(error));
      }
    },
  });

  return (
    <FormDialog
      {...props}
      title="Edit company"
      submitLabel="Save"
      form={formik}
    >
      <TextField
        {...getTextFieldProps(formik, "name")}
        label="Company name"
        variant="standard"
        fullWidth
      />
    </FormDialog>
  );
}
