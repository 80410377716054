import { Box, TableCell, TableRow } from "@mui/material";

export function DummyText(props: { width: string }) {
  return (
    <Box
      style={{ width: props.width }}
      sx={{
        height: "1em",
        backgroundColor: "background.default",
        borderRadius: 1,
      }}
    />
  );
}

export function DummyRows(props: {
  columns: number;
  rows: number;
  rowHeight?: number;
}) {
  const widths = ["30%", "45%", "80%", "60%", "65%", "40%"];

  return (
    <>
      {Array.from({ length: props.rows }, (_row, rowIndex) => {
        return (
          <TableRow key={rowIndex} sx={{ height: props.rowHeight }}>
            {Array.from({ length: props.columns }, (_column, columnIndex) => {
              const width = widths[(columnIndex + rowIndex) % widths.length];

              return (
                <TableCell key={columnIndex}>
                  <DummyText width={width} />
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </>
  );
}
