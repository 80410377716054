import { ReactNode, useState } from "react";
import { useOnboardingSWR } from "../hooks/useOnboarding";
import LoadingSpinner from "./loading-spinner";
import { ErrorCard } from "./info-card";
import { OnboardingProcess } from "../pages/onboarding";
import { Grid } from "@mui/material";

/**
 * Onboarding Guard component for non internal users. This Guard
 * protects the display of application if the user's location or
 * company is not CONFIRMED.
 */
function OnboardingGuard(props: { children?: ReactNode }) {
  const [index, setIndex] = useState(0);
  const { data, error, mutate } = useOnboardingSWR();

  if (error) {
    return (
      <ErrorCard heading="Onboarding Fetch Failed" message={error.message} />
    );
  }

  if (!data) {
    return <LoadingSpinner />;
  }

  // The onboarding is not needed, the SWR client returns only SUCCESS.
  if (index === 0 && data.length === 1 && data[0].type === "SUCCESS") {
    return <>{props.children}</>;
  }

  // The last screen does nothing, just informs user about the end of the process.
  if (index === 1 && data.length === 1) {
    return (
      <Grid container height={"100vh"}>
        <OnboardingProcess
          step={data[0]}
          handleDataChange={() => {
            setIndex(0);
          }}
        />
      </Grid>
    );
  }

  return (
    <Grid container height={"100vh"}>
      <OnboardingProcess
        step={data[index]}
        handleDataChange={() => {
          // The first screen does nothing, just informs user about the process.
          if (index === 0 && data[index].type === "DETAILS") {
            setIndex(1);
          }

          mutate();
        }}
      />
    </Grid>
  );
}

export default OnboardingGuard;
