export const publicConfig = {
  uri: process.env.REACT_APP_BACKEND_HOST,
  applicationInsightsConnectionString:
    process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING,
};

export const b2cConfig = {
  clientId: process.env.REACT_APP_ADB2C_CLIENT_ID || "",
  authority: process.env.REACT_APP_ADB2C_AUTHORITY || "",
  knownAuthorities: process.env.REACT_APP_ADB2C_KNOWN_AUTHORITIES?.split(
    ",",
  ) || [""],
  redirectUri: process.env.REACT_APP_ADB2C_REDIRECT_URI || "",
  postLogoutRedirectUri:
    process.env.REACT_APP_ADB2C_POST_LOGOUT_REDIRECT_URI || "",
  signUpSignIn: process.env.REACT_APP_ADB2C_SIGNUP_SIGNIN_POLICY || "",
  profileAuthority: process.env.REACT_APP_ADB2C_EDIT_PROFILE_AUTHORITY || "",
  scopes: process.env.REACT_APP_ADB2C_LOGIN_SCOPES?.split(",") || [""],
};
