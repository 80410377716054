import { ConfirmDialog } from "./confirm-dialog";
import { unpackResponse, useClient } from "../client";

export function DeactivateContactDialog(props: {
  isOpen: boolean;
  contact: { fullName: string; id: number };
  onClose: () => void;
  onSuccess: () => void;
}) {
  const client = useClient();
  const { contact, onSuccess, ...dialogProps } = props;

  return (
    <ConfirmDialog
      {...dialogProps}
      title={`Deactivate ${contact.fullName}?`}
      description={`${contact.fullName} will no longer have access to PourMyBev.`}
      submitLabel="Deactivate"
      submitColor="error"
      onSubmit={async () => {
        await unpackResponse(
          client.DELETE("/contacts/{contactId}", {
            params: { path: { contactId: contact.id } },
          }),
        );

        onSuccess();
      }}
    />
  );
}
