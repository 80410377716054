import { Grid, Typography } from "@mui/material";
import PmbCircularProgress from "../PmbCircularProgress";
import styled from "@emotion/styled";

const FormTitleGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;

  @media (max-width: 600px) {
    justify-content: left;
  }
`;

type PmbLoaderProps = {
  total: number;
  current: number;
  topic: string;
  text: string;
};

const PmbLoader = ({ total, current, topic, text }: PmbLoaderProps) => {
  const multiplier = 100 / total;
  return (
    <Grid
      container
      spacing={"40px"}
      my={"36px"}
      p={"0 16px"}
      alignItems={"center"}
    >
      <Grid item xs={12} lg={1}>
        <PmbCircularProgress
          value={current * multiplier}
          text={`${current} of ${total}`}
          style={{ color: "#A3CA3F" }}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <FormTitleGroup>
          <Typography variant="h3" align="right">
            {text}
          </Typography>
          <Typography align="right">
            {current === total
              ? `Final step in ${topic} creation`
              : "Next: " + text}
          </Typography>{" "}
        </FormTitleGroup>
      </Grid>
    </Grid>
  );
};

export default PmbLoader;
