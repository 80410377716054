import { Chip } from "@mui/material";
import { ReactElement } from "react";
import { PMBSchemas } from "../client/types";
import { EnumMapper } from "../utils/enum-formatter";

export const statusChip = new EnumMapper<
  PMBSchemas["PaymentStatus"],
  ReactElement
>(
  {
    PAID: <Chip label="Paid" variant="filled" color="success" />,
    MANUALLY_SETTLED: (
      <Chip label="Settled manually" variant="filled" color="success" />
    ),
    PENDING: <Chip label="Pending" variant="filled" />,
    OVERDUE: <Chip label="Overdue" variant="filled" color="error" />,
  },
  <Chip label="Unknown" variant="filled" color="error" />,
);

export function PaymentStatusChip(props: PMBSchemas["PaymentListItem"]) {
  if (
    props.status === "PENDING" &&
    props.lastTransaction?.status === "FAILED"
  ) {
    return <Chip label="Payment failed" variant="filled" color="warning" />;
  }

  return statusChip.get(props.status);
}
