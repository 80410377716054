import { Divider, MenuItem, Stack, TextField } from "@mui/material";
import { unpackResponse, useClient } from "../../client";
import { PMBSchemas } from "../../client/types";
import { DialogState } from "../../ui/more-menu";
import { FormDialog } from "../../ui/form-dialog";
import { useFormik } from "formik";
import { getTextFieldProps } from "../../ui/text-field";
import { CheckboxField, getCheckboxFieldProps } from "../../ui/checkbox-field";
import { caughtValueToString } from "../../utils/caught-error";
import { getNumberFieldProps, NumberField } from "../../ui/number-field";
import { number, object } from "yup";

type FormValues = {
  label: string;
  hwPlatformId: number;
  touchscreenTypeId: number;
  tapCount: number;
  flowmeterTypeId: number;
  valveTypeId: number;
  rfidReaderActive: boolean;
  qrScannerActive: boolean;
  paymentTerminalActive: boolean;
  createCount: number;
};

type SubmitValues = Pick<
  PMBSchemas["DeviceUpdate"],
  | "label"
  | "hwPlatform"
  | "touchscreenType"
  | "tapCount"
  | "flowmeterType"
  | "valveType"
  | "rfidReaderType"
  | "qrScannerType"
  | "paymentTerminalType"
> & {
  createCount: number;
};

type DeviceModelConfig = {
  hardwareModels: PMBSchemas["DeviceModel"][];
  touchscreenTypeModels: PMBSchemas["DeviceModel"][];
  valveTypeModels: PMBSchemas["DeviceModel"][];
  flowmeterTypeModels: PMBSchemas["DeviceModel"][];
  rfidReaderTypeId: number;
  qrScannerTypeId: number;
  paymentTerminalTypeId: number;
};

type Props = DialogState & {
  deviceModelsByCategory: Record<
    PMBSchemas["DeviceModelCategory"],
    PMBSchemas["DeviceModel"][]
  >;
  locationId: number;
  revalidateView: () => void;
};

function ScreenDeviceFormDialog(
  props: Props & {
    onSubmit: (values: SubmitValues) => Promise<void>;
    device?: PMBSchemas["Device"];
    title: string;
    submitLabel: string;
  },
) {
  const { device, deviceModelsByCategory } = props;
  const isCreate = !device;

  const config: DeviceModelConfig = {
    hardwareModels: deviceModelsByCategory.SCREEN,
    touchscreenTypeModels: deviceModelsByCategory.TOUCHSCREEN,
    valveTypeModels: deviceModelsByCategory.VALVE,
    flowmeterTypeModels: deviceModelsByCategory.FLOWMETER,
    rfidReaderTypeId: deviceModelsByCategory.RFID_READER.at(0)?.id!,
    qrScannerTypeId: deviceModelsByCategory.QR_SCANNER.at(0)?.id!,
    paymentTerminalTypeId: deviceModelsByCategory.PAYMENT_TERMINAL.at(0)?.id!,
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      label: device?.label ?? "",
      hwPlatformId: device?.hwPlatform.id ?? config.hardwareModels.at(0)?.id!,
      touchscreenTypeId:
        device?.touchscreenType?.id ?? config.touchscreenTypeModels.at(0)?.id!,
      tapCount: device?.tapCount ?? 1,
      flowmeterTypeId:
        device?.flowmeterType?.id ?? config.flowmeterTypeModels.at(0)?.id!,
      valveTypeId: device?.valveType?.id ?? config.valveTypeModels.at(0)?.id!,
      rfidReaderActive: !!device?.rfidReaderType,
      qrScannerActive: !!device?.qrScannerType,
      paymentTerminalActive: !!device?.paymentTerminalType,
      createCount: 1,
    },
    async onSubmit(values) {
      try {
        await props.onSubmit({
          label: values.label,
          hwPlatform: { id: values.hwPlatformId },
          touchscreenType: { id: values.touchscreenTypeId },
          tapCount: values.tapCount,
          valveType: { id: values.valveTypeId },
          flowmeterType: { id: values.flowmeterTypeId },
          rfidReaderType: values.rfidReaderActive
            ? { id: config.rfidReaderTypeId }
            : undefined,
          qrScannerType: values.qrScannerActive
            ? { id: config.qrScannerTypeId }
            : undefined,
          paymentTerminalType: values.paymentTerminalActive
            ? { id: config.rfidReaderTypeId }
            : undefined,
          createCount: values.createCount,
        });
        props.revalidateView();
        props.onClose();
      } catch (error) {
        formik.setStatus(caughtValueToString(error));
      }
    },
    validationSchema: object().shape({
      createCount: number().min(1, "At least one screen must be created."),
    }),
  });

  return (
    <FormDialog {...props} form={formik}>
      <Stack direction="column" gap={4}>
        <TextField
          {...getTextFieldProps(formik, "label")}
          variant="standard"
          fullWidth
          label="Label"
        />

        <TextField
          {...getTextFieldProps(formik, "hwPlatformId")}
          variant="standard"
          fullWidth
          label="Hardware Platform"
          placeholder="Select hardware platform"
          select
        >
          {config.hardwareModels.map((deviceModel) => {
            return (
              <MenuItem value={deviceModel.id!}>
                {deviceModel.modelName}
              </MenuItem>
            );
          })}
        </TextField>

        <TextField
          {...getTextFieldProps(formik, "tapCount")}
          variant="standard"
          fullWidth
          label="Tap Count"
          select
        >
          <MenuItem value={1}>One tap</MenuItem>
          <MenuItem value={2}>Two taps</MenuItem>
          <MenuItem value={3}>Three taps</MenuItem>
          <MenuItem value={4}>Four taps</MenuItem>
        </TextField>

        <TextField
          {...getTextFieldProps(formik, "valveTypeId")}
          variant="standard"
          fullWidth
          label="Valve type"
          select
        >
          {config.valveTypeModels.map((deviceModel) => {
            return (
              <MenuItem value={deviceModel.id!}>
                {deviceModel.modelName}
              </MenuItem>
            );
          })}
        </TextField>

        <TextField
          {...getTextFieldProps(formik, "flowmeterTypeId")}
          variant="standard"
          fullWidth
          label="Flowmeter type"
          select
        >
          {config.flowmeterTypeModels.map((deviceModel) => {
            return (
              <MenuItem value={deviceModel.id!}>
                {deviceModel.modelName}
              </MenuItem>
            );
          })}
        </TextField>

        <Stack>
          <CheckboxField
            {...getCheckboxFieldProps(formik, "rfidReaderActive")}
            label="RFID Reader Active"
          />
          <CheckboxField
            {...getCheckboxFieldProps(formik, "qrScannerActive")}
            label="QR Scanner Active"
          />
          <CheckboxField
            {...getCheckboxFieldProps(formik, "paymentTerminalActive")}
            label="Payment Terminal Active"
          />
        </Stack>

        {isCreate && (
          <>
            <Divider />

            <NumberField
              {...getNumberFieldProps(formik, "createCount")}
              label="Create multipele screens"
            />
          </>
        )}
      </Stack>
    </FormDialog>
  );
}

export function CreateScreenDeviceDialog(
  props: Props & { gatewayDeviceId: number },
) {
  const client = useClient();

  return (
    <ScreenDeviceFormDialog
      {...props}
      title="Create Screen Device"
      submitLabel="Create"
      onSubmit={async (values) => {
        // If a number lower than 1 was provided, make sure it is 1.
        const count = Math.max(1, values.createCount);
        const range = Array.from({ length: count }, (_, i) => {
          return `${i + 1}`.padStart(2, "0");
        });

        for (const iteration of range) {
          // If multiple screens are being created and a label was provided,
          // make sure to add a number to the label to differentiate the
          // individual screens.

          const label =
            values.label && count > 1
              ? `${values.label} ${iteration}`
              : values.label;

          await unpackResponse(
            client.POST("/devices", {
              body: {
                ...values,
                label,
                parentDeviceId: props.gatewayDeviceId,
                deviceCategory: "SCREEN",
                status: "LEGACY",
                locationId: props.locationId,
              },
            }),
          );
        }
      }}
    />
  );
}

export function UpdateScreenDeviceDialog(
  props: Props & { device: PMBSchemas["Device"] },
) {
  const client = useClient();

  return (
    <ScreenDeviceFormDialog
      {...props}
      device={props.device}
      title="Update Screen Device"
      submitLabel="Save"
      onSubmit={async (values) => {
        await unpackResponse(
          client.PUT("/devices/{deviceId}", {
            params: { path: { deviceId: props.device.id! } },
            body: { ...props.device, ...values },
          }),
        );
      }}
    />
  );
}
