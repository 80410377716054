import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Card,
  FormControl,
  Grid,
  Button as MuiButton,
  Typography,
} from "@mui/material";
import { Box, spacing, SpacingProps } from "@mui/system";
import { useMsalAccount } from "../../auth/AccountContext";
import { PMBSchemas, PMBSubscriptionFrequency } from "../../client/types";
import MiddleCardTemplate from "./middle-card-template";
import { PaymentStructureTable } from "../../ui/payment-structure-table";
import {
  RadioGroupField,
  RadioGroupOption,
  getRadioGroupFieldProps,
} from "../../ui/radio-group-field";
import { useFormik } from "formik";
import { useSubscriptionSWR } from "../../hooks/useSubscriptionSWR";
import { ErrorCard } from "../../ui/info-card";
import LoadingSpinner from "../../ui/loading-spinner";

interface ButtonProps extends SpacingProps {
  component?: React.ElementType;
  to?: string;
  target?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

function SubscriptionTemplate(props: {
  location: PMBSchemas["LocationBase"];
  subscription: PMBSchemas["Subscription"];
  handleConfirmation: () => void;
  handleFrequency: (frequency: PMBSubscriptionFrequency) => void;
}) {
  const { logout } = useMsalAccount();

  const formik = useFormik({
    initialValues: props.subscription,
    onSubmit: () => {},
  });

  const { data, error, mutate } = useSubscriptionSWR({
    locationId: props.location.id,
    subscription: formik.values,
  });

  useEffect(() => {
    mutate();
    props.handleFrequency(formik.values.paymentFrequency);
  }, [formik.values.paymentFrequency, mutate, props]);

  if (error) {
    return (
      <MiddleCardTemplate maxHeight="50vh" maxWidth="50vw" overflow="auto">
        <ErrorCard
          heading="Error, custom subscription"
          message={error.message}
        />
        <Button variant="outlined" color="warning" mt={2} onClick={logout}>
          Logout
        </Button>
      </MiddleCardTemplate>
    );
  }

  if (!data) {
    return <LoadingSpinner />;
  }

  return (
    <MiddleCardTemplate maxHeight="90vh" maxWidth="90vw" overflow="auto">
      <Helmet title="Subscription" />

      <Box
        sx={{
          display: "flex",
          padding: 4,
          flexDirection: "column",
          alignItems: "flex-start",
          alignSelf: "stretch",
        }}
      >
        <Typography
          component="h2"
          variant="h2"
          sx={{
            display: "flex",
            gap: 18,
            padding: "8px 8px 8px 0",
            alignItems: "flex-start",
            alignSelf: "stretch",
          }}
        >
          {`Set up subscription for ${props.location.name}, ${
            props.location.address.city
          }${
            props.location.address.state !== "N_A"
              ? ", " + props.location.address.state
              : ""
          }`}
        </Typography>

        <Grid container item xs={12} pb={4}>
          <Typography component="p" variant="body1" color={"#6B6B6B"}>
            Please, confirm your subscription details and payment frequency
            below. If you have any questions, please contact &nbsp;
            <Link to={"mailto:support@pourmybeer.com"}>
              support@pourmybeer.com.
            </Link>
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          my={4}
          sx={{
            borderRadius: 1,
            border: "1px solid rgba(0, 0, 0, 0.25)",
            width: "100%",
          }}
        >
          <Card sx={{ width: "100%" }}>
            <PaymentStructureTable paymentStructure={data} />
          </Card>
        </Grid>

        <Grid item xs={12} minWidth={800} mt={4}>
          <FormControl>
            <RadioGroupField
              {...getRadioGroupFieldProps(formik, "paymentFrequency")}
              label="Select billing type"
            >
              <RadioGroupOption<PMBSchemas["Subscription"]["paymentFrequency"]>
                value="MONTHLY"
                label="Monthly payments"
              />
              <RadioGroupOption<PMBSchemas["Subscription"]["paymentFrequency"]>
                value="ANNUAL"
                label="Annual payments - save 10%💡"
              />
            </RadioGroupField>
          </FormControl>
        </Grid>

        <Grid
          container
          item
          xs={12}
          minWidth={800}
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            color="primary"
            mt={2}
            onClick={props.handleConfirmation}
          >
            Confirm
          </Button>
          <Button variant="outlined" color="warning" mt={2} onClick={logout}>
            Logout
          </Button>
        </Grid>
      </Box>
    </MiddleCardTemplate>
  );
}

export default SubscriptionTemplate;
