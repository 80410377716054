import { unpackResponse, useClient } from "../../client";
import { ConfirmDialog } from "../../ui/confirm-dialog";
import { DialogState } from "../../ui/more-menu";

export function DeleteDeviceDialog(
  props: DialogState & { deviceId: number; revalidateView: () => void },
) {
  const { deviceId } = props;
  const client = useClient();

  return (
    <ConfirmDialog
      {...props}
      title="Delete device?"
      description="Are you sure you want to delete this device?"
      submitColor="error"
      submitLabel="Delete"
      onSubmit={async () => {
        await unpackResponse(
          client.DELETE("/devices/{deviceId}", {
            params: { path: { deviceId } },
          }),
        );

        props.revalidateView();
      }}
    />
  );
}
