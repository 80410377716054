import { useCallback, useState } from "react";

/**
 * Returns preconfigured values to be used for controlling dialog visibility.
 *
 * This is a low-level hooks that should only be used for advanced use-cases.
 * If you simply want to open a dialog on button click, use `<DialogButton />`
 * component which avoids unnecessary boilerplate.
 */
export function useDialogState(initialState: boolean = false) {
  const [isOpen, setIsOpen] = useState(initialState);
  const openDialog = useCallback(() => setIsOpen(true), []);
  const closeDialog = useCallback(() => setIsOpen(false), []);
  return {
    isOpen,
    openDialog,
    closeDialog,

    /**
     * Alias for closeDialog. Useful for spreading dialogState to component
     * props.
     */
    onClose: closeDialog,
  };
}
