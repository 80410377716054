import { TableCell, TableCellProps, TableSortLabel } from "@mui/material";
import { DummyText } from "./dummy-rows";
import { ReactNode } from "react";
import { RouterLink } from "./router-link";

/**
 * TableColumnHeadCell should be used to define header cell that labels a column.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/th
 */

export function TableColumnHeadCell(props: {
  width?: string;
  children?: ReactNode;
  align?: TableCellProps["align"];
  sort?: { onClick: () => void; direction: "asc" | "desc"; active: boolean };
}) {
  return (
    <TableCell
      component="th"
      variant="head"
      scope="col"
      width={props.width}
      align={props.align}
    >
      {props.sort ? (
        <TableSortLabel {...props.sort}>{props.children}</TableSortLabel>
      ) : (
        props.children
      )}
    </TableCell>
  );
}

/**
 * TableRowHeadCell should be used to define header cell that labels a row.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/th
 */
export function TableRowHeadCell(
  props: Omit<TableCellProps, "component" | "scope">,
) {
  return <TableCell component="th" scope="row" {...props} />;
}

/**
 * TableCellLoading should be used to indicate that a table cell is
 * making an sync request to fetch data.
 */
export function TableCellLoading(props: { width?: string }) {
  return (
    <TableCell>
      <DummyText width={props.width ?? "40%"} />
    </TableCell>
  );
}

/**
 * TableCellLink should be used for table cells that navigate to another
 * part of the UI.
 *
 * Note that it should not be used for external links, or alternative
 * protocols like `maito` or `tel`.
 */
export function TableCellLink(props: { to: string; children: ReactNode }) {
  return (
    <TableCell>
      <RouterLink to={props.to} underline="hover">
        {props.children}
      </RouterLink>
    </TableCell>
  );
}
