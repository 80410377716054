import { Button, ButtonProps } from "@mui/material";
import { NavLink } from "react-router-dom";

/**
 * NavLinkButton combines MUI Button and React Router NavLink
 * into a single component.
 */
export function NavLinkButton(
  props: ButtonProps & {
    to: string;
  },
) {
  const { to, ...rest } = props;
  return <Button component={NavLink} to={to} {...rest} />;
}
