import { useCallback } from "react";
import { unpackResponse, useClient } from "../client";
import useSWR from "swr";

export function useLocationSubscription(locationId: number) {
  const client = useClient();
  const fetcher = useCallback(async () => {
    const sub = await unpackResponse(
      client.GET("/locations/{locationId}/subscription", {
        params: { path: { locationId: locationId } },
      }),
    );

    return sub;
  }, [client, locationId]);
  return useSWR(`CUSTOM:/locations/${locationId}/subscriptions`, fetcher);
}
