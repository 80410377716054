import styled from "@emotion/styled";
import { Box, CardContent, Alert as MuiAlert } from "@mui/material";
import { spacing } from "@mui/system";
import { FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { PMBContact, PMBSchemas } from "../../client/types";
import { countryRequiresState } from "../../constants/countries";
import { errorMessages } from "../../constants/messages";
import LoadingSpinner from "../../ui/loading-spinner";
import { ViewHeader } from "../../ui/view-header";
import { YupState, YupZip } from "../../utils/yup-validators";
import PmbCard from "../components/pmb/PmbCard";
import PmbFormButtons from "../components/pmb/form/PmbFormButtons";
import PmbLoader from "../components/pmb/form/PmbLoader";
import CompanyAddressFormik from "./formik/CompanyAddressFormik";
import PrimaryContactFormik from "./formik/PrimaryContactFormik";
import { unpackResponse, useClient } from "../../client";

const Alert = styled(MuiAlert)(spacing);

export type CompanyFormType = PMBSchemas["CreateCompany"] & {
  contactFullName: string;
  contactEmail: string;
  contactMobile: string;
};

const initialValues: CompanyFormType = {
  id: 0,
  logo: 0,
  name: "",
  address: {
    addressLineOne: "",
    addressLineTwo: "",
    city: "",
    zipCode: "",
    state: "N_A",
    country: "USA",
  },
  primaryContact: undefined,
  contactFullName: "",
  contactEmail: "",
  contactMobile: "",
};

const validationSchemaStep1 = Yup.object().shape({
  name: Yup.string().required("Required"),
  address: Yup.object().shape({
    addressLineOne: Yup.string().required("Required"),
    addressLineTwo: Yup.string(),
    city: Yup.string().required("Required"),
    zipCode: YupZip(),
    country: Yup.string().required("Country is required"),
    state: YupState(),
  }),
  primaryContact: Yup.object().nullable(),
});

const validationSchemaStep2 = Yup.object().shape({
  contactFullName: Yup.string()
    .nullable()
    .when("primaryContact", {
      is: (primaryContact: PMBContact) =>
        primaryContact === null || primaryContact === undefined,
      then: (schema) => schema.required("Required"),
    }),
  contactMobile: Yup.string()
    .nullable()
    .when("primaryContact", {
      is: (primaryContact: PMBContact) =>
        primaryContact === null || primaryContact === undefined,
      then: (schema) => schema.required("Required"),
    }),
  contactEmail: Yup.string()
    .email()
    .nullable()
    .when("primaryContact", {
      is: (primaryContact: PMBContact) =>
        primaryContact === null || primaryContact === undefined,
      then: (schema) => schema.required("Required"),
    }),
});

const steps = ["Company address", "Primary Contact"];

const CompanyFormStepper = () => {
  const navigate = useNavigate();
  const client = useClient();

  const [nextDisabled, setNextDisabled] = useState(true);
  const [activeStep, setActiveStep] = React.useState(0);
  const [maxStep, setMaxStep] = React.useState(0);
  const [validationSchema, setValidationSchema] = React.useState(
    validationSchemaStep1,
  );
  const [postError, setPostError] = useState<string | null>(null);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      const stepNumber = prevActiveStep + 1;
      if (stepNumber === 1) {
        setValidationSchema(
          validationSchemaStep1.concat(validationSchemaStep2),
        );
      }
      if (stepNumber > maxStep) {
        setMaxStep(stepNumber);
      }
      return stepNumber;
    });

    if (maxStep <= activeStep) {
      setNextDisabled(true);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      const stepNumber = prevActiveStep - 1;
      if (stepNumber === 0) {
        setValidationSchema(validationSchemaStep1);
      }
      return stepNumber;
    });
    setNextDisabled(false);
  };

  const handleSubmit = async (
    values: CompanyFormType,
    { resetForm, setErrors, setStatus, setSubmitting }: any,
  ) => {
    try {
      if (!values.primaryContact) {
        values.primaryContact = {
          id: 0,
          fullName: values.contactFullName,
          email: values.contactEmail,
          mobile: values.contactMobile,
        };
      }

      const response = await unpackResponse(
        client.POST(`/companies`, { body: values }),
      );

      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
      navigate(`/company/${response.id}/profile`);
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
      setPostError(
        error.response.data?.message ||
          error.message ||
          errorMessages.get("GENERAL"),
      );
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    formik.validateForm();
    setNextDisabled(!(formik.isValid && formik.dirty));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, formik.isValid, formik.dirty]);

  // When user select any other Country then [US, Canada] state is null
  useEffect(() => {
    if (!countryRequiresState(formik.values.address.country)) {
      formik.setFieldValue("state", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.address.country]);

  return (
    <Box sx={{ width: "100%" }}>
      {postError && (
        <>
          <Alert
            severity="error"
            onClose={() => {
              setPostError(null);
            }}
          >
            Cannot create contact — <strong>{postError}</strong>
          </Alert>
        </>
      )}
      <>
        <PmbLoader
          total={steps.length}
          current={activeStep + 1}
          text={steps[activeStep]}
          topic="company"
        />
        <>
          {formik.status && formik.status.sent && (
            <Alert severity="success" my={3}>
              Your data has been submitted successfully!
            </Alert>
          )}

          {formik.isSubmitting ? (
            <LoadingSpinner />
          ) : (
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <PmbCard>
                  <CardContent>
                    {/* Company Address */}
                    {activeStep === 0 && <CompanyAddressFormik />}
                    {/* Primary Contact */}
                    {activeStep === 1 && <PrimaryContactFormik />}
                  </CardContent>
                </PmbCard>

                <PmbFormButtons
                  text={"company"}
                  current={activeStep + 1}
                  disabled={nextDisabled}
                  total={steps.length}
                  handleBack={handleBack}
                  handleNext={handleNext}
                />
              </form>
            </FormikProvider>
          )}
        </>
      </>
    </Box>
  );
};

function CompanyForm() {
  return (
    <>
      <ViewHeader
        title="Create a new company"
        breadcrumb={[
          {
            label: "Companies",
            url: `/company/list`,
          },
          {
            label: "Add Company",
          },
        ]}
      />

      <CompanyFormStepper />
    </>
  );
}

export default CompanyForm;
