import { RouteObject } from "react-router-dom";
import { Authenticated } from "./Authenticated";
import async from "./components/Async";
import CompanyForm from "./pages/Company/CompanyForm";
import { CompanyProfileFetch } from "./pages/Company/CompanyProfile";
import { LocationTopologyFetch } from "./pages/Device/location-topology-view";
import { LocationProfileFetch } from "./pages/Location/LocationProfile";
import Page404 from "./pages/auth/Page404";
import { LandingPageRedirect } from "./ui/landing-page-redirect";
import { CompanyListView } from "./ui/views/company-list-view";
import { CompanyPaymentMethodsFetch } from "./ui/views/company-payment-methods-view";
import { CompanySetupIntentViewFetch } from "./ui/views/company-setup-intent-view";
import { CompanySubscriptionsFetch } from "./ui/views/company-subscriptions-view";
import { ContactListView } from "./ui/views/contact-list-view";
import { CreateLocationViewFetch } from "./ui/views/create-location-view";
import { LocationListView } from "./ui/views/location-list-view";
import { LocationSubscriptionFetch } from "./ui/views/location-subscription-detail-view";
import { UserProfile } from "./ui/views/profile-view";
import { TransactionListView } from "./ui/views/transaction-list-view";
import { VerifyEmailPage } from "./ui/views/verify-email-view";
import { DeviceGroupFetch } from "./pages/Device/device-group-view";
const PmbDashboard = async(() => import("./pages/dashboard"));

export const routes: RouteObject[] = [
  // All routes within this route object require a user to be authenticated.
  {
    element: <Authenticated />,
    children: [
      {
        index: true,
        element: <LandingPageRedirect />,
      },
      {
        path: "dashboard",
        element: <PmbDashboard />,
      },

      // Contacts
      {
        path: "contact/list",
        element: <ContactListView />,
      },

      // Locations
      {
        path: "location/list",
        element: <LocationListView />,
      },
      {
        path: "location/create",
        element: <CreateLocationViewFetch />,
      },
      {
        path: "location/:locationId/profile",
        element: <LocationProfileFetch />,
      },
      {
        path: "location/:locationId/topology",
        element: <LocationTopologyFetch />,
      },
      {
        path: "location/:locationId/topology/device-groups/:deviceGroupId",
        element: <DeviceGroupFetch />,
      },
      {
        path: "location/:locationId/subscription",
        element: <LocationSubscriptionFetch />,
      },

      // Companies
      {
        path: "company/list",
        element: <CompanyListView />,
      },
      {
        path: "company/create",
        element: <CompanyForm />,
      },
      {
        path: "company/:companyId/profile",
        element: <CompanyProfileFetch />,
      },
      {
        path: "company/:companyId/payment-methods",
        element: <CompanyPaymentMethodsFetch />,
      },
      {
        path: "company/:companyId/payment-methods/verify",
        element: <CompanySetupIntentViewFetch />,
      },
      {
        path: "company/:companyId/subscriptions",
        element: <CompanySubscriptionsFetch />,
      },

      // Transactions list
      {
        path: "transaction/list",
        element: <TransactionListView />,
      },

      // User profile
      {
        path: "profile",
        element: <UserProfile />,
      },
    ],
  },

  // These routes do not require an authenticated user.
  {
    path: "email/:contactId/:emailId",
    element: <VerifyEmailPage />,
  },

  {
    path: "*",
    element: <Page404 />,
  },
];
