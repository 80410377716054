import { TablePagination } from "@mui/material";

export function ViewTablePagination(props: {
  pageSize: number;
  pageNumber: number;
  onSetPageSize: (size: number) => void;
  onSetPageNumber: (page: number) => void;
  totalElements?: number;
  elementsPerPageOptions: number[];
}) {
  return (
    <TablePagination
      component="div"
      rowsPerPageOptions={props.elementsPerPageOptions}
      count={props.totalElements ?? -1}
      page={props.pageNumber}
      onPageChange={(_, page) => props.onSetPageNumber(page)}
      rowsPerPage={props.pageSize}
      onRowsPerPageChange={(event) =>
        props.onSetPageSize(Number(event.target.value))
      }
    />
  );
}
