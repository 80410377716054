import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";
import { AlertCircle } from "react-feather";

export function TextWithIcon(props: {
  children: ReactNode;
  icon?: ReactNode;
  color?: string;
  variant?: "body1" | "body2";
}) {
  return (
    <Typography
      color={props.color ?? "error.main"}
      variant={props.variant ?? "body2"}
      sx={{ "&+&": { marginTop: 3 } }}
    >
      <Box
        component="span"
        sx={{
          display: "inline-block",
          marginRight: "0.5em",
          position: "relative",
          top: "0.15em",
        }}
      >
        {props.icon ?? <AlertCircle size="1em" />}
      </Box>
      {props.children}
    </Typography>
  );
}
