import { Box, Typography } from "@mui/material";
import { useClientSWR } from "../client";
import { PMBSchemas } from "../client/types";
import { EnumFormatter, EnumMapper } from "../utils/enum-formatter";
import { mf } from "../utils/formatters";
import { ErrorMessage } from "./info-card";
import LoadingSpinner from "./loading-spinner";
import { ViewDialog } from "./view-dialog";

export const content = {
  transactionAttemptHeading: mf(`Attempt {attemptNumber}`),
  transactionAttempt: mf(
    `{transactionDate, date, ::dMMMMyyyy} - {amount, number, ::currency/USD}`,
  ),
};

const transactionStatusFormatter = new EnumFormatter<
  PMBSchemas["PaymentTransactionStatus"]
>({
  SUCCESS: "Success",
  PENDING: "Pending",
  FAILED: "Failed",
});

const transactionStatusColorMap = new EnumMapper<
  PMBSchemas["PaymentTransactionStatus"],
  string
>(
  {
    SUCCESS: "success.main",
    PENDING: "warning.main",
    FAILED: "error.main",
  },
  "info.main",
);

export function PaymentDetailDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  paymentId: number;
}) {
  const { data, error } = useClientSWR("/payments/{paymentId}", {
    params: { path: { paymentId: props.paymentId } },
  });

  return (
    <ViewDialog
      {...props}
      title="Payment detail"
      padding={9}
      buttonVariant="contained"
    >
      {error ? (
        <ErrorMessage heading="Fetch failed" message={error.message} />
      ) : data ? (
        <div>
          {!data.transactions || data.transactions.length === 0 ? (
            "No transactions"
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
              {data.transactions.map((transaction, index) => {
                return (
                  <div key={transaction.id}>
                    <Typography>
                      {content.transactionAttemptHeading.format({
                        attemptNumber: index + 1,
                      })}
                      {": "}
                      <Typography
                        color={transactionStatusColorMap.get(
                          transaction.status,
                        )}
                        component="span"
                        fontWeight="bold"
                      >
                        {transactionStatusFormatter.format(transaction.status)}
                        {transaction.description &&
                          ` — ${transaction.description}`}
                      </Typography>
                    </Typography>
                    <Typography variant="body2">
                      {content.transactionAttempt.format({
                        amount: transaction.amount,
                        transactionDate: new Date(
                          transaction.transactionDate ?? 0,
                        ),
                      })}{" "}
                    </Typography>
                  </div>
                );
              })}
            </Box>
          )}
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </ViewDialog>
  );
}
