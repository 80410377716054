import { Typography } from "@mui/material";
import { unpackResponse, useClient } from "../client";
import { PMBSchemas } from "../client/types";
import { ConfirmDialog } from "./confirm-dialog";
import { Box } from "@mui/system";

export function ConfirmLocationDeactivationDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  location: PMBSchemas["LocationBase"];
  revalidateView: () => void;
}) {
  const { location, ...dialogProps } = props;
  const client = useClient();

  return (
    <ConfirmDialog
      {...dialogProps}
      title={`Deactivate ${location.name}?`}
      confirmationString={location.name}
      description={
        <>
          <Typography>
            <strong>BEWARE!</strong> Location deactivation has significant
            impact and <strong>cannot be undone</strong>! Once deactivated, the
            following events will be triggered:
          </Typography>
          <Box component="ul" sx={{ marginTop: 4, marginBottom: 3 }}>
            <Typography component="li">
              Location will be marked as permanently deactivated.
            </Typography>
            <Typography component="li">
              Its parent company will also be deactivated (unless there is
              another active location bound to it).
            </Typography>
            <Typography component="li">
              All location and company contacts will be deleted and users will
              lose access to PMB cloud application.
            </Typography>
            <Typography component="li">
              All location devices will be disabled and disconnected from the
              network.
            </Typography>
          </Box>

          <Typography>
            Historic transactional and billing data will remain accessible in
            the app for PMB admin users.
          </Typography>
          <Typography sx={{ marginTop: 4 }}>
            Please write the name of this location ({location.name}) into the
            field below to confirm.
          </Typography>
        </>
      }
      submitLabel="Deactivate"
      submitColor="error"
      onSubmit={async () => {
        await unpackResponse(
          client.PATCH("/locations/{locationId}/deactivate", {
            params: { path: { locationId: location.id } },
          }),
        );

        props.revalidateView();
      }}
    />
  );
}
