import { Autocomplete, Grid, TextField } from "@mui/material";
import PmbFormGrid from "../../components/pmb/form/PmbFormGrid";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { Button as MuiButton } from "@mui/material";
import styled from "@emotion/styled";
import { CompanyFormType } from "../CompanyForm";
import { PMBContact, PMBSchemas } from "../../../client/types";
import { getContactLabel } from "../../../utils/common";
import { unpackResponse, useClient } from "../../../client";

const AutocompleteAddItem = styled(MuiButton)`
  width: 100%;
  justify-content: left;
  color: black;

  & > span {
    color: #2d51ff;
    font-weight: 700;
    padding-right: 10px;
  }
`;

const PrimaryContactFormik = () => {
  const client = useClient();
  const { values, handleBlur, touched, errors, setFieldValue } =
    useFormikContext<CompanyFormType>();
  const [primaryContacts, setPrimaryContacts] = useState<PMBContact[]>([]);
  const [primaryContactNames, setPrimaryContactNames] = useState<string[]>([]);
  const [showFormForNewContact, setShowFormForNewContact] =
    useState<boolean>(false);
  const [newContactName, setNewContactName] = useState<string>("");

  const fetchContacts = async () => {
    const { content: contacts } = await unpackResponse(client.GET("/contacts"));

    const primaryContactsFiltered = contacts.filter(
      (contact: PMBContact) =>
        contact.isInternal === false &&
        contact.contactStatus === "ACTIVE" &&
        !contact.companyId &&
        !contact.locationId,
    );
    setPrimaryContacts(primaryContactsFiltered);

    const primaryContactNames = primaryContactsFiltered.map(
      (item: PMBContact) => item.fullName + " (" + item.email + ")",
    );
    setPrimaryContactNames(primaryContactNames);
  };

  const selectPrimaryContact = (
    contact: PMBSchemas["PrimaryContactProperties"],
  ) => {
    setNewContactName("");
    setFieldValue("primaryContact", contact);
    setShowFormForNewContact(false);
  };

  useEffect(() => {
    fetchContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PmbFormGrid>
      <Grid item xs={12}>
        <Autocomplete
          value={values.primaryContact}
          options={primaryContacts}
          onInputChange={(_event, value) => {
            setNewContactName(value);
            setFieldValue("contactFullName", value);
          }}
          getOptionLabel={(option) =>
            getContactLabel({ contact: option as PMBSchemas["Contact"] })
          }
          onChange={(_e, value) => {
            selectPrimaryContact(
              value as PMBSchemas["PrimaryContactProperties"],
            );
          }}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              name="contactFullName"
              label="Name"
              error={Boolean(touched.contactFullName && errors.contactFullName)}
              helperText={touched.contactFullName && errors.contactFullName}
              onBlur={handleBlur}
              variant="standard"
              fullWidth
            />
          )}
        />
        {!showFormForNewContact &&
        newContactName.length > 3 &&
        !primaryContactNames.includes(newContactName) ? (
          <AutocompleteAddItem
            onClick={() => {
              setFieldValue("primaryContact", null);
              setFieldValue("contactFullName", newContactName);
              setFieldValue("contactMobile", "");
              setFieldValue("contactEmail", "");
              setShowFormForNewContact(true);
            }}
          >
            <span>Add new Contact:</span>
            {newContactName}
          </AutocompleteAddItem>
        ) : null}
      </Grid>
      {showFormForNewContact ? (
        <>
          <Grid container item xs={12} spacing={8}>
            <Grid item xs={12} lg={6}>
              <TextField
                label="Mobile"
                name={"contactMobile"}
                value={values.contactMobile}
                onBlur={handleBlur}
                onChange={(event) => {
                  setFieldValue("contactMobile", event.target.value);
                }}
                variant="standard"
                fullWidth
                error={Boolean(touched.contactMobile && errors.contactMobile)}
                helperText={touched.contactMobile && errors.contactMobile}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                label="Email"
                name={"contactEmail"}
                value={values.contactEmail}
                onBlur={handleBlur}
                onChange={(event) => {
                  setFieldValue("contactEmail", event.target.value);
                }}
                variant="standard"
                fullWidth
                error={Boolean(touched.contactEmail && errors.contactEmail)}
                helperText={touched.contactEmail && errors.contactEmail}
              />
            </Grid>
          </Grid>
        </>
      ) : null}
    </PmbFormGrid>
  );
};

export default PrimaryContactFormik;
