import { PMBSchemas } from "../client/types";
import { EnumFormatter } from "../utils/enum-formatter";

export const accountsManagement = new EnumFormatter<
  PMBSchemas["AccountsManagement"]
>({
  CAMPUS: "Campus",
  CLUB_CARDS: "Club Cards",
  POS_INTEGRATION: "POS Integration",
  STANDARD: "Standard",
  HOTEL_CARDS: "Hotel Cards",
});
