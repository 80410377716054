import { useMsalAccount } from "../auth/AccountContext";
import { publicConfig } from "../config";
import { downloadBlob } from "../utils/download-blob";

export function withTimestamp(name: string) {
  const timestamp = new Date()
    .toISOString()

    // Replace time marker
    .replace("T", "_")

    // Replace time separators (cannot use colons in filenames)
    .replaceAll(":", "-")

    // Remove sub-second precisions and timezone
    .replace(/\.\d{3}Z$/, "");

  return name.replace(/\.([a-zA-Z0-9]+)$/, `_${timestamp}.$1`);
}

/**
 * Downloads specified resources to file using the provided name.
 *
 * This hook is necessary because the OpenAPI client we use elsewhere
 * seems to always attempt to JSON.parse the response, but in this
 * case we need access to the raw response so that we get its Blob.
 */

export function useDownloadClient() {
  const { token } = useMsalAccount();

  return async function downloadPath(
    path: string,
    options: { filename: string; mimeType?: string },
  ) {
    const response = await fetch(`${publicConfig.uri}${path}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: options.mimeType ?? "*/*",
      },
    });

    downloadBlob(await response.blob(), options.filename);
  };
}
