/**
 * Handles mapping enums to arbitrary values. This is a low-level class that
 * should only be used when non-string values are needed. If the value of V
 * is string, use `EnumFormatter`.
 *
 * Using this class is preferrable to manually creating mapping records as
 * the `get` function handles undefined or non-recognised enum arguments
 * with a consistent fallback value.
 *
 * Additionally, a strongly typed `enums` array is also provided for further
 * use within the application.
 *
 * Make sure to always provide the type argument to the formatter to make
 * sure that all known enum cases are covered.
 */
export class EnumMapper<K extends string | number, V> {
  private map: Map<K, V>;
  private fallback: V;

  constructor(values: Record<K, V> | Array<[K, V]>, fallback: V) {
    const entries = Array.isArray(values)
      ? values
      : (Object.entries(values) as Array<[K, V]>);
    this.map = new Map(entries) as Map<K, V>;
    this.fallback = fallback;
  }

  /**
   * Returns a value corresponding to the provided key.
   *
   * If no match is found, returns the fallback value provided in the constructor.
   *
   * Note that TypeScript will make sure that we only ever use the right types
   * as the enum param, but it can still happen at runtime that a different
   * values are provided at runtime.
   */
  get(e: K | undefined): V {
    return (e && this.map.get(e)) ?? this.fallback;
  }

  /**
   * All enums known by this mapper.
   *
   * This can be useful for things like providing options to Select or
   * Autocomplete components.
   */
  get enums() {
    return Array.from(this.map.keys());
  }
}

/**
 * Handles formatting enums to user-friendly labels. This will most likely
 * be used with PMBSchema enums, but it can be used in other cases too.
 *
 * Using this class is preferrable to manually creating mapping records as
 * the `format` function handles undefined or non-recognised enum arguments
 * with a consistent fallback label.
 *
 * Additionally, a strongly typed `enums` array is also provided for further
 * use within the application.
 *
 * Make sure to always provide the type argument to the formatter to make
 * sure that all known enum cases are covered with a label.
 *
 * @example
 * ```ts
 * // Define formatter instance
 * export const userStatus = new EnumFormatter<PMBSchemas["UserStatus"]>({
 *   ACTIVE: "Active",
 *   BLOCKED: "Blocked",
 *   INVITED: "Invited",
 *   NOT_INVITED: "Not invited yet",
 * });
 *
 * // Returns "Not invited yet".
 * userStatus.format("NOT_INVITED")
 *
 * // Returns "Unknown".
 * useStatus.format()
 *
 * // This is typecheck error, but it can still happen at runtime, in which
 * // case it returns "Unknown".
 * useStatus.format("X")
 *
 * ```
 */

export class EnumFormatter<K extends string> extends EnumMapper<K, string> {
  constructor(record: Record<K, string>, fallback: string = "Unknown") {
    super(record, fallback);
  }

  /**
   * Returns a user-fiendly label based on provided enum.
   *
   * If no match is found, returns the fallback value.
   *
   * Note that TypeScript will make sure that we only ever use the right types
   * as the enum param, but it can still happen at runtime that a different
   * value is provided from the server.
   */
  format(e?: K) {
    return this.get(e);
  }
}
