import { CreditCard, EventRepeat } from "@mui/icons-material";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Award, DollarSign, Home, Users } from "react-feather";
import { useClientSWR } from "../../client";
import { SidebarItemsType } from "../../types/sidebar";
import { useUserInfo } from "../../ui/user-info-context";
import { includesAnyOf } from "../../utils/one-of";

const adminHome = {
  href: "/",
  icon: Home,
  title: "Home",
};

const company = {
  href: "/company/list",
  icon: Award,
  title: "Companies",
};

const location = {
  href: "/location/list",
  icon: LocationOnOutlinedIcon,
  title: "Locations",
};

const contact = {
  href: "/contact/list",
  icon: Users,
  title: "Contacts",
};

const transaction = {
  href: "/transaction/list",
  icon: DollarSign,
  title: "Transactions",
};

export function useDashboardItems(): {
  title: string;
  pages: SidebarItemsType[];
}[] {
  const { roles, principal } = useUserInfo();

  if (includesAnyOf(["PMB_ADMIN", "SYSTEM_ADMIN"], roles)) {
    return pmbAdminDashboardItems();
  }

  if (includesAnyOf(["PMB_VIEWER"], roles)) {
    return pmbViewerDashboardItems();
  }

  if (includesAnyOf(["COMPANY_ADMIN"], roles) && principal.companyId) {
    return CompanyDashboardItems({ companyId: principal.companyId });
  }

  if (includesAnyOf(["LOCATION_ADMIN"], roles) && principal.locationId) {
    return LocationDashboardItems({
      locationId: principal.locationId,
      isAdmin: true,
    });
  }

  if (includesAnyOf(["LOCATION_VIEWER"], roles) && principal.locationId) {
    return LocationDashboardItems({
      locationId: principal.locationId,
      isAdmin: false,
    });
  }

  return [
    {
      title: "Pages",
      pages: [],
    },
  ];
}

function pmbAdminDashboardItems() {
  return [
    {
      title: "Pages",
      pages: [
        adminHome,
        company,
        location,
        contact,
        transaction,
      ] as SidebarItemsType[],
    },
  ];
}

function pmbViewerDashboardItems() {
  return [
    {
      title: "Pages",
      pages: [adminHome, company, location, contact] as SidebarItemsType[],
    },
  ];
}

function CompanyDashboardItems(props: { companyId: number }) {
  const { data, error } = useClientSWR("/companies/{companyId}/locations", {
    params: { path: { companyId: props.companyId } },
  });

  if (error) {
    alert("Error fetching company locations data, Error: " + error.message);
    return [];
  }

  if (!data) {
    return [];
  }

  const home = {
    href: `/company/${props.companyId}/profile`,
    icon: Home,
    title: "Home",
  };

  const pms = {
    href: `/company/${props.companyId}/payment-methods`,
    icon: CreditCard,
    title: "Payment Methods",
  };

  const subs = {
    href: `/company/${props.companyId}/subscriptions`,
    icon: EventRepeat,
    title: "Subscriptions",
  };

  const locations = data.content.map((location) => {
    return {
      icon: LocationOnOutlinedIcon,
      title: location.name,
      children: [
        {
          href: `/location/${location.id}/profile`,
          icon: BadgeOutlinedIcon,
          title: "Profile",
        },
        // Hide Devices (PMB-599)
        // {
        //   href: `/location/${location.id}/topology`,
        //   icon: DevicesOutlined,
        //   title: "Devices",
        // },
        {
          href: `/location/${location.id}/subscription`,
          icon: EventRepeat,
          title: "Subscription",
        },
      ],
    };
  });

  return [
    {
      title: "Pages",
      pages: [home, pms, subs, ...locations] as SidebarItemsType[],
    },
  ];
}

function LocationDashboardItems(props: {
  locationId: number;
  isAdmin: boolean;
}) {
  const { data, error } = useClientSWR("/locations/{locationId}", {
    params: { path: { locationId: props.locationId } },
  });

  if (error) {
    alert("Error fetching company locations data, Error: " + error.message);
    return [];
  }

  if (!data) {
    return [];
  }

  const home = {
    href: `/location/${props.locationId}/profile`,
    icon: Home,
    title: "Home",
  };

  // Hide Devices (PMB-599)
  // const devices = {
  //   href: `/location/${data.id}/topology`,
  //   icon: DevicesOutlined,
  //   title: "Devices",
  // };

  const subscription = {
    href: `/location/${data.id}/subscription`,
    icon: EventRepeat,
    title: "Subscription",
  };

  const pages: SidebarItemsType[] = [];
  pages.push(home);

  // pages.push(devices);

  if (props.isAdmin) {
    pages.push(subscription);
  }

  return [
    {
      title: "Pages",
      pages: pages,
    },
  ];
}
