import { useMsal } from "@azure/msal-react";
import { Alert, Box, Button, Paper, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { unpackResponse, useClient, useClientSWR } from "../../client";
import { caughtValueToString } from "../../utils/caught-error";
import { ErrorMessage } from "../info-card";
import LoadingSpinner from "../loading-spinner";

function VerifyForm(props: {
  params: { contactId: number; uuid: string };
  newEmail: string;
}) {
  const { instance } = useMsal();
  const client = useClient();
  const formik = useFormik({
    initialValues: {},
    async onSubmit() {
      try {
        await unpackResponse(
          client.POST("/email/{contactId}/{uuid}", {
            params: { path: props.params },
          }),
        );

        instance.logoutRedirect();
      } catch (error) {
        formik.setStatus(caughtValueToString(error));
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Box sx={{ height: "100%" }}>
        <Typography variant="h2" component="h1" sx={{ marginBottom: 4 }}>
          Verify new email
        </Typography>

        <Typography sx={{ marginBottom: 8 }}>
          Use the button below to change your login email to {props.newEmail}.
          You will have to log in again to complete the process.
        </Typography>

        {!formik.isSubmitting && formik.status && (
          <Alert severity="error" sx={{ marginBottom: 4 }}>
            {formik.status}
          </Alert>
        )}

        <Button type="submit" variant="contained" size="large">
          Verify
        </Button>
      </Box>
    </form>
  );
}

export function VerifyEmailPage() {
  const { contactId, emailId } = useParams<"emailId" | "contactId">();
  const params = { contactId: Number(contactId), uuid: emailId! };
  const { data, error } = useClientSWR("/email/{contactId}/{uuid}", {
    params: { path: params },
  });

  if (data) {
    return (
      <Box
        sx={{
          height: "100%",
          backgroundColor: "background.default",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 4,
        }}
      >
        <Paper sx={{ padding: 12, width: "min(100%, 36rem)" }}>
          <Box sx={{ marginBottom: 8 }}>
            <img
              alt="PMB-logo"
              src="/static/img/brands/pmb-color.svg"
              style={{ height: "auto", width: "8rem", display: "block" }}
            />
          </Box>

          <VerifyForm params={params} {...data} />
        </Paper>
      </Box>
    );
  }

  if (error) {
    return <ErrorMessage heading="Failed to load email verification" />;
  }

  return <LoadingSpinner />;
}
