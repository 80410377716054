import { useCallback } from "react";
import { unpackResponse, useClient } from "../client";
import { useUserInfo } from "../ui/user-info-context";
import { PMBSchemas } from "../client/types";
import { includesAnyOf } from "../utils/one-of";
import useSWR from "swr";

export type SubscriptionDataType = {
  subscription: PMBSchemas["Subscription"];
  location: PMBSchemas["LocationBase"];
};

/**
 * List of Onboarding Steps, used in Onboarding check and Onboarding process.
 */
export type OnboardingStep =
  | { type: "DETAILS" }
  | { type: "COMPANY"; company: PMBSchemas["Company"] }
  | { type: "LOCATION"; location: PMBSchemas["LocationBase"] }
  | {
      type: "SUBSCRIPTION";
      subscription: PMBSchemas["Subscription"];
      location: PMBSchemas["LocationBase"];
    }
  | {
      type: "TERMS_OF_SERVICE";
      company: PMBSchemas["Company"];
      tos: PMBSchemas["Tos"];
    }
  | {
      type: "CANNOT_ONBOARD";
      primaryContact?: PMBSchemas["Contact"];
    }
  | { type: "SUCCESS" };

/**
 * Onboarding SWR is a Custom React Hook for the Onboarding Process
 * which runs after every render.
 *
 * It returns the necessary steps, which are needed to be done before the
 * user can enter the application or error in case of failure. It is an
 * custom SWR with custom logic for the Onboarding data fetching.
 *
 * This SWR client exists because of a specific data fetching according to
 * the user role. All the data are processed and returned like classical SWR.
 *
 * Step also contains a related data, which are later used in Onboarding page.
 *
 * Steps:
 * "SUCCESS" - There is no need for onboarding
 * "CANNOT_ONBOARD" - User is not allowed to enter the app
 * Others - Onboarding process is needed
 */
export function useOnboardingSWR() {
  const client = useClient();
  const { roles, principal } = useUserInfo();

  // The fetcher function has to be stabilized with useCallback to make
  // sure it doesn't change on every render.
  const fetcher = useCallback(async (): Promise<OnboardingStep[]> => {
    /**
     * If a non-PMB internal App User (Company Admin, Location Admin or
     * Location Viewer) tries to open its Location Detail page that has
     * not been confirmed by the respective Company Admin before (i.e.
     * the confirmed flag is not true), an error page is displayed and
     * the user cannot do anything in the app.
     * PMB internal users can see the page normally.
     */
    const isRestrictedUser = includesAnyOf(roles, [
      "LOCATION_ADMIN",
      "LOCATION_VIEWER",
    ]);

    /**
     * If a non-PMB internal App User (Company Admin) tries to open its
     * Location Detail page that has not been confirmed (i.e. the confirmed
     * flag is not true), an onboarding page is displayed and the user have
     * to confirm the Company/Location before he can do anything in the app.
     */
    const isCompanyAdmin = includesAnyOf(roles, ["COMPANY_ADMIN"]);

    if (!isCompanyAdmin && !isRestrictedUser) {
      return [{ type: "SUCCESS" }];
    }

    if (principal.companyId) {
      const [tos, company, locations] = await Promise.all([
        unpackResponse(client.GET("/tos/actual")),
        unpackResponse(
          client.GET("/companies/{companyId}", {
            params: { path: { companyId: principal.companyId } },
          }),
        ),
        unpackResponse(
          client.GET("/companies/{companyId}/locations", {
            params: {
              path: { companyId: principal.companyId },
              query: { page_size: 1000 },
            },
          }),
        ),
      ]);

      const subscriptions = await Promise.all(
        locations.content.map((location) =>
          unpackResponse(
            client.GET("/locations/{locationId}/subscription", {
              params: { path: { locationId: location.id } },
            }),
          ),
        ),
      );

      return companySteps({
        company,
        locations: locations.content,
        subscriptions,
        tos,
        isCompanyAdmin,
        isRestrictedUser,
      });
    }

    if (principal.locationId) {
      const [location, subscription] = await Promise.all([
        unpackResponse(
          client.GET("/locations/{locationId}", {
            params: { path: { locationId: principal.locationId } },
          }),
        ),
        unpackResponse(
          client.GET("/locations/{locationId}/subscription", {
            params: { path: { locationId: principal.locationId } },
          }),
        ),
      ]);

      return locationSteps({
        location,
        subscription,
        isCompanyAdmin,
        isRestrictedUser,
      });
    }

    return [{ type: "SUCCESS" }];
  }, [client, principal.companyId, principal.locationId, roles]);

  return useSWR(`onboarding-${principal.id}`, fetcher);
}

function locationSteps(props: {
  location: PMBSchemas["LocationBase"];
  subscription: PMBSchemas["Subscription"];
  isCompanyAdmin: boolean;
  isRestrictedUser: boolean;
}): OnboardingStep[] {
  const steps: (OnboardingStep | null)[] = [
    !props.location.isConfirmed
      ? { type: "LOCATION", location: props.location }
      : null,
    props.subscription.status === "PENDING_ACTIVATION"
      ? {
          type: "SUBSCRIPTION",
          subscription: props.subscription,
          location: props.location,
        }
      : null,
  ];

  const innerSteps = steps.filter(
    <T>(item: T): item is NonNullable<T> => !!item,
  );

  if (innerSteps.length > 0) {
    if (props.isRestrictedUser) {
      return [{ type: "CANNOT_ONBOARD" }];
    }
    if (props.isCompanyAdmin) {
      return [{ type: "DETAILS" }, ...innerSteps, { type: "SUCCESS" }];
    }
  }

  return [{ type: "SUCCESS" }];
}

function companySteps(props: {
  company: PMBSchemas["Company"];
  locations: PMBSchemas["LocationBase"][];
  subscriptions: PMBSchemas["Subscription"][];
  tos: PMBSchemas["Tos"];
  isCompanyAdmin: boolean;
  isRestrictedUser: boolean;
}): OnboardingStep[] {
  const steps: (OnboardingStep | null)[] = [
    !props.company.isConfirmed
      ? { type: "COMPANY", company: props.company }
      : null,
    ...props.locations.flatMap((location) => {
      if (location.isConfirmed) {
        return [];
      }
      const step: OnboardingStep = {
        type: "LOCATION",
        location,
      };
      return step;
    }),
    !props.company.isLatestTosAccepted
      ? { type: "TERMS_OF_SERVICE", company: props.company, tos: props.tos }
      : null,
    ...props.subscriptions
      .filter((subscription) => subscription.status === "PENDING_ACTIVATION")
      .map((subscription) => {
        const location = props.locations.find(
          (location) => location.id === subscription.locationId,
        );
        if (!location) {
          return null;
        }
        const step: OnboardingStep = {
          type: "SUBSCRIPTION",
          subscription,
          location,
        };
        return step;
      }),
  ];

  const innerSteps = steps.filter(
    <T>(item: T): item is NonNullable<T> => !!item,
  );

  if (innerSteps.length > 0) {
    if (props.isRestrictedUser) {
      return [
        {
          type: "CANNOT_ONBOARD",
          primaryContact: props.company.primaryContact,
        },
      ];
    }
    if (props.isCompanyAdmin) {
      return [{ type: "DETAILS" }, ...innerSteps, { type: "SUCCESS" }];
    }
  }

  return [{ type: "SUCCESS" }];
}
