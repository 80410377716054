import { Checkbox, FormControlLabel } from "@mui/material";
import { FormikValues, useFormik } from "formik";

export function getCheckboxFieldProps<
  Values extends FormikValues,
  Name extends string & keyof Values,
>(formik: ReturnType<typeof useFormik<Values>>, name: Name) {
  const checked = formik.values[name];
  const wasTouched = formik.touched[name];
  const validationHint = formik.errors[name];

  return {
    name,
    checked,
    onBlur: () => formik.setFieldTouched(name),
    onChange: () => {
      return formik.setFieldValue(name, !checked);
    },
    error: !!(wasTouched && validationHint),
    helperText: wasTouched && validationHint,
  };
}

export function CheckboxField(props: {
  checked: boolean;
  onChange: () => void;
  label: string;
}) {
  const { label, ...checkboxProps } = props;
  return (
    <FormControlLabel control={<Checkbox {...checkboxProps} />} label={label} />
  );
}
