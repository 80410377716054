import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { List, ListItem, Button as MuiButton, Typography } from "@mui/material";
import { Box, spacing, SpacingProps } from "@mui/system";
import { PMBSchemas } from "../../client/types";
import { useMsalAccount } from "../../auth/AccountContext";
import MiddleCardTemplate from "./middle-card-template";

interface ButtonProps extends SpacingProps {
  component?: React.ElementType;
  to?: string;
  target?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

function OnboardingTemplate(props: {
  title: string;
  content: string[] | { html: string };
  btnName: string;
  subtitle?: string;
  address?: PMBSchemas["Address"];
  handleNext: () => void;
  handleEdit?: () => void;
}) {
  const { logout } = useMsalAccount();

  return (
    <MiddleCardTemplate maxWidth="600px">
      <Helmet title="Onboarding" />
      <Typography
        component="h1"
        variant="h5"
        sx={{
          display: "flex",
          padding: 4,
          alignItems: "flex-start",
          gap: 2,
          alignSelf: "stretch",
        }}
      >
        {props.title}
      </Typography>

      <Box
        sx={{
          display: "flex",
          padding: 4,
          flexDirection: "column",
          alignItems: "flex-start",
          alignSelf: "stretch",
        }}
      >
        {"html" in props.content ? (
          <Box
            dangerouslySetInnerHTML={{ __html: props.content.html }}
            sx={{
              fontFamily: [
                "Arial",
                "-apple-system",
                "BlinkMacSystemFont",
                '"Segoe UI"',
                "Roboto",
                '"Helvetica Neue"',
                "Arial",
                "Nunito",
                "sans-serif",
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
              ].join(","),
              lineHeight: 1.5,
            }}
          />
        ) : (
          props.content.map((sentence, index) => {
            const multipleLineSentence = sentence.split("\n");

            if (multipleLineSentence.length > 1) {
              return (
                <List sx={{ listStyle: "decimal", pl: 4 }}>
                  {multipleLineSentence.map((sentence, index) => (
                    <ListItem
                      key={index}
                      sx={{ display: "list-item", pl: 2, m: 0, py: 0 }}
                    >
                      <Typography
                        key={index}
                        component="p"
                        variant="body2"
                        p={0}
                        m={0}
                      >
                        {sentence}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              );
            }

            return (
              <Typography key={index} component="p" variant="body2" pb={3}>
                {sentence}
              </Typography>
            );
          })
        )}
      </Box>

      {props.address ? (
        <Box
          sx={{
            display: "flex",
            padding: 4,
            flexDirection: "column",
            alignItems: "flex-start",
            alignSelf: "stretch",
          }}
        >
          <Typography component="h2" variant="h5" mb={4}>
            {props.subtitle}
          </Typography>
          <Typography component="p" variant="body2">
            {props.address.addressLineOne}
          </Typography>
          <Typography component="p" variant="body2">
            {props.address.addressLineTwo}
          </Typography>
          <Typography component="p" variant="body2">
            {props.address.city} {props.address.state} {props.address.zipCode}
          </Typography>
          <Typography component="p" variant="body2" mb={5}>
            {props.address.country}
          </Typography>

          <Button
            component={Link}
            to="#"
            variant="outlined"
            color="primary"
            onClick={props.handleEdit}
          >
            Edit details
          </Button>
        </Box>
      ) : null}

      <Box
        sx={{
          display: "flex",
          padding: 4,
          flexDirection: "row",
          justifyContent: "space-between",
          gap: 3,
          alignSelf: "stretch",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          mt={2}
          onClick={props.handleNext}
        >
          {props.btnName}
        </Button>
        <Button variant="outlined" color="warning" mt={2} onClick={logout}>
          Logout
        </Button>
      </Box>
    </MiddleCardTemplate>
  );
}

export default OnboardingTemplate;
