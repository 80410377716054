import { object, string } from "yup";
import { countryRequiresState } from "../constants/countries";
import { getStatesOrProvincesByCountry } from "../constants/statesAndProvinces";

/**
 * @deprecated Use {@link zipCode} validator function instead.
 */
export const YupZip = () => {
  return string()
    .transform((value) => value.replace(/\s/g, ""))
    .matches(/^.{1,10}$/, "Zip code is invalid.")
    .label("ZIP")
    .required("Zip code is required.");
};

export const zipCode = () => {
  return string()
    .transform((value) => value.replace(/\s/g, ""))
    .matches(/^.{1,10}$/, "Zip code is invalid.");
};

/**
 * @deprecated Use {@link state} validator function instead.
 */
export const YupState = (countryAttributeName = "country") => {
  return string()
    .nullable()
    .when(countryAttributeName, {
      is: countryRequiresState,
      then: (schema) =>
        schema.required("State is required when the country is US or CA"),
    });
};

export function state(props: {
  /**
   * The state validator must know a reference to the country field to
   * determine whether it is required or not.
   */
  countryField: string;
}) {
  return string()
    .nullable()
    .when(props.countryField, {
      is: countryRequiresState,
      then: (schema) =>
        schema.required("State is required when country is USA or Canada."),
    })
    .when(props.countryField, {
      is: "USA",
      then(schema) {
        return schema.oneOf(
          getStatesOrProvincesByCountry("USA"),
          "Must be a state or territory of the USA.",
        );
      },
    })
    .when(props.countryField, {
      is: "CANADA",
      then(schema) {
        return schema.oneOf(
          getStatesOrProvincesByCountry("CANADA"),
          "Must be a province or territory of Canada.",
        );
      },
    });
}

export const addressSchema = object().shape({
  addressLineOne: string().required("Address line 1 is required."),
  addressLineTwo: string(),
  city: string().required("City is required."),
  country: string().required("Country is required."),
  state: state({ countryField: "country" }),
  zipCode: YupZip(),
});
