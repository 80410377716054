import styled from "@emotion/styled";
import { Box, Button as MuiButton } from "@mui/material";
import { SpacingProps, spacing } from "@mui/system";

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

type FormButtonsProps = {
  text: string;
  current: number;
  disabled: boolean;
  update?: boolean;
  total: number;
  handleBack: () => void;
  handleNext: () => void;
};

const PmbFormButtons = ({
  text,
  disabled = false,
  handleBack,
  handleNext,
  current,
  total,
  update,
}: FormButtonsProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        pt: "36px",
        m: "16px",
      }}
    >
      <Button
        color="primary"
        onClick={handleBack}
        sx={{
          mr: 1,
          display: current !== 1 ? "block" : "none",
        }}
        variant="contained"
      >
        Back to previous step
      </Button>

      {current !== total ? (
        <Button
          disabled={disabled}
          onClick={handleNext}
          variant="contained"
          color="primary"
          ml={current !== 1 ? "40px" : 0}
        >
          Go to next step
        </Button>
      ) : null}

      {current === total ? (
        <Button
          disabled={disabled}
          type="submit"
          variant="contained"
          color="primary"
          ml={current !== 1 ? "40px" : 0}
        >
          Save & {update ? "update" : "create"} {text}
        </Button>
      ) : null}
    </Box>
  );
};

export default PmbFormButtons;
