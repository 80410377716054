import { Autocomplete, TextField } from "@mui/material";
import { FormikValues, useFormik } from "formik";
import { SyntheticEvent } from "react";

export function contactToOptionOrNull(contact?: {
  id: number;
  fullName: string;
}) {
  return contact ? contactToOption(contact) : null;
}

export function contactToOption(contact: { id: number; fullName: string }) {
  return { value: contact.id, label: contact.fullName };
}

/**
 * Retrieves props that are suitable for {@link AutocompleteSelectField}.
 *
 * Notably, this field getter encapsulates logic regarding visibility of
 * errors and helper text, which was otherwise spread all over the codebase.
 */
export function getAutocompleteSelectFieldProps<
  Values extends FormikValues,
  Name extends string & keyof Values,
>(formik: ReturnType<typeof useFormik<Values>>, name: Name) {
  const wasTouched = formik.touched[name];
  const validationHint = formik.errors[name];

  return {
    name,
    value: formik.values[name],
    onBlur: () => formik.setFieldTouched(name),
    onChange: (_event: unknown, value: Values[Name] | null | undefined) => {
      return formik.setFieldValue(name, value);
    },
    error: !!(wasTouched && validationHint),
    helperText: wasTouched && validationHint,
  };
}

export function AutocompleteSelectField<Value extends string>(props: {
  name: string;
  label: string;
  value: Value;
  helperText?: string;
  error: boolean;
  getOptionLabel?: (value: Value) => string;
  onChange: (event: SyntheticEvent, value: Value | null) => void;
  onBlur?: () => void;
  options: Value[];
}) {
  return (
    <Autocomplete
      value={props.value}
      options={props.options}
      getOptionLabel={props.getOptionLabel}
      onChange={props.onChange}
      onBlur={props.onBlur}
      renderInput={(params) => (
        <TextField
          {...params}
          name={props.name}
          label={props.label}
          error={props.error}
          helperText={props.helperText}
          variant="standard"
          fullWidth
        />
      )}
    />
  );
}
