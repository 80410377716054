import { useClientSWR } from "../client";
import { PMBSchemas } from "../client/types";

function getQuery(position: PMBSchemas["Position"]) {
  return { positions: [position], page_size: 1000 };
}

export function useInternalStaffLists() {
  const { data: salesRepsResponse } = useClientSWR("/contacts", {
    params: { query: getQuery("PMB_SALES_REP") },
  });
  const { data: projectManagersResponse } = useClientSWR("/contacts", {
    params: { query: getQuery("PMB_PROJECT_MANAGER") },
  });
  const { data: installersResponse } = useClientSWR("/contacts", {
    params: { query: getQuery("PMB_INSTALLATION_CREW") },
  });

  return {
    installers: installersResponse?.content,
    salesReps: salesRepsResponse?.content,
    projectManagers: projectManagersResponse?.content,
  };
}
