import "@mui/lab/themeAugmentation";
import { green, grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import breakpoints from "./breakpoints";
import components from "./components";
import typography from "./typography";

const background = "#F7F7F7";
const navy = "#132644";
const royal = "#2D51FF";

const customBlue = {
  50: "#e9f0fb",
  100: "#c8daf4",
  200: "#a3c1ed",
  300: "#7ea8e5",
  400: "#6395e0",
  500: "#4782da",
  600: "#407ad6",
  700: "#376fd0",
  800: "#2f65cb",
  900: "#2052c2 ",
};

export const theme = createTheme(
  {
    spacing: 4,
    breakpoints: breakpoints,

    // There is some issue with non-default components: hence the `any`.
    // This should be configurable somehow.
    components: components as any,
    typography: typography,
    palette: {
      primary: {
        main: royal,
        contrastText: "#FFF",
      },
      secondary: {
        main: customBlue[500],
        contrastText: "#FFF",
      },
      info: {
        main: grey[600],
        contrastText: "white",
      },
      background: {
        default: background,
        paper: "#FFF",
      },
      text: {
        primary: grey[600],
      },
    },
  },
  {
    header: {
      color: grey[500],
      background: "#FFF",
      search: {
        color: `rgba(0, 0, 0, 0.54)`,
      },
      indicator: {
        background: customBlue[600],
      },
    },
    footer: {
      color: grey[500],
      background: "#FFF",
    },
    sidebar: {
      color: grey[200],
      background: navy,
      header: {
        color: grey[200],
        background: navy,
        brand: {
          color: customBlue[500],
        },
      },
      footer: {
        color: grey[200],
        background: navy,
        online: {
          background: green[500],
        },
      },
      badge: {
        color: "#FFF",
        background: customBlue[500],
      },
    },
  },
);
