import { Box } from "@mui/material";

export function DummyLine(props: { width: string }) {
  return (
    <Box sx={{ paddingY: 1 }}>
      <Box
        sx={{
          backgroundColor: "background.default",
          borderRadius: 1,
          height: 12,
          width: props.width,
        }}
      />
    </Box>
  );
}
