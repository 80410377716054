import { PMBSchemas } from "../client/types";
import { ErrorCard } from "./info-card";
import { useMsalAccount } from "../auth/AccountContext";
import { Button } from "@mui/material";

/**
 * `ConfirmationErrorCard` is a specialized component that should be used to
 * handle errors coming from the Confirmation Guard.
 */
export function ConfirmationErrorCard(props: {
  contact?: PMBSchemas["Contact"];
  entitySingular?: string;
}) {
  const { logout } = useMsalAccount();

  const message = props.contact
    ? `${props.entitySingular} has not been confirmed yet. Please contact your company administrator (${props.contact.fullName}, ${props.contact.email}, ${props.contact.mobile}) to enable you working with this ${props.entitySingular}.`
    : `Entity has not been confirmed yet. Please contact your company administrator to enable you working with this location.`;

  switch (props.entitySingular) {
    case "Location": {
      return (
        <ErrorCard
          heading={`${props.entitySingular} is not confirmed`}
          message={message}
          button={
            <Button variant="outlined" color="warning" onClick={() => logout()}>
              Logout
            </Button>
          }
        />
      );
    }

    case "Company":
      return (
        <ErrorCard
          heading={`${props.entitySingular} is not confirmed`}
          message={message}
          button={
            <Button variant="outlined" color="warning" onClick={() => logout()}>
              Logout
            </Button>
          }
        />
      );

    default: {
      return (
        <ErrorCard
          heading="Missing confirmation"
          message={message}
          button={
            <Button variant="outlined" color="warning" onClick={() => logout()}>
              Logout
            </Button>
          }
        />
      );
    }
  }
}
