import { useCallback, useState } from "react";

export function useAsyncState<T>() {
  const [state, setState] = useState<
    | { type: "initial" | "pending" }
    | { type: "error"; error: unknown }
    | { type: "success"; data: T }
  >({ type: "initial" });

  const setPending = useCallback(() => setState({ type: "pending" }), []);
  const setError = useCallback(
    (error: unknown) => setState({ type: "error", error }),
    [],
  );
  const setSuccess = useCallback(
    (data: T) => setState({ type: "success", data }),
    [],
  );
  const setStateFromPromise = useCallback(
    async (promise: Promise<T>) => {
      setPending();
      try {
        const data = await promise;
        setSuccess(data);
        return data;
      } catch (error) {
        setError(error);
        throw error;
      }
    },
    [setError, setPending, setSuccess],
  );

  return {
    isPending: state.type === "pending",
    data: state.type === "success" ? state.data : null,
    error: state.type === "error" ? state.error : null,
    setStateFromPromise,
    setPending,
    setError,
    setSuccess,
  };
}
