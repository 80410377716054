import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import { ReactNode } from "react";
import { Edit3 } from "react-feather";
import { unpackResponse, useClient, useClientSWR } from "../client";
import { PMBSchemas } from "../client/types";
import { marketSegment } from "../constants/marketSegments";
import { caughtValueToString } from "../utils/caught-error";
import { intersperse } from "../utils/common";
import { formatDate } from "../utils/formatters";
import {
  AsyncAutocompleteSelectField,
  getAutocompleteSelectFieldProps,
} from "./async-autocomplete-select-field";
import {
  contactToOption,
  contactToOptionOrNull,
} from "./autocomplete-select-field";
import { ConfirmLocationDeactivationDialog } from "./confirm-location-deactivation-dialog";
import { DateField, getDateFieldProps } from "./date-field";
import { DialogButton, DialogIconButton } from "./dialog-button";
import { FormDialog } from "./form-dialog";
import { NumberField, getNumberFieldProps } from "./number-field";
import { RoleGuard } from "./role-guard";
import { getTextFieldProps } from "./text-field";
import { useInternalStaffLists } from "./use-internal-staff-lists";
import { ViewCard } from "./view-card";

const EMPTY_MARKER = "—";

function handleEmpty(value?: string | number) {
  return value ?? EMPTY_MARKER;
}

function handleEmptyDate(date?: string) {
  return date ? formatDate(date, "medium") : EMPTY_MARKER;
}

function EditLocationInternalInfoDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  locationId: number;
  locationInternals: PMBSchemas["LocationInternals"];
  onSuccess: () => void;
}) {
  const { locationInternals: initialValues } = props;
  const { installers, salesReps, projectManagers } = useInternalStaffLists();

  const client = useClient();
  const formik = useFormik({
    initialValues: {
      note: initialValues.note,
      marketSegment: initialValues.marketSegment,
      hiltonInnCode: initialValues.hiltonInnCode,
      salesOrderId: initialValues.salesOrderId,
      salesDate: initialValues.salesDate,
      dealValue: initialValues.dealValue,
      isSelfInstall: initialValues.isSelfInstall,
      installDate: initialValues.installDate,
      softOpeningDate: initialValues.softOpeningDate,
      grandOpeningDate: initialValues.grandOpeningDate,
      salesRep: contactToOptionOrNull(initialValues.salesRep),
      projectManager: contactToOptionOrNull(initialValues.projectManager),
      installer: contactToOptionOrNull(initialValues.installer),
    },
    async onSubmit(values) {
      try {
        await unpackResponse(
          client.PUT("/locations/{locationId}/internals", {
            params: { path: { locationId: props.locationId } },
            body: {
              note: values.note,
              marketSegment: values.marketSegment,
              hiltonInnCode: values.hiltonInnCode,
              salesOrderId: values.salesOrderId,
              salesDate: values.salesDate,
              dealValue: values.dealValue,
              isSelfInstall: values.isSelfInstall,
              installDate: values.installDate,
              softOpeningDate: values.softOpeningDate,
              grandOpeningDate: values.grandOpeningDate,
              installerId: values.installer?.value,
              salesRepId: values.salesRep?.value,
              projectManagerId: values.projectManager?.value,
            },
          }),
        );

        props.onClose();
        props.onSuccess();
      } catch (error) {
        formik.setStatus(caughtValueToString(error));
      }
    },
  });

  const textFieldProps = {
    InputLabelProps: { shrink: true },
    variant: "standard" as const,
    fullWidth: true,
  };

  return (
    <FormDialog
      {...props}
      title="Edit internal info"
      submitLabel="Save"
      form={formik}
    >
      <Box
        sx={{ paddingTop: 2, display: "flex", flexDirection: "column", gap: 6 }}
      >
        <TextField
          {...getTextFieldProps(formik, "note")}
          {...textFieldProps}
          label="Note"
          multiline
        />

        <InfoSubheading marginTop={6}>Deal</InfoSubheading>

        <AsyncAutocompleteSelectField
          {...getAutocompleteSelectFieldProps(formik, "salesRep")}
          label="Sales Rep"
          placeholder="Select sales rep"
          loadingText="Select sales rep (Loading...)"
          options={salesReps?.map((contact) => contactToOption(contact))}
        />

        <NumberField
          {...getNumberFieldProps(formik, "dealValue")}
          {...textFieldProps}
          value={formik.values.dealValue ?? null}
          label="Deal value"
          adornment="$"
        />

        <TextField
          {...getTextFieldProps(formik, "salesOrderId")}
          {...textFieldProps}
          label="Sales Order ID"
        />

        <TextField
          {...getTextFieldProps(formik, "marketSegment")}
          {...textFieldProps}
          label="Market Segment"
          select
        >
          {marketSegment.enums.map((e) => {
            return (
              <MenuItem key={e} value={e}>
                {marketSegment.format(e)}
              </MenuItem>
            );
          })}
        </TextField>

        <DateField
          {...getDateFieldProps(formik, "salesDate")}
          label="Sales Date"
        />

        <TextField
          {...getTextFieldProps(formik, "hiltonInnCode")}
          {...textFieldProps}
          label="Hilton Inn Code"
        />

        <InfoSubheading marginTop={6}>Installation & Opening</InfoSubheading>

        <AsyncAutocompleteSelectField
          {...getAutocompleteSelectFieldProps(formik, "projectManager")}
          label="Project Manager"
          placeholder="Select project manager"
          loadingText="Select project manager (Loading...)"
          options={projectManagers?.map((contact) => contactToOption(contact))}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.isSelfInstall}
              onChange={() => {
                formik.setFieldValue(
                  "isSelfInstall",
                  !formik.values.isSelfInstall,
                );
              }}
            />
          }
          label="Self-installation"
        />

        {!formik.values.isSelfInstall && (
          <AsyncAutocompleteSelectField
            {...getAutocompleteSelectFieldProps(formik, "installer")}
            label="Installer"
            placeholder="Select installer"
            loadingText="Select installer (Loading...)"
            options={installers?.map((contact) => contactToOption(contact))}
          />
        )}

        <DateField
          {...getDateFieldProps(formik, "installDate")}
          label="Installation Date"
        />

        <DateField
          {...getDateFieldProps(formik, "softOpeningDate")}
          label="Soft Opening Date"
        />

        <DateField
          {...getDateFieldProps(formik, "grandOpeningDate")}
          label="Grand Opening Date"
        />
      </Box>
    </FormDialog>
  );
}

function InfoSubheading(props: { children: ReactNode; marginTop?: number }) {
  return (
    <Typography
      variant="h6"
      sx={{ fontWeight: 700, marginTop: props.marginTop }}
    >
      {props.children}
    </Typography>
  );
}

function InfoRow(props: { heading: string; content: string | number }) {
  return (
    <Box sx={{ marginTop: 2 }}>
      <Typography variant="body2">{props.heading}</Typography>
      <Typography sx={{ marginTop: 1 }}>{props.content}</Typography>
    </Box>
  );
}

export function LocationInternalInfoCard(
  props: PMBSchemas["LocationBase"] & { revalidateView: () => void },
) {
  const { data, error, mutate } = useClientSWR(
    "/locations/{locationId}/internals",
    { params: { path: { locationId: props.id } } },
  );

  const revalidateLocationInternalView = () => mutate();
  const revalidateLocationView = props.revalidateView;

  if (data) {
    return (
      <ViewCard
        title="Internal Info"
        headerAction={
          <RoleGuard allowedRoles={["PMB_ADMIN"]}>
            <DialogIconButton
              label="Edit internal info"
              renderDialog={(dialogProps) => (
                <EditLocationInternalInfoDialog
                  {...dialogProps}
                  locationId={props.id}
                  locationInternals={data}
                  onSuccess={revalidateLocationInternalView}
                />
              )}
            >
              <Edit3 />
            </DialogIconButton>
          </RoleGuard>
        }
        content={
          <>
            {data.note && (
              <Typography sx={{ marginBottom: 6 }}>
                {intersperse(data.note.trim().split("\n"), <br />)}
              </Typography>
            )}

            <InfoSubheading>Deal</InfoSubheading>

            <InfoRow
              heading="Sales Rep"
              content={handleEmpty(data.salesRep?.fullName)}
            />

            <InfoRow
              heading="Deal Value"
              content={handleEmpty(data.dealValue)}
            />

            <InfoRow
              heading="Sales Order ID"
              content={handleEmpty(data.salesOrderId)}
            />

            <InfoRow
              heading="Market segment"
              content={marketSegment.format(data.marketSegment)}
            />

            <InfoRow
              heading="Sales date"
              content={handleEmptyDate(data.salesDate)}
            />

            <InfoRow
              heading="Hilton inn code"
              content={handleEmpty(data.hiltonInnCode)}
            />

            <InfoSubheading marginTop={6}>
              Installation & Opening
            </InfoSubheading>

            <InfoRow
              heading="Project Manager"
              content={handleEmpty(data.projectManager?.fullName)}
            />

            <InfoRow
              heading="Installer"
              content={
                data.isSelfInstall
                  ? "Self-installation"
                  : handleEmpty(data.installer?.fullName)
              }
            />

            <InfoRow
              heading="Installation date"
              content={handleEmptyDate(data.installDate)}
            />

            <InfoRow
              heading="Soft opening date"
              content={handleEmptyDate(data.softOpeningDate)}
            />

            <InfoRow
              heading="Grand opening date"
              content={handleEmptyDate(data.grandOpeningDate)}
            />
          </>
        }
        actions={
          props.locationStatus !== "CLOSED" ? (
            <DialogButton
              color="info"
              variant="outlined"
              renderDialog={(dialogProps) => {
                return (
                  <ConfirmLocationDeactivationDialog
                    {...dialogProps}
                    location={props}
                    revalidateView={() => {
                      revalidateLocationView();
                      revalidateLocationInternalView();
                    }}
                  />
                );
              }}
            >
              Deactivate
            </DialogButton>
          ) : (
            <>
              <Typography variant="body2">
                This location has been deactivated.
              </Typography>
            </>
          )
        }
      />
    );
  }

  if (error) {
    return <div>Error</div>;
  }

  return null;
}
