import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useClientSWR } from "../client";
import { PMBSchemas } from "../client/types";
import { paymentFrequency } from "../constants/paymentFrequency";
import { EnumFormatter } from "../utils/enum-formatter";
import { mf } from "../utils/formatters";
import { DummyLine } from "./dummy-line";
import { TextWithIcon } from "./issue-text";
import { useUserHasRole } from "./role-guard";
import { ViewCard } from "./view-card";
import { Gift } from "react-feather";

type StatusWithoutActive = Exclude<PMBSchemas["SubscriptionStatus"], "ACTIVE">;

const content = {
  heading: "Subscription details",
  fetchError: `Failed to fetch subscription details.`,
  viewButtonLabel: `View details`,
  subscriptionStatus: new EnumFormatter<StatusWithoutActive>(
    {
      PENDING_ACTIVATION: `Subscription is pending activation.`,
      MANUAL: `This location is billed manually`,
      CLOSED: `This location has been closed.`,
    },
    `Unknown subscription status`,
  ),
  subscriptionStatusActive: mf(
    `Next {frequency} payment of {value, number, ::currency/USD} due {dueDate, date, ::dMMMyyyy}.`,
  ),
  subscriptionWarning: new EnumFormatter<PMBSchemas["SubscriptionWarningType"]>(
    {
      PAYMENT_OVERDUE: `Overdue payment`,
      MISSING_PAYMENT_METHOD: "Missing payment method",
    },
  ),
  annualPromo: `Switch to annual payments and save 10%.`,
};

function SubscriptionDetailsContent(props: {
  subscription: PMBSchemas["Subscription"];
}) {
  const isCompanyAdmin = useUserHasRole(["COMPANY_ADMIN"]);
  const { subscription } = props;

  switch (subscription.status) {
    case "ACTIVE": {
      const showWarnings =
        subscription.warnings && subscription.warnings.length > 0;

      // Promo should only be shown to company admin
      const showPromo =
        isCompanyAdmin && subscription.paymentFrequency === "MONTHLY";

      return (
        <>
          <Typography variant="body2">
            {content.subscriptionStatusActive.format({
              frequency: paymentFrequency
                .format(subscription.paymentFrequency)
                .toLowerCase(),
              value: subscription.expectedNextTotalAmount,
              dueDate: new Date(subscription.nextPaymentDate ?? 0),
            })}
          </Typography>

          {/* We don't show promo if there are warnings in order not to overload the user. */}
          {(showWarnings || showPromo) && (
            <Box sx={{ marginTop: 3 }}>
              {showWarnings ? (
                subscription.warnings?.map((warning) => {
                  return (
                    <TextWithIcon key={warning}>
                      {content.subscriptionWarning.format(warning)}
                    </TextWithIcon>
                  );
                })
              ) : (
                <TextWithIcon icon={<Gift size="1em" />} color="success.main">
                  {content.annualPromo}
                </TextWithIcon>
              )}
            </Box>
          )}
        </>
      );
    }

    case "MANUAL": {
      return (
        <Typography variant="body2">
          {content.subscriptionStatus.format(subscription.status)}
        </Typography>
      );
    }

    case "PENDING_ACTIVATION":
    default: {
      return (
        <Typography variant="body2" color="warning.main">
          {content.subscriptionStatus.format(subscription.status)}
        </Typography>
      );
    }
  }
}

export function LocationSubscriptionDetailsCard(props: {
  locationId: number;
  heading: string;
  subscription?: PMBSchemas["Subscription"];
  error?: PMBSchemas["Error"];
}) {
  const showActions = useUserHasRole([
    "SYSTEM_ADMIN",
    "PMB_ADMIN",
    "COMPANY_ADMIN",
  ]);

  return (
    <ViewCard
      title={props.heading}
      content={
        props.error ? (
          <Typography variant="body2">{content.fetchError}</Typography>
        ) : props.subscription ? (
          <SubscriptionDetailsContent subscription={props.subscription} />
        ) : (
          <>
            <DummyLine width="60%" />
            <DummyLine width="40%" />
          </>
        )
      }
      actions={
        showActions && (
          <Button
            variant="outlined"
            component={Link}
            to={`/location/${props.locationId}/subscription`}
          >
            {content.viewButtonLabel}
          </Button>
        )
      }
    />
  );
}

export function LocationSubscriptionDetailsCardFetch(props: {
  locationId: number;

  /**
   * This is useful on the Company Subscriptions page, where different
   * locations need to be differentiated by name.
   */
  heading?: string;
}) {
  const { locationId } = props;
  const { data: subscription, error: subscriptionError } = useClientSWR(
    "/locations/{locationId}/subscription",
    { params: { path: { locationId } } },
  );

  return (
    <LocationSubscriptionDetailsCard
      locationId={locationId}
      heading={props.heading ?? content.heading}
      subscription={subscription}
      error={subscriptionError}
    />
  );
}
