import { ViewHeader } from "../view-header";
import { TransactionTableFetch } from "../transaction-table";

export function TransactionListView() {
  return (
    <>
      <ViewHeader
        title="All Transactions"
        breadcrumb={[
          {
            label: "All transactions",
          },
        ]}
      />
      <TransactionTableFetch />
    </>
  );
}
