import { Box, Chip, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useClientSWR } from "../client";
import { operations } from "../client/pmb";
import { position } from "../constants/contactPositions";
import { contactStatus } from "../constants/contactStatus";
import { ChipsGroup } from "./chips-group";
import { FormDialog } from "./form-dialog";
import AutocompleteMultiSelect from "./multi-select";
import { getTextFieldProps } from "./text-field";

export type FilterValues = Pick<
  NonNullable<operations["getContacts"]["parameters"]["query"]>,
  "name_search_term" | "email_search_term" | "positions" | "status"
>;

export function ContactsFilterDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  initialValues: FilterValues;
  onSubmit: (values: FilterValues) => void;
}) {
  const formik = useFormik<FilterValues>({
    initialValues: props.initialValues,
    onSubmit: (values) => {
      props.onSubmit(values);
      props.onClose();
    },
  });
  const { data } = useClientSWR("/contacts", {
    params: { query: formik.values },
  });

  return (
    <FormDialog
      {...props}
      title="Filter contacts"
      submitLabel={
        data ? `Show ${data.totalElements} contacts` : "Show contacts"
      }
      form={formik}
    >
      <Box
        sx={{ paddingTop: 2, display: "flex", flexDirection: "column", gap: 6 }}
      >
        <Typography>
          Specify filtering criteria using the fields below.
        </Typography>

        <TextField
          {...getTextFieldProps(formik, "name_search_term")}
          label="Name contains"
          placeholder="Alex"
          InputLabelProps={{ shrink: true }}
          variant="standard"
          fullWidth
        />

        <TextField
          {...getTextFieldProps(formik, "email_search_term")}
          label="Email contains"
          placeholder="alex@example.com"
          InputLabelProps={{ shrink: true }}
          variant="standard"
          fullWidth
        />

        <AutocompleteMultiSelect
          label="Position"
          placeholder="Choose one or more"
          getOptionLabel={(enumValue) => position.format(enumValue)}
          options={position.enums}
          value={formik.values.positions ?? []}
          onChange={(values) => formik.setFieldValue("positions", values)}
        />

        <ChipsGroup label="Contact status">
          {contactStatus.enums.map((chipValue) => {
            const currentValue = formik.values.status;
            return (
              <Chip
                key={chipValue}
                label={contactStatus.format(chipValue)}
                variant="filled"
                color={currentValue === chipValue ? "primary" : "default"}
                onClick={() => formik.setFieldValue("status", chipValue)}
              />
            );
          })}
        </ChipsGroup>
      </Box>
    </FormDialog>
  );
}
