import { InteractionType } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  useMsalAuthentication,
} from "@azure/msal-react";
import { Outlet } from "react-router-dom";
import { AccountProvider } from "./auth/AccountContext";
import { ClientProvider } from "./client";
import DashboardLayout from "./layouts/Dashboard";
import OnboardingGuard from "./ui/onboarding-guard";
import { UserInfoProvider } from "./ui/user-info-context";

export function Authenticated() {
  useMsalAuthentication(InteractionType.Redirect);

  return (
    <AuthenticatedTemplate>
      <AccountProvider>
        <ClientProvider>
          <UserInfoProvider>
            <OnboardingGuard>
              <DashboardLayout>
                <Outlet />
              </DashboardLayout>
            </OnboardingGuard>
          </UserInfoProvider>
        </ClientProvider>
      </AccountProvider>
    </AuthenticatedTemplate>
  );
}
