import { Card, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactNode } from "react";

export function NameCard(props: {
  name?: string;
  subheading?: string;
  footer?: ReactNode;
  action?: ReactNode;
}) {
  return (
    <Card sx={{ position: "relative" }}>
      {props.action && (
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            padding: 2,
          }}
        >
          {props.action}
        </Box>
      )}

      <Box
        sx={{
          paddingTop: 4,
          paddingLeft: 4,
          paddingRight: 4,
          paddingBottom: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Box sx={{ padding: 4 }}>
          <img src={"/static/img/custom/insert-logo.png"} alt="" />
        </Box>

        <Typography variant="h3">{props.name ?? "Unknown"}</Typography>
        {props.subheading && (
          <Typography variant="body2" sx={{ marginTop: 2 }}>
            {props.subheading}
          </Typography>
        )}
      </Box>

      {props.footer && (
        <>
          <Divider variant="fullWidth" flexItem />
          {props.footer}
        </>
      )}
    </Card>
  );
}
