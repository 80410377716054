import { NavLinkButton } from "../nav-link-button";
import { ViewHeader } from "../view-header";
import { CompaniesTableFetch } from "../companies-table";

export function CompanyListView() {
  return (
    <>
      <ViewHeader
        title="All Companies"
        breadcrumb={[
          {
            label: "Companies",
          },
        ]}
        button={
          <NavLinkButton
            to="/company/create"
            variant="contained"
            color="primary"
          >
            Add Company
          </NavLinkButton>
        }
      />
      <CompaniesTableFetch />
    </>
  );
}
