import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useRoutes } from "react-router-dom";
import { routes } from "./routes";
import { theme } from "./theme";
import createEmotionCache from "./utils/createEmotionCache";
import GlobalStyle from "./components/GlobalStyle";
import { CssBaseline } from "@mui/material";

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | PMB"
          defaultTitle="PourMyBev - Admin Dashboard"
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <GlobalStyle />
          <CssBaseline />
          <ThemeProvider theme={theme}>{content}</ThemeProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
