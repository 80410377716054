import { FormikValues, useFormik } from "formik";

/**
 * Retrieves props that are suitable for use for fields that work with text.
 *
 * Notably, this field getter encapsulates logic regarding visibility of
 * errors and helper text, which was otherwise spread all over the codebase.
 */
export function getTextFieldProps<
  T extends FormikValues,
  Name extends string & keyof T,
>(formik: ReturnType<typeof useFormik<T>>, name: Name) {
  const wasTouched = formik.touched[name];
  const validationHint = formik.errors[name];

  return {
    name,
    value: formik.values[name],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
    error: !!(wasTouched && validationHint),
    helperText: wasTouched && validationHint,
  };
}
