import { Card, CardActions, CardContent, CardHeader } from "@mui/material";
import { ReactNode } from "react";

export function ViewCard(props: {
  title: ReactNode;
  subtitle?: ReactNode;
  titleComponent?: "h2" | "h3" | "h4" | "h5" | "h6";
  headerAction?: ReactNode;
  actions?: ReactNode;
  content?: ReactNode;
}) {
  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader
        title={props.title}
        subheader={props.subtitle}
        subheaderTypographyProps={{
          variant: "body2",
        }}
        titleTypographyProps={{
          variant: "h5",
          component: props.titleComponent ?? "h2",
        }}
        action={props.headerAction}
      />
      <CardContent sx={{ paddingTop: 0 }}>{props.content}</CardContent>

      {props.actions && (
        <CardActions
          sx={{
            marginTop: "auto",
            paddingTop: 2,
            paddingLeft: 4,
            paddingRight: 4,
            paddingBottom: 4,
          }}
        >
          {props.actions}
        </CardActions>
      )}
    </Card>
  );
}
