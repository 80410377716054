import { ReactNode } from "react";
import { Box } from "@mui/system";

function MiddleCardTemplate(props: {
  children: ReactNode;
  maxWidth?: string;
  maxHeight?: string;
  overflow?: string;
}) {
  return (
    <Box
      sx={{
        maxWidth: props.maxWidth,
        maxHeight: props.maxHeight,
        overflow: props.overflow,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifySelf: "center",
        gap: 1,
        padding: 4,
        margin: "auto",
        textAlign: "left",
        background: "transparent",
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 3,
        backgroundColor: "#FFF",
        boxShadow: "1px 1px 14px 2px rgba(0, 0, 0, 0.25)",
      }}
    >
      {props.children}
    </Box>
  );
}

export default MiddleCardTemplate;
