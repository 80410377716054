import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { FormikValues, useFormik } from "formik";
import { ReactNode } from "react";

/**
 * Retrieves props from formik state that can be used by
 * `<RadioGroupField />`.
 */
export function getRadioGroupFieldProps<
  T extends FormikValues,
  Name extends string & keyof T,
>(formik: ReturnType<typeof useFormik<T>>, name: Name) {
  return {
    name,
    value: formik.values[name],
    onChange: (value: T[Name]) => formik.setFieldValue(name, value),
    onBlur: () => formik.setFieldTouched(name),
  };
}

/**
 * Represents a radio button group.
 *
 * Should be used with RadioGroupOption nested as children. Note that
 * it is up to you to make sure that the RadioGroupOptions are using
 * types that don't violate the RadioGroupField typings.
 *
 * If you are using this field with `formik`, you can use the
 * `getRadioGroupFieldProps` helper to retrieve exactly the right props
 * from formik state.
 */
export function RadioGroupField<V>(props: {
  name: string;
  label: string;
  value: V;
  children: ReactNode;
  onChange: (value: V) => void;
  onBlur?: () => void;
}) {
  return (
    <FormControl sx={{ display: "block" }}>
      <FormLabel
        sx={{
          display: "block",
          fontWeight: "bold",
          marginBottom: 2,
        }}
      >
        {props.label}
      </FormLabel>

      <RadioGroup
        name={props.name}
        value={props.value}
        onChange={(event) => props.onChange(event.target.value as V)}
        onBlur={props.onBlur}
      >
        {props.children}
      </RadioGroup>
    </FormControl>
  );
}

/**
 * A radio option that should be used within radio group field.
 */
export function RadioGroupOption<V>(props: { value: V; label: string }) {
  return (
    <FormControlLabel
      value={props.value}
      label={props.label}
      control={<Radio />}
    />
  );
}
