import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import { ReactNode, useId } from "react";

/**
 * Renders a View Dialog.
 *
 * This component should be used when a piece of information needs to be
 * viewed by the user without needing a URL.
 *
 * If you need form submission, use FormDialog or ConfirmDialog.
 */
export function ViewDialog(props: {
  title: string;
  padding?: number;
  children: ReactNode;
  isOpen: boolean;
  maxWidth?: DialogProps["maxWidth"];
  buttonVariant?: ButtonProps["variant"];
  onClose: () => void;
}) {
  const { isOpen, onClose, title, children } = props;
  const titleId = useId();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth={props.maxWidth}
      aria-labelledby={titleId}
    >
      <DialogTitle id={titleId} variant="h3" sx={{ padding: props.padding }}>
        {title}
      </DialogTitle>

      <DialogContent sx={{ paddingX: props.padding }}>{children}</DialogContent>

      <DialogActions
        disableSpacing
        sx={{
          position: "sticky",
          bottom: 0,
          background: "linear-gradient(hsl(0 0% 100% / 0), white)",
          paddingX: props.padding,
          paddingBottom: props.padding,
          gap: 2,
        }}
      >
        <Button
          variant={props.buttonVariant ?? "text"}
          color="info"
          onClick={onClose}
          autoFocus
        >
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
}
