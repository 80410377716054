import { useNavigate } from "react-router-dom";
import { PMBSchemas } from "../client/types";
import { includesAnyOf } from "../utils/one-of";
import { useUserInfo } from "./user-info-context";
import { useEffect } from "react";

/**
 * Redirects user to their logical home page.
 *
 * For System Admin, PMB Admin, and PMB Viewer roles, the user
 * is redirected to the main dashboard page.
 *
 * For Company Admin, the user is redirected to Company Profile.
 *
 * For Location Admin and Location Viewer, the user is redirected to
 * Location Profile.
 */
export function LandingPageRedirect() {
  const navigate = useNavigate();
  const { roles, principal } = useUserInfo();
  const { locationId, companyId } = principal ?? {};

  useEffect(() => {
    if (
      includesAnyOf<PMBSchemas["Role"]>(roles, [
        "SYSTEM_ADMIN",
        "PMB_ADMIN",
        "PMB_VIEWER",
      ])
    ) {
      // Redirect to dashboard
      return navigate("/dashboard", { replace: true });
    }

    if (
      includesAnyOf<PMBSchemas["Role"]>(roles, ["COMPANY_ADMIN"]) &&
      companyId
    ) {
      // Redirect to company profile
      return navigate(`/company/${companyId}/profile`, { replace: true });
    }

    if (
      includesAnyOf<PMBSchemas["Role"]>(roles, [
        "LOCATION_ADMIN",
        "LOCATION_VIEWER",
      ]) &&
      locationId
    ) {
      // Redirect to location detail
      return navigate(`/location/${locationId}/profile`, { replace: true });
    }
  }, [navigate, companyId, locationId, roles]);

  return <div>Redirecting...</div>;
}
