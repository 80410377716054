import { defaultQuerySerializer } from "openapi-fetch";
import { operations } from "../client/pmb";
import { ConfirmDialog } from "./confirm-dialog";
import { useDownloadClient, withTimestamp } from "./use-download-client";

export function ExportTransactionsDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  query: operations["getPaymentsList"]["parameters"]["query"];
}) {
  const download = useDownloadClient();

  return (
    <ConfirmDialog
      {...props}
      title="Export filtered transactions?"
      description="The export file will contain only those records that are currently filtered on the screen."
      submitLabel="Download"
      onSubmit={async () => {
        await download(`/payments?${defaultQuerySerializer(props.query)}`, {
          filename: withTimestamp("pmb_transactions_export.csv"),
          mimeType: "text/csv",
        });
      }}
    />
  );
}
