import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { publicConfig } from "./config";

if (publicConfig.applicationInsightsConnectionString) {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: publicConfig.applicationInsightsConnectionString,
      enableDebug: true,
      enableAutoRouteTracking: true,
    },
  });

  // Enable app insights
  appInsights.loadAppInsights();

  // Track the page that was just loaded
  appInsights.trackPageView();
}
