import { Grid as MuiGrid, styled } from "@mui/material";
import { ReactNode } from "react";

const Grid = styled(MuiGrid)`
  padding: 16px 36px 36px 36px;
  gap: 40px;

  @media (max-width: 600px) {
    gap: 16px;
    padding: 16px;
  }
`;

interface ParentProps {
  children: ReactNode;
}

const PmbFormGrid = (props: ParentProps) => {
  return <Grid container>{props.children}</Grid>;
};

export default PmbFormGrid;
