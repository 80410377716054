import { unpackResponse, useClient } from "../client";
import { PMBContact } from "../client/types";
import { position } from "../constants/contactPositions";
import { ConfirmDialog } from "./confirm-dialog";

type ContactDialogProps = {
  isOpen: boolean;
  contact: PMBContact;
  onClose: () => void;
  onSuccess: () => void;
};

export function InviteContactDialog(props: ContactDialogProps) {
  const client = useClient();
  const { contact, onSuccess, isOpen, onClose } = props;
  const positionName = position.format(contact.position);

  return (
    <ConfirmDialog
      title={`Invite ${contact.fullName} to PourMyBev?`}
      description={`${contact.fullName} (${contact.email}) will be invited to join PourMyBev as ${positionName}.`}
      isOpen={isOpen}
      submitLabel="Invite"
      onClose={onClose}
      onSubmit={async () => {
        await unpackResponse(
          client.PATCH("/contacts/{contactId}/invite", {
            params: { path: { contactId: contact.id } },
            body: { isResend: false },
          }),
        );

        onSuccess();
      }}
    />
  );
}

export function ResendInviteDialog(props: ContactDialogProps) {
  const client = useClient();
  const { contact, onSuccess, isOpen, onClose } = props;

  return (
    <ConfirmDialog
      title={`Resend invite to ${contact.fullName}?`}
      description={`${contact.fullName} (${contact.email}) will be sent a new invite to join PourMyBev.`}
      isOpen={isOpen}
      submitLabel="Resend"
      onClose={onClose}
      onSubmit={async () => {
        await unpackResponse(
          client.PATCH("/contacts/{contactId}/invite", {
            params: { path: { contactId: contact.id } },
            body: {
              isResend: true,
              inviteLinkExpirationDate: contact.inviteLinkExpirationDate,
            },
          }),
        );

        onSuccess();
      }}
    />
  );
}

export function BlockContactDialog(props: ContactDialogProps) {
  const client = useClient();
  const { contact, onSuccess, isOpen, onClose } = props;

  return (
    <ConfirmDialog
      title={`Block ${contact.fullName}?`}
      description={`${contact.fullName} will no longer be able to access PourMyBev. They can be unblocked at any time to restore access.`}
      isOpen={isOpen}
      submitLabel="Block"
      submitColor="error"
      onClose={onClose}
      onSubmit={async () => {
        await unpackResponse(
          client.PUT("/contacts/{contactId}/block", {
            params: { path: { contactId: contact.id } },
          }),
        );

        onSuccess();
      }}
    />
  );
}

export function UnblockContactDialog(props: ContactDialogProps) {
  const client = useClient();
  const { contact, onSuccess, isOpen, onClose } = props;
  const positionName = position.format(contact.position);

  return (
    <ConfirmDialog
      title={`Unblock ${contact.fullName}?`}
      description={`${contact.fullName} will regain their access to PourMyBev as ${positionName}.`}
      isOpen={isOpen}
      submitLabel="Unblock"
      onClose={onClose}
      onSubmit={async () => {
        await unpackResponse(
          client.DELETE("/contacts/{contactId}/block", {
            params: { path: { contactId: contact.id } },
          }),
        );

        onSuccess();
      }}
    />
  );
}
