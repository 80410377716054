import { Box } from "@mui/material";
import { useFormik } from "formik";
import { ReactNode, useMemo } from "react";
import { number, object, string } from "yup";
import { PMBClient, unpackResponse, useClient, useClientSWR } from "../client";
import { PMBSchemas } from "../client/types";
import { accountsManagement } from "../constants/accountsManagement";
import { caughtValueToString } from "../utils/caught-error";
import { formatCurrency } from "../utils/formatters";
import {
  DateField,
  getDateFieldProps,
  isInvalidDateMarker,
} from "./date-field";
import { FormDialog } from "./form-dialog";
import { NumberField, getNumberFieldProps } from "./number-field";
import {
  RadioGroupField,
  RadioGroupOption,
  getRadioGroupFieldProps,
} from "./radio-group-field";

type FormValues = {
  status: PMBSchemas["SubscriptionStatus"];
  discountType: PMBSchemas["SubscriptionDiscountType"];
  accountsManagement: PMBSchemas["AccountsManagement"];
  paymentFrequency: PMBSchemas["PaymentFrequency"];
  discountValidTo: string;
  lumpsumDiscount: number;
  percentageDiscount: number;
  addons: PMBSchemas["SubscriptionAddon"][];
  selectedQrTextServiceAddonId: number | "none";
};

export function findExistingQrTextServiceAddon(
  addons?: PMBSchemas["SubscriptionAddon"][],
) {
  return addons?.find(
    (connection) => connection.addon?.type === "QR_TEXT_SERVICE",
  )?.addon;
}

function excludeQrTextServiceAddonConnections(
  addons?: PMBSchemas["SubscriptionAddon"][],
) {
  return addons?.filter((c) => !(c.addon?.type === "QR_TEXT_SERVICE"));
}

async function updateSubscriptionWithAddons(
  client: PMBClient,
  locationId: number,
  { selectedQrTextServiceAddonId, ...values }: FormValues,
): Promise<PMBSchemas["Subscription"]> {
  const existingQrTextServiceAddon = findExistingQrTextServiceAddon(
    values.addons,
  );

  if (selectedQrTextServiceAddonId === "none") {
    // If no QR text service addon is selected and none currently exists in the
    // location subscription addons, we can just return the full addons field
    // as is — we are not making any changes.
    if (!existingQrTextServiceAddon) {
      return await unpackResponse(
        client.PUT("/locations/{locationId}/subscription", {
          params: { path: { locationId } },
          body: values,
        }),
      );
    }

    // Otherwise, we have to remove all QR text service connections that point
    // to a QR text service addon.
    return await unpackResponse(
      client.PUT("/locations/{locationId}/subscription", {
        params: { path: { locationId } },
        body: {
          ...values,
          addons: excludeQrTextServiceAddonConnections(values.addons),
        },
      }),
    );
  }

  if (existingQrTextServiceAddon?.id === selectedQrTextServiceAddonId) {
    // If the selected addon id matches the existing one, we just send the addons array
    // as is, since no changes were made.
    return await unpackResponse(
      client.PUT("/locations/{locationId}/subscription", {
        params: { path: { locationId } },
        body: values,
      }),
    );
  }

  // Otherwise, we need to update the locations addons array, which is done in two steps.
  // First, we remove all existing QR text service addon connections, to make sure there
  // are no duplicates. Only a single QR text service addon should be present at a time.
  await unpackResponse(
    client.PUT("/locations/{locationId}/subscription", {
      params: { path: { locationId } },
      body: {
        ...values,
        addons: excludeQrTextServiceAddonConnections(values.addons),
      },
    }),
  );

  // Now, we add the new QR text service addon.
  return await unpackResponse(
    client.POST("/locations/{locationId}/subscription/addons/{addonId}", {
      params: { path: { addonId: selectedQrTextServiceAddonId, locationId } },
    }),
  );
}

function InputsRow(props: { children: ReactNode }) {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 260px)",
        gap: 4,
        marginTop: 2,
        marginBottom: 4,
      }}
    >
      {props.children}
    </Box>
  );
}

function QrTextServiceRadioGroup(props: {
  name: string;
  value: FormValues["selectedQrTextServiceAddonId"];
  onChange: (value: FormValues["selectedQrTextServiceAddonId"]) => void;
  onBlur?: () => void;
}) {
  const { data: addons, error } = useClientSWR("/addons");

  if (addons) {
    const qrTextServiceAddons = addons.filter(
      (a) => a.type === "QR_TEXT_SERVICE",
    );

    return (
      <RadioGroupField {...props} label="Select QR text message service tier:">
        <RadioGroupOption value="none" label="None" />

        {qrTextServiceAddons.map((addon) => {
          return (
            <RadioGroupOption
              key={addon.id}
              value={addon.id}
              label={`${addon.tierOptionLabel}: ${formatCurrency("USD", addon.monthlyPrice)}`}
            />
          );
        })}
      </RadioGroupField>
    );
  }

  if (error) {
    return <div>Could not load addons. {error.message}</div>;
  }

  return <div>Loading QR text service addons...</div>;
}

export function LocationPricingSetupDialog(props: {
  onClose: () => void;
  isOpen: boolean;
  location: PMBSchemas["LocationBase"];
  subscription: PMBSchemas["Subscription"];
  revalidateView: () => void;
}) {
  const { subscription, location } = props;
  const todayDate = useMemo(() => {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }, []);
  const client = useClient();

  const formik = useFormik<Required<FormValues>>({
    initialValues: {
      status: subscription.status,
      discountType: subscription.discountType,
      accountsManagement: subscription.accountsManagement ?? "CAMPUS",
      paymentFrequency: subscription.paymentFrequency,
      discountValidTo: subscription.discountValidTo ?? "",
      lumpsumDiscount: subscription.lumpsumDiscount ?? 0,
      percentageDiscount: subscription.percentageDiscount ?? 0,
      addons: subscription.addons ?? [],
      selectedQrTextServiceAddonId:
        subscription.addons?.find(
          (connection) => connection.addon?.type === "QR_TEXT_SERVICE",
        )?.addon?.id ?? "none",
    },
    validationSchema: object().shape({
      status: string().required(),
      discountType: string().required(),
      accountsManagement: string().required(),
      paymentFrequency: string().required(),
      discountValidTo: string()
        .test(
          "validISODate",
          "Must be a valid date or empty.",
          (value) => !value || !isInvalidDateMarker(value),
        )
        .test(
          "futureDate",
          "Must be a future date or empty.",
          (value) => !value || new Date(value) >= todayDate,
        ),
      lumpsumDiscount: number().when("discountType", {
        is: "LUMPSUM",
        then: (schema) =>
          schema.moreThan(0, "Must be greater than 0.").required("Required"),
      }),
      percentageDiscount: number().when("discountType", {
        is: "PERCENTAGE",
        then: (schema) =>
          schema.moreThan(0, "Must be greater than 0.").required("Required"),
      }),
    }),
    async onSubmit(values) {
      try {
        await updateSubscriptionWithAddons(client, location.id, values);

        props.revalidateView();
        props.onClose();
      } catch (error) {
        formik.setStatus(caughtValueToString(error));
      }
    },
  });

  // The automated radio value needs to be set based on the current state
  // of the subscription. A user can transition the subscription state from
  // ACTIVE to MANUAL or from MANUAL to PENDING_ACTIVATION, but never directly
  // into ACTIVE. The below encapsulates this logic.
  const automatedValue =
    subscription.status === "ACTIVE" ? "ACTIVE" : "PENDING_ACTIVATION";

  return (
    <FormDialog
      {...props}
      padding={10}
      maxWidth="md"
      title="Location Pricing Setup"
      submitLabel="Save"
      buttonVariant="contained"
      form={formik}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <RadioGroupField
          {...getRadioGroupFieldProps(formik, "status")}
          label="Select billing type"
        >
          <RadioGroupOption<PMBSchemas["SubscriptionStatus"]>
            value={automatedValue}
            label="Automated - deducted from a card or an account"
          />
          <RadioGroupOption<PMBSchemas["SubscriptionStatus"]>
            value="MANUAL"
            label="⚠️ Manual - the customer will not be billed automatically and invoicing process will be done manually"
          />
        </RadioGroupField>

        <RadioGroupField
          {...getRadioGroupFieldProps(formik, "discountType")}
          label="Select discount type"
        >
          <RadioGroupOption<PMBSchemas["SubscriptionDiscountType"]>
            value="NONE"
            label="No discount"
          />
          <RadioGroupOption<PMBSchemas["SubscriptionDiscountType"]>
            value="LUMPSUM"
            label="Lumpsum - a fixed dollar amount will be deducted from the calculated base."
          />
          {formik.values.discountType === "LUMPSUM" && (
            <InputsRow>
              <NumberField
                {...getNumberFieldProps(formik, "lumpsumDiscount")}
                label="Amount"
                adornment="$"
              />
              <DateField
                {...getDateFieldProps(formik, "discountValidTo")}
                label="Valid until"
                minDate={todayDate}
              />
            </InputsRow>
          )}
          <RadioGroupOption<PMBSchemas["SubscriptionDiscountType"]>
            value="PERCENTAGE"
            label="Percentage - a percentage amount will be deducted from the calculated base."
          />
          {formik.values.discountType === "PERCENTAGE" && (
            <InputsRow>
              <NumberField
                {...getNumberFieldProps(formik, "percentageDiscount")}
                label="Amount"
                adornment="%"
              />
              <DateField
                {...getDateFieldProps(formik, "discountValidTo")}
                label="Valid until"
                minDate={todayDate}
              />
            </InputsRow>
          )}
        </RadioGroupField>

        <RadioGroupField
          {...getRadioGroupFieldProps(formik, "accountsManagement")}
          label="Select Transaction handling active at this location:"
        >
          {accountsManagement.enums.map((value) => {
            return (
              <RadioGroupOption<PMBSchemas["AccountsManagement"]>
                key={value}
                value={value}
                label={accountsManagement.format(value)}
              />
            );
          })}
        </RadioGroupField>

        <QrTextServiceRadioGroup
          {...getRadioGroupFieldProps(formik, "selectedQrTextServiceAddonId")}
        />
      </Box>
    </FormDialog>
  );
}
