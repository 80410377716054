import { useCallback } from "react";
import { unpackResponse, useClient } from "../client";
import useSWR from "swr";

export function useCompanySubscriptions(companyId: number) {
  const client = useClient();
  const fetcher = useCallback(async () => {
    const { content: locations } = await unpackResponse(
      client.GET("/companies/{companyId}/locations", {
        params: { path: { companyId }, query: { page_size: 1000 } },
      }),
    );

    const subscriptions = await Promise.all(
      locations.map(async (location) => {
        const sub = await unpackResponse(
          client.GET("/locations/{locationId}/subscription", {
            params: { path: { locationId: location.id } },
          }),
        );

        return sub;
      }),
    );

    return subscriptions;
  }, [client, companyId]);
  return useSWR(`CUSTOM:/companies/${companyId}/subscriptions`, fetcher);
}
