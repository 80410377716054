import { unpackResponse, useClient } from "../client";
import { PMBSchemas } from "../client/types";
import { waitForStripeWebhooks } from "../utils/prototyping";
import { ConfirmDialog } from "./confirm-dialog";
import { getPaymentMethodName } from "./views/stripe-brand-formatter";

export function RetryPaymentDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  locationId: number;
  paymentId: number;
  paymentMethod: PMBSchemas["PaymentMethod"];
}) {
  const client = useClient();
  const { onSuccess, isOpen, onClose } = props;
  const paymentMethodName = getPaymentMethodName(
    props.paymentMethod.stripePaymentMethod,
  );

  return (
    <ConfirmDialog
      title="Retry payment?"
      description={`Your default payment method (${paymentMethodName}) will be used to retry the selected payment.`}
      isOpen={isOpen}
      submitLabel="Retry payment"
      onClose={onClose}
      onSubmit={async () => {
        await Promise.all([
          waitForStripeWebhooks(),
          unpackResponse(
            client.PATCH(
              "/locations/{locationId}/payments/{paymentId}/settle",
              {
                params: {
                  path: {
                    locationId: props.locationId,
                    paymentId: props.paymentId,
                  },
                },
              },
            ),
          ),
        ]);

        onSuccess();
      }}
    />
  );
}
