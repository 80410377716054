import { Autocomplete, Grid, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import {
  countryRequiresState,
  countryFormatter,
} from "../../../constants/countries";
import {
  getStatesOrProvincesByCountry,
  stateFormatter,
} from "../../../constants/statesAndProvinces";
import PmbFormGrid from "../../components/pmb/form/PmbFormGrid";
import { CompanyFormType } from "../CompanyForm";

const CompanyAddressFormik = () => {
  const { values, errors, touched, handleBlur, handleChange, setFieldValue } =
    useFormikContext<CompanyFormType>();

  // When user select any other Country then [US, Canada] state is null
  useEffect(() => {
    if (!countryRequiresState(values.address.country)) {
      setFieldValue("address.state", "N_A");
    }
  }, [setFieldValue, values.address.country]);

  return (
    <PmbFormGrid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="name"
          name="name"
          label="Company Name"
          value={values.name}
          error={Boolean(touched.name && errors.name)}
          helperText={touched.name && errors.name}
          onBlur={handleBlur}
          onChange={(event) => setFieldValue("name", event.target.value)}
          variant="standard"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="address.addressLineOne"
          label="Address Line 1"
          value={values.address.addressLineOne}
          error={Boolean(
            touched.address?.addressLineOne && errors.address?.addressLineOne,
          )}
          fullWidth
          helperText={
            touched.address?.addressLineOne && errors.address?.addressLineOne
          }
          onBlur={handleBlur}
          onChange={handleChange}
          variant="standard"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="address.addressLineTwo"
          label="Address Line 2"
          value={values.address.addressLineTwo}
          error={Boolean(
            touched.address?.addressLineTwo && errors.address?.addressLineTwo,
          )}
          fullWidth
          helperText={
            touched.address?.addressLineTwo && errors.address?.addressLineTwo
          }
          onBlur={handleBlur}
          onChange={handleChange}
          variant="standard"
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={{ lg: 16, xs: 4 }}>
          <Grid item lg={10} xs={12}>
            <TextField
              name="address.city"
              label="City"
              value={values.address.city}
              error={Boolean(touched.address?.city && errors.address?.city)}
              fullWidth
              helperText={touched.address?.city && errors.address?.city}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="standard"
            />
          </Grid>
          <Grid item lg={2} xs={12}>
            <TextField
              name="address.zipCode"
              label="ZIP"
              value={values.address.zipCode}
              error={Boolean(
                touched.address?.zipCode && errors.address?.zipCode,
              )}
              fullWidth
              helperText={touched.address?.zipCode && errors.address?.zipCode}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="standard"
            />
          </Grid>
        </Grid>
      </Grid>
      {countryRequiresState(values.address.country) && (
        <Grid item xs={12}>
          <Autocomplete
            value={values.address.state}
            options={getStatesOrProvincesByCountry(values.address.country)}
            getOptionLabel={(option) => stateFormatter.format(option)}
            onChange={(e, value) => {
              setFieldValue("address.state", value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="state"
                label="State"
                error={Boolean(touched.address?.state && errors.address?.state)}
                helperText={touched.address?.state && errors.address?.state}
                onBlur={handleBlur}
                variant="standard"
                fullWidth
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Autocomplete
          value={values.address.country}
          options={countryFormatter.enums}
          getOptionLabel={(option) => countryFormatter.format(option)}
          onChange={(e, value) => {
            setFieldValue("address.country", value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name="country"
              label="Country"
              error={Boolean(
                touched.address?.country && errors.address?.country,
              )}
              helperText={touched.address?.country && errors.address?.country}
              onBlur={handleBlur}
              variant="standard"
              fullWidth
            />
          )}
        />
      </Grid>
    </PmbFormGrid>
  );
};

export default CompanyAddressFormik;
