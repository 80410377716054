import { TableCell, TableRow, Typography } from "@mui/material";
import { useClientSWR } from "../client";
import { operations } from "../client/pmb";
import { PMBSchemas } from "../client/types";
import { locationStatus } from "../constants/locationStatus";
import { DummyRows } from "./dummy-rows";
import { LocationFetchErrorCard } from "./info-card";
import { NavLinkButton } from "./nav-link-button";
import { TableColumnHeadCell, TableRowHeadCell } from "./table-elements";
import { Pageable, usePageable } from "./use-pageable";
import { ViewTable } from "./view-table";
import { ViewTablePagination } from "./view-table-pagination";

/**
 * LocationsTable is a stateless component that renders a list of locations
 * if provided, otherwise an indeterminate state (dummy rows).
 */
function LocationsTable(props: {
  pageable: Pageable;
  locations?: {
    totalElements?: number;
    content?: PMBSchemas["LocationList"][];
  };
}) {
  return (
    <ViewTable
      head={
        <TableRow>
          <TableColumnHeadCell width="15%">Location Name</TableColumnHeadCell>
          <TableColumnHeadCell width="15%">Company Name</TableColumnHeadCell>
          <TableColumnHeadCell width="15%">Customer Number</TableColumnHeadCell>
          <TableColumnHeadCell width="15%">Location Status</TableColumnHeadCell>
          <TableColumnHeadCell width="10%">Sales Rep</TableColumnHeadCell>
          <TableColumnHeadCell width="15%">Project Manager</TableColumnHeadCell>
          <TableColumnHeadCell width="10%">Deal Value</TableColumnHeadCell>
          <TableColumnHeadCell />
        </TableRow>
      }
      body={
        !props.locations ? (
          <DummyRows
            columns={8}
            rows={props.pageable.pageSize}
            rowHeight={73}
          />
        ) : (
          props.locations.content?.map((location) => {
            return (
              <TableRow key={location.id} hover>
                <TableRowHeadCell>
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    component="span"
                  >
                    {location.name}
                  </Typography>
                </TableRowHeadCell>
                <TableCell>{location.companyName}</TableCell>
                <TableCell>{location.customerNumber}</TableCell>
                <TableCell>
                  {locationStatus.format(location.locationStatus)}
                </TableCell>
                <TableCell>{location.salesRepFullName}</TableCell>
                <TableCell>{location.projectManagerFullName}</TableCell>
                <TableCell>{location.dealValue}</TableCell>
                <TableCell>
                  <NavLinkButton
                    variant="outlined"
                    to={`/location/${location.id}/profile`}
                  >
                    View
                  </NavLinkButton>
                </TableCell>
              </TableRow>
            );
          })
        )
      }
      pagination={
        <ViewTablePagination
          {...props.pageable.paginationProps}
          totalElements={props.locations?.totalElements}
        />
      }
    />
  );
}

/**
 * LocationsTableFetch is a stateful component retrieves locations from the
 * server, handles errors, and renders a table of locations once ready.
 */
export function LocationsTableFetch(props: {
  query?: operations["getLocations"]["parameters"]["query"];
}) {
  const pageable = usePageable({ initialSortProp: "name" });
  const { data, error } = useClientSWR("/locations", {
    params: { query: { ...pageable.query, ...props.query } },
  });

  if (error) {
    return <LocationFetchErrorCard error={error} />;
  }

  return <LocationsTable locations={data} pageable={pageable} />;
}
