import { PMBSchemas } from "../client/types";
import { countryRequiresState } from "../constants/countries";

/**
 * Before sending an address to the server, we need to make sure that state is
 * only included when the country actually needs a state.
 */
export function serializeAddress(address: PMBSchemas["Address"]) {
  if (countryRequiresState(address.country)) {
    return address;
  }

  // Omit
  return {
    ...address,
    state: "N_A" as const,
  };
}
