import { Button, ButtonProps, IconButton, Tooltip } from "@mui/material";
import { ReactElement } from "react";
import { useDialogState } from "./use-dialog-state";

export type RenderDialogFn = (dialogProps: {
  isOpen: boolean;
  onClose: () => void;
}) => ReactElement;

/**
 * Renders a Button that is bound to automatically open a dialog on click.
 *
 * This component takes all the usual MUI Button props with the exception
 * of the `onClick` handlers which is always set to open the dialog.
 */
export function DialogButton(
  props: Omit<ButtonProps, "onClick"> & {
    renderDialog: RenderDialogFn;
  },
) {
  const { renderDialog, ...buttonProps } = props;
  const { openDialog, ...dialogState } = useDialogState();

  return (
    <>
      {dialogState.isOpen && props.renderDialog(dialogState)}
      <Button {...buttonProps} onClick={openDialog}>
        {props.children}
      </Button>
    </>
  );
}

/**
 * Renders an IconButton that is bound to automatically open a dialog on click.
 *
 * This component takes all the usual MUI Icon Button props with the exception
 * of the `onClick` handlers which is always set to open the dialog.
 */
export function DialogIconButton(
  props: Omit<ButtonProps, "onClick" | "aria-label"> & {
    label: string;
    renderDialog: RenderDialogFn;
  },
) {
  const { renderDialog, ...buttonProps } = props;
  const { openDialog, ...dialogState } = useDialogState();

  return (
    <>
      {dialogState.isOpen && props.renderDialog(dialogState)}

      <Tooltip title={props.label}>
        <IconButton
          {...buttonProps}
          aria-label={props.label}
          onClick={openDialog}
        >
          {props.children}
        </IconButton>
      </Tooltip>
    </>
  );
}
