import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { FormEvent, ReactNode, useId, useState } from "react";
import { caughtValueToString } from "../utils/caught-error";

/**
 * Renders a Confirm Dialog.
 *
 * This component should be used when an action (typically an async request to
 * the server) needs to be confirmed by the user.
 *
 * After submission completes, the dialog will be automatically dismissed.
 */
export function ConfirmDialog(props: {
  title: string;
  description: string | ReactNode;
  isOpen: boolean;

  /**
   * The label of the primary call to action button. Should be a _directive_,
   * for example "Send", "Delete", "Create", etc...
   *
   * If possible try to avoid the word "Submit" as it is needlessly vague and
   * technical. A more concrete term is always better!
   */
  submitLabel: string;

  /**
   * Note that this is only a subset of the available colors. Notably, the
   * `"info"` option is missing to make sure that there is always a difference
   * between the main call to action and the dismiss button.
   *
   * @default "primary"
   */
  submitColor?: "primary" | "secondary" | "success" | "error" | "warning";
  confirmationString?: string;

  /**
   * Submission function to be called when the primary call to action button
   * is clicked. Note that `onClose` will be called automatically after
   * this function returns.
   */
  onSubmit: () => Promise<void> | void;

  onClose: () => void;
}) {
  const {
    isOpen,
    onClose,
    onSubmit,
    title,
    description,
    submitLabel,
    submitColor = "primary",
  } = props;
  const [submitState, setSubmitState] = useState<
    | {
        type: "INITIAL" | "SUBMITTING" | "SUCCESS";
      }
    | {
        type: "ERROR";
        message: string;
      }
  >({ type: "INITIAL" });
  const isSubmitting = submitState.type === "SUBMITTING";
  const [confirmation, setConfirmation] = useState("");

  const titleId = useId();
  const descId = useId();

  // If confirmation string is supplied, make sure that the user has supplied
  // a matching string. Otherwise allow submission.
  const allowSubmission = props.confirmationString
    ? props.confirmationString.toLowerCase() === confirmation.toLowerCase()
    : true;

  const closeUnlessSubmitting = () => {
    if (!isSubmitting) {
      onClose();
    }
  };

  const submitThenClose = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setSubmitState({ type: "SUBMITTING" });
    try {
      await onSubmit();
      onClose();
      setSubmitState({ type: "SUCCESS" });
    } catch (error) {
      console.log(error);
      setSubmitState({
        type: "ERROR",
        message: caughtValueToString(error),
      });
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeUnlessSubmitting}
      fullWidth
      aria-labelledby={titleId}
      aria-describedby={descId}
    >
      <form onSubmit={submitThenClose}>
        <DialogTitle id={titleId} fontWeight="bold">
          {title}
        </DialogTitle>
        <DialogContent>
          {typeof description === "string" ? (
            <DialogContentText id={descId}>{description}</DialogContentText>
          ) : (
            description
          )}
          {props.confirmationString && (
            <TextField
              sx={{ marginTop: 4 }}
              label="Confirmation"
              variant="standard"
              value={confirmation}
              onChange={(event) => setConfirmation(event.target.value)}
              fullWidth
            />
          )}
          {submitState.type === "ERROR" && (
            <Alert severity="error" sx={{ marginTop: 4 }}>
              {submitState.message}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          {isSubmitting && (
            <CircularProgress size="1em" sx={{ marginRight: 2 }} />
          )}
          <Button
            variant="text"
            color="info"
            onClick={closeUnlessSubmitting}
            disabled={isSubmitting}
            autoFocus
          >
            Dismiss
          </Button>
          <Button
            type="submit"
            variant="text"
            color={submitColor}
            disabled={isSubmitting || !allowSubmission}
          >
            {submitLabel}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
