import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
} from "@mui/material";

const PmbCircularProgress = (
  props: CircularProgressProps & { value: number; text: string },
) => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <Box
        sx={{
          top: 0,
          left: 0,
          position: "absolute",
          width: "96px",
          height: "96px",
          border: "16px solid #def46e",
          borderRadius: "50%",
        }}
      />
      <CircularProgress
        {...props}
        variant="determinate"
        size="96px"
        thickness={7.3}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="body1" component="div" color="text.primary">
          {props.text}
        </Typography>
      </Box>
    </Box>
  );
};

export default PmbCircularProgress;
