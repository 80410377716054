import { Box, Chip, Divider, Paper, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import { Box as BoxIcon, Cpu, CreditCard, Monitor } from "react-feather";
import { PMBSchemas } from "../../client/types";
import { deviceStatusV2 } from "../../constants/locationConfiguration/deviceStatus";
import { MoreMenu, MoreMenuDialogItem } from "../../ui/more-menu";
import { NavLinkButton } from "../../ui/nav-link-button";
import { RoleGuard } from "../../ui/role-guard";
import { EnumFormatter } from "../../utils/enum-formatter";
import { mf } from "../../utils/formatters";
import { UpdateChargerDeviceDialog } from "./charger-form-dialog";
import { DeleteDeviceDialog } from "./delete-device-dialog";
import { DeleteDeviceGroupDialog } from "./delete-device-group-dialog";
import { UpdateDeviceGroupDialog } from "./device-group-form-dialog";
import { UpdateGatewayDeviceDialog } from "./gateway-device-form-dialog";
import { UpdateScreenDeviceDialog } from "./screen-device-form-dialog";

function DeviceCard(props: {
  icon: ReactNode;
  label: string;
  code?: string;
  actions: ReactNode;
  info: ReactNode;
  chip: string;
}) {
  return (
    <Paper
      sx={{
        padding: 4,
        display: "grid",
        gridTemplateColumns: "3.5rem minmax(0, 1fr)",
        gap: 4,
      }}
    >
      <Box
        sx={{
          alignSelf: "stretch",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "background.default",
          borderRadius: 2,
        }}
      >
        {props.icon}
      </Box>

      <div>
        <Stack direction="row" gap={2} alignItems="start">
          <Typography variant="h5">
            {props.label}
            {props.code && (
              <>
                {" "}
                <Box component="span" sx={{ opacity: 0.4, fontWeight: 400 }}>
                  ({props.code})
                </Box>
              </>
            )}
          </Typography>
          <Stack
            direction="row"
            gap={2}
            sx={{ marginLeft: "auto", marginY: -2, marginRight: -2 }}
          >
            {props.actions}
          </Stack>
        </Stack>
        <Stack
          direction="row"
          gap={3}
          alignItems="center"
          sx={{ marginTop: 2 }}
        >
          <Chip label={props.chip} variant="outlined" size="small" />
          {props.info}
        </Stack>
      </div>
    </Paper>
  );
}

const deviceGroupStatus = mf(
  "{screensCount, plural, =1 {# screen} other {# screens}}, {tapsCount, plural, =1 {# tap} other {# taps}}",
);

const deviceGroupType = new EnumFormatter<PMBSchemas["DeviceGroupType"]>({
  TAP_TRAILER: "Tap Trailer",
  SELF_POUR_STATION: "Self-pour Station",
});

function getGroupStats(devices: PMBSchemas["Device"][] = []) {
  const screens = devices.filter((d) => d.deviceCategory === "SCREEN");
  const tapsCount = screens.reduce((a, d) => a + (d.tapCount ?? 0), 0);

  return {
    screensCount: screens.length,
    tapsCount,
  };
}

/**
 * Checks whether a common status can be found among the devices provided.
 * If one is found, it is formatted and returned. Otherwise the string
 * 'Mixed Statuses' is returned.
 */
function getCommonGroupStatus(devices: PMBSchemas["Device"][] = []) {
  let firstStatus: PMBSchemas["Device"]["status"] | undefined;

  for (const device of devices) {
    // Assign status if it hasn't been set yet.
    firstStatus ??= device.status;

    if (firstStatus !== device.status) {
      return "Mixed Statuses";
    }
  }

  return deviceStatusV2.format(firstStatus);
}

export function DeviceGroupCard(
  props: PMBSchemas["DeviceGroup"] & {
    locationId: number;
    revalidateView: () => void;
  },
) {
  const stats = getGroupStats(props.devices);
  const commonStatus = getCommonGroupStatus(props.devices);

  return (
    <DeviceCard
      icon={<BoxIcon />}
      label={props.label || deviceGroupType.format(props.type)}
      actions={
        <>
          <NavLinkButton
            variant="outlined"
            to={`/location/${props.locationId}/topology/device-groups/${props.id}`}
          >
            View
          </NavLinkButton>

          <RoleGuard allowedRoles={["PMB_ADMIN"]}>
            <MoreMenu>
              <MoreMenuDialogItem
                renderDialog={(dialogState) => {
                  return (
                    <UpdateDeviceGroupDialog
                      {...dialogState}
                      deviceGroup={props}
                      revalidateView={props.revalidateView}
                    />
                  );
                }}
              >
                Update
              </MoreMenuDialogItem>

              <Divider />

              <MoreMenuDialogItem
                renderDialog={(dialogState) => {
                  return (
                    <DeleteDeviceGroupDialog
                      {...dialogState}
                      deviceGroupId={props.id}
                      revalidateView={props.revalidateView}
                    />
                  );
                }}
              >
                Delete
              </MoreMenuDialogItem>
            </MoreMenu>
          </RoleGuard>
        </>
      }
      chip={commonStatus}
      info={
        <>
          <Typography variant="body2" sx={{ color: "black" }}>
            {deviceGroupType.format(props.type)}
          </Typography>

          <Typography variant="body2">
            {deviceGroupStatus.format(stats)}
          </Typography>
        </>
      }
    />
  );
}

export function GatewayDeviceCard(props: {
  device: PMBSchemas["Device"];
  locationId: number;
  availableHardwareModels: PMBSchemas["DeviceModel"][];
  revalidateView: () => void;
}) {
  const { device } = props;

  return (
    <DeviceCard
      icon={<Cpu />}
      label={props.device.label!}
      code={props.device.iotHubName!}
      chip={deviceStatusV2.format(device.status)}
      info={
        <Typography variant="body2">
          Hardware:{" "}
          <span style={{ color: "black" }}>{device.hwPlatform.modelName}</span>
        </Typography>
      }
      actions={
        <RoleGuard allowedRoles={["PMB_ADMIN"]}>
          <MoreMenu>
            <MoreMenuDialogItem
              renderDialog={(dialogProps) => {
                return (
                  <UpdateGatewayDeviceDialog {...dialogProps} {...props} />
                );
              }}
            >
              Update
            </MoreMenuDialogItem>
          </MoreMenu>
        </RoleGuard>
      }
    />
  );
}

export function ChargerDeviceCard(props: {
  device: PMBSchemas["Device"];
  locationId: number;
  availableHardwareModels: PMBSchemas["DeviceModel"][];
  revalidateView: () => void;
}) {
  const { device } = props;

  return (
    <DeviceCard
      icon={<CreditCard />}
      label={props.device.label!}
      code={props.device.iotHubName!}
      chip={deviceStatusV2.format(device.status)}
      info={
        <Typography variant="body2">
          Hardware:{" "}
          <span style={{ color: "black" }}>{device.hwPlatform.modelName}</span>
        </Typography>
      }
      actions={
        <RoleGuard allowedRoles={["PMB_ADMIN"]}>
          <MoreMenu>
            <MoreMenuDialogItem
              renderDialog={(dialogProps) => {
                return (
                  <UpdateChargerDeviceDialog {...dialogProps} {...props} />
                );
              }}
            >
              Update
            </MoreMenuDialogItem>

            <Divider />

            <MoreMenuDialogItem
              renderDialog={(dialogState) => {
                return (
                  <DeleteDeviceDialog
                    {...dialogState}
                    deviceId={device.id!}
                    revalidateView={props.revalidateView}
                  />
                );
              }}
            >
              Delete
            </MoreMenuDialogItem>
          </MoreMenu>
        </RoleGuard>
      }
    />
  );
}

function MaybeInfoText(props: {
  label: string;
  value?: string | number | boolean;
}) {
  if (!props.value) {
    return null;
  }

  return (
    <Typography variant="body2">
      {props.label}:{" "}
      <span style={{ color: "black" }}>
        {typeof props.value === "boolean" ? "Active" : props.value}
      </span>
    </Typography>
  );
}

export function ScreenDeviceCard(props: {
  device: PMBSchemas["Device"];
  locationId: number;
  allowDelete?: boolean;
  revalidateView: () => void;
  deviceModelsByCategory: Record<
    PMBSchemas["DeviceModelCategory"],
    PMBSchemas["DeviceModel"][]
  >;
}) {
  const { device, allowDelete = true } = props;

  return (
    <DeviceCard
      icon={<Monitor />}
      label={props.device.label!}
      code={props.device.iotHubName!}
      chip={deviceStatusV2.format(device.status)}
      info={
        <>
          <MaybeInfoText label="Hardware" value={device.hwPlatform.modelName} />
          <MaybeInfoText label="Taps" value={device.tapCount} />
          <MaybeInfoText label="Valve" value={device.valveType?.modelName} />
          <MaybeInfoText
            label="Flowmeter"
            value={device.flowmeterType?.modelName}
          />
          <MaybeInfoText label="RFID Reader" value={!!device.rfidReaderType} />
          <MaybeInfoText label="QR Scanner" value={!!device.qrScannerType} />
          <MaybeInfoText
            label="Payment Terminal"
            value={!!device.qrScannerType}
          />
        </>
      }
      actions={
        <RoleGuard allowedRoles={["PMB_ADMIN"]}>
          <MoreMenu>
            <MoreMenuDialogItem
              renderDialog={(dialogProps) => {
                return <UpdateScreenDeviceDialog {...dialogProps} {...props} />;
              }}
            >
              Update
            </MoreMenuDialogItem>

            {allowDelete && [
              <Divider key="divider" />,
              <MoreMenuDialogItem
                key="delete"
                renderDialog={(dialogState) => {
                  return (
                    <DeleteDeviceDialog
                      {...dialogState}
                      deviceId={device.id!}
                      revalidateView={props.revalidateView}
                    />
                  );
                }}
              >
                Delete
              </MoreMenuDialogItem>,
            ]}
          </MoreMenu>
        </RoleGuard>
      }
    />
  );
}
