import { TypographyOptions } from "@mui/material/styles/createTypography";

const typography: TypographyOptions = {
  fontFamily: [
    "Arial",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "Nunito",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  fontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    color: "#132644",
    fontStyle: "normal",
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "38.4px",
    letterSpacing: "-0.219px",
  },
  h2: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "33.6px",
    letterSpacing: "-0.233px",
  },
  h3: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "28.8px",
  },
  h4: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0.041px",
  },
  h5: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
  },
  h6: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20.4px",
    letterSpacing: "0.06px",
  },
  body1: {
    color: "#132644",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0.079px",
  },
  body2: {
    color: "#6B6B6B",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.1px",
  },
  subtitle1: {
    color: "#6B6B6B",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "0.1px",
  },
  subtitle2: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "21px",
    letterSpacing: "0.07px",
  },
  caption: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "19.92px",
    letterSpacing: "0.3px",
  },
  overline: {
    color: "#263238",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "31px",
    letterSpacing: "0.83px",
    textTransform: "uppercase",
  },
  button: {
    textTransform: "none",
    minHeight: "32px",
  },
};

export default typography;
