import { useState } from "react";
import { PMBCompany, PMBLocationBase, PMBSchemas } from "../../client/types";
import OnboardingTemplate from "./onboarding-template";
import { useClient } from "../../client";
import { CompanyAddressModal, LocationAddressModal } from "./address-modal";
import { OnboardingStep } from "../../hooks/useOnboarding";
import { ConfirmationErrorCard } from "../../ui/confirmation-info";
import SubscriptionTemplate from "./subscription-template";
import MiddleCardTemplate from "./middle-card-template";

function CompanyOnboarding(props: {
  company: PMBCompany;
  handleNext: () => void;
  onSuccessUpdate: () => void;
}) {
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [company, setCompany] = useState<PMBCompany>(props.company);

  const toggleModal = () => {
    setModalOpened((prev) => !prev);
  };

  const updateCompany = (company: PMBCompany) => {
    setCompany(company);
  };

  if (modalOpened) {
    return (
      <CompanyAddressModal
        company={company}
        modalOpened={modalOpened}
        toggleModal={toggleModal}
        updateCompany={updateCompany}
        onSuccessUpdate={props.onSuccessUpdate}
      />
    );
  }

  return (
    <>
      <OnboardingTemplate
        title={"Confirm company address"}
        subtitle={company.name}
        content={[
          "Are your company details correct? If not, edit them please.",
        ]}
        address={company.address}
        btnName="Confirm"
        handleNext={props.handleNext}
        handleEdit={toggleModal}
      />
    </>
  );
}

function LocationOnboarding(props: {
  location: PMBLocationBase;
  handleNext: () => void;
  onSuccessUpdate: () => void;
}) {
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [location, setLocation] = useState<PMBLocationBase>(props.location);

  const updateLocation = (location: PMBLocationBase) => {
    setLocation(location);
  };

  const toggleModal = () => {
    setModalOpened((prev) => !prev);
  };

  if (modalOpened) {
    return (
      <LocationAddressModal
        location={location}
        modalOpened={modalOpened}
        toggleModal={toggleModal}
        updateLocation={updateLocation}
        onSuccessUpdate={props.onSuccessUpdate}
      />
    );
  }

  return (
    <>
      <OnboardingTemplate
        title={"Confirm location address"}
        subtitle={location.name}
        content={[
          "Are your location details correct? If not, edit them please.",
        ]}
        address={location.address}
        btnName="Confirm"
        handleNext={props.handleNext}
        handleEdit={toggleModal}
      />
    </>
  );
}

export function SubscriptionOnboarding(props: {
  location: PMBSchemas["LocationBase"];
  subscription: PMBSchemas["Subscription"];
  handleNext: () => void;
}) {
  const client = useClient();
  const [frequency, setFrequency] =
    useState<PMBSchemas["Subscription"]["paymentFrequency"]>("MONTHLY");

  const confirmSubscription = async () => {
    try {
      const { data, error } = await client.PATCH(
        "/locations/{locationId}/subscription/confirm",
        {
          params: {
            path: { locationId: props.subscription.locationId },
          },
          body: { paymentFrequency: frequency },
        },
      );
      if (error) {
        alert("Can not confirm subscription. Error: " + error.message);
        return;
      }
      if (data && data.status !== "PENDING_ACTIVATION") {
        props.handleNext();
      } else {
        alert("Subscription is not confirmed.");
      }
    } catch (error) {
      console.error(error);
      alert("Catch error, Can not confirm subscription.");
    }
  };

  const updateFrequency = (
    value: PMBSchemas["Subscription"]["paymentFrequency"],
  ) => {
    setFrequency(value);
  };

  return (
    <>
      <SubscriptionTemplate
        location={props.location}
        subscription={props.subscription}
        handleFrequency={updateFrequency}
        handleConfirmation={confirmSubscription}
      />
    </>
  );
}

export function OnboardingProcess(props: {
  step: OnboardingStep;
  handleDataChange: () => void;
}) {
  const client = useClient();

  const confirmCompany = async (company: PMBSchemas["Company"]) => {
    try {
      const { data, error } = await client.PATCH(
        "/companies/{companyId}/confirm",
        {
          params: { path: { companyId: company.id } },
        },
      );
      if (error) {
        alert("Can not confirm location. Error: " + error.message);
        return;
      }
      if (data && data.isConfirmed) {
        props.handleDataChange();
      }
    } catch (error) {
      console.error("Error while confirming company:", error);
      throw error;
    }
  };

  const confirmLocation = async (location: PMBSchemas["LocationBase"]) => {
    try {
      const { data, error } = await client.PATCH(
        "/locations/{locationId}/confirm",
        {
          params: {
            path: { locationId: location.id },
          },
        },
      );
      if (error) {
        alert("Can not confirm location. Error: " + error.message);
        return;
      }
      if (data && data.isConfirmed) {
        props.handleDataChange();
      }
    } catch (error) {
      console.error("Error while confirming location:", error);
      throw error;
    }
  };

  const confirmTos = async (
    company: PMBSchemas["Company"],
    termsOfService: PMBSchemas["Tos"],
  ) => {
    try {
      const { data, error } = await client.PATCH(
        "/companies/{companyId}/accept-tos/{tosId}",
        {
          params: {
            path: {
              companyId: company.id,
              tosId: termsOfService.id,
            },
          },
        },
      );
      if (error) {
        alert("Can not confirm TOS. Error: " + error.message);
        return;
      }
      if (data && data.isLatestTosAccepted) {
        props.handleDataChange();
      }
    } catch (error) {
      console.error("Error while confirming TOS:", error);
      throw error;
    }
  };

  if (props.step.type === "DETAILS") {
    return (
      <OnboardingTemplate
        title={"Details update and confirmation"}
        content={[
          "To ensure the accuracy of your information on PourMyBev app, we kindly request you to confirm a few details.",
          "Your cooperation in maintaining updated information is greatly appreciated.",
          "Thank you for choosing PourMyBev.",
        ]}
        btnName="Next"
        handleNext={props.handleDataChange}
      />
    );
  }

  if (props.step.type === "COMPANY") {
    return (
      <CompanyOnboarding
        company={props.step.company}
        onSuccessUpdate={props.handleDataChange}
        handleNext={() =>
          props.step.type === "COMPANY" && confirmCompany(props.step.company)
        }
      />
    );
  }

  if (props.step.type === "LOCATION") {
    return (
      <LocationOnboarding
        key={props.step.location.id}
        location={props.step.location}
        onSuccessUpdate={props.handleDataChange}
        handleNext={() =>
          props.step.type === "LOCATION" && confirmLocation(props.step.location)
        }
      />
    );
  }

  if (props.step.type === "TERMS_OF_SERVICE") {
    return (
      <OnboardingTemplate
        title={"Terms of service"}
        content={{
          html: `<p>Please confirm the updated Terms of Service below.</p>${props.step.tos.text}`,
        }}
        btnName="Confirm"
        handleNext={() =>
          props.step.type === "TERMS_OF_SERVICE" &&
          confirmTos(props.step.company, props.step.tos)
        }
      />
    );
  }

  if (props.step.type === "SUBSCRIPTION") {
    return (
      <SubscriptionOnboarding
        location={props.step.location}
        subscription={props.step.subscription}
        handleNext={props.handleDataChange}
      />
    );
  }

  if (props.step.type === "SUCCESS") {
    return (
      <OnboardingTemplate
        title={"Your details are up to date"}
        content={[
          "All details are now current and accurate. Thanks for confirming.",
        ]}
        btnName="Proceed to the app"
        handleNext={props.handleDataChange}
      />
    );
  }

  return (
    <>
      {props.step.type === "CANNOT_ONBOARD" ? (
        <MiddleCardTemplate maxWidth="600px" overflow="auto">
          <ConfirmationErrorCard
            entitySingular="Entity"
            contact={props.step.primaryContact}
          />
        </MiddleCardTemplate>
      ) : null}
    </>
  );
}
