import { Grid, Divider, Typography } from "@mui/material";
import { ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import { BreadcrumbData, PmbBreadcrumb } from "../components/Breadcrumbs";

/**
 * View Header sets page title, h1 heading, and navigational breadcrumb.
 *
 * Should be used on simple list-based pages.
 */
export function ViewHeader(props: {
  /**
   * View and page title. This will be set as h1.
   */
  title: string;

  /**
   * Breadcrumb array of specific type and data.
   */
  breadcrumb?: BreadcrumbData[];

  /**
   * Optionally, provide a call to action button which will be rendered on the
   * right-hand side of the heading.
   */
  button?: ReactNode;
}) {
  const { title } = props;

  return (
    <div>
      <Helmet title={title} />

      <Grid justifyContent="space-between" container spacing={2}>
        <Grid item>
          <Typography
            variant="h3"
            component="h1"
            // Note that the lineHeight here is important as it makes sure
            // that the title lines up with the button on the right, and
            // if the button is not preset, it still maintains its correct
            // vertical height.
            lineHeight={2}
          >
            {title}
          </Typography>
        </Grid>

        {props.button && <Grid item>{props.button}</Grid>}
      </Grid>

      <PmbBreadcrumb data={props.breadcrumb} />

      <Divider sx={{ marginBottom: 8 }} />
    </div>
  );
}
