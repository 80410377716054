import { Autocomplete, TextField } from "@mui/material";

export default function AutocompleteMultiSelect<T extends string>(props: {
  label: string;
  placeholder: string;
  value: T[];
  options: T[];
  onChange: (value: T[]) => void;
  getOptionLabel: (option: T) => string;
}) {
  return (
    <Autocomplete
      multiple
      value={props.value}
      options={props.options}
      getOptionLabel={props.getOptionLabel}
      onChange={(_event, selectedValues) => props.onChange(selectedValues)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={props.label}
          placeholder={props.placeholder}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      )}
    />
  );
}
