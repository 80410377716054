import { useEffect } from "react";

const chatbase = {
  chatbotId: "BAj3yPY7WaZ4HoepkzTMH",
  domain: "www.chatbase.co",
};

function createConfigScript() {
  const element = document.createElement("script");
  element.innerText = `window.embeddedChatbotConfig = { chatbotId: "${chatbase.chatbotId}", domain: "${chatbase.domain}" }`;
  return element;
}

function createChatbaseScript() {
  const element = document.createElement("script");
  element.setAttribute("src", "https://www.chatbase.co/embed.min.js");
  element.setAttribute("chatbotId", chatbase.chatbotId);
  element.setAttribute("domain", chatbase.domain);
  return element;
}

/**
 * Conditionally adds the Chatbase chatbot depending on the isEnabled flag.
 *
 * This is necessary, as we only want to add the chatbot for some users.
 */
export function useChatbase(isEnabled: boolean) {
  useEffect(() => {
    if (isEnabled) {
      const configScript = createConfigScript();
      const chatbaseScript = createChatbaseScript();
      document.body.append(configScript, chatbaseScript);

      // We remove the scripts if chatbase was previously enabled. To make
      // more robust, the actual chatbot window should actually be removed
      // or disabled. I have not found a way to do that via documented
      // APIs, but it's currently not a problem, because we always refresh
      // the page upon logout, so it is not an issue.
      return () => {
        configScript?.remove();
        chatbaseScript?.remove();
      };
    }
  }, [isEnabled]);
}
