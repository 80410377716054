import { Box, TextField } from "@mui/material";
import { useFormik } from "formik";
import { object, string } from "yup";
import { unpackResponse, useClient } from "../client";
import { caughtValueToString } from "../utils/caught-error";
import { FormDialog } from "./form-dialog";
import { getTextFieldProps } from "./text-field";

export function EditLocationNameDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  location: {
    id: number;
    name: string;
    customerNumber: string;
  };

  /**
   * Determines whether the customer number field will be rendered
   * and whether it will be required by validation.
   */
  userCanUpdateCustomerNumber: boolean;
}) {
  const client = useClient();
  const { location, userCanUpdateCustomerNumber } = props;
  const formik = useFormik({
    initialValues: {
      name: location.name,
      customerNumber: userCanUpdateCustomerNumber
        ? location.customerNumber
        : undefined,
    },
    validationSchema: object().shape({
      name: string().required("Location name cannot be empty."),
      customerNumber: props.userCanUpdateCustomerNumber
        ? string().required("Customer ID cannot be empty")
        : string().optional(),
    }),
    async onSubmit(values) {
      try {
        await unpackResponse(
          client.PATCH("/locations/{locationId}", {
            params: { path: { locationId: props.location.id } },
            body: values,
          }),
        );

        props.onClose();
        props.onSuccess();
      } catch (error) {
        formik.setStatus(caughtValueToString(error));
      }
    },
  });

  return (
    <FormDialog
      {...props}
      title="Edit location name"
      submitLabel="Save"
      form={formik}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <TextField
          {...getTextFieldProps(formik, "name")}
          label="Location name"
          variant="standard"
          fullWidth
        />

        {props.userCanUpdateCustomerNumber && (
          <TextField
            {...getTextFieldProps(formik, "customerNumber")}
            label="Customer ID"
            variant="standard"
            fullWidth
          />
        )}
      </Box>
    </FormDialog>
  );
}
