import { PMBSchemas } from "../client/types";
import { EnumFormatter } from "../utils/enum-formatter";

export const locationStatus = new EnumFormatter<PMBSchemas["LocationStatus"]>({
  PENDING_INSTALLATION: "Pending Installation",
  PENDING_OPENING: "Pending Opening",
  ACTIVE: "Active",
  CLOSED: "Deactivated",
  NOT_SUPPORTED: "Not Supported",
  LEGACY: "Legacy",
});
