import { PMBSchemas, PMBStates } from "../client/types";
import { groupBy } from "../utils/common";
import { EnumFormatter } from "../utils/enum-formatter";
import { countryRequiresState } from "./countries";

export const stateFormatter = new EnumFormatter<PMBStates>({
  ALABAMA: "Alabama",
  ALASKA: "Alaska",
  ALBERTA: "Alberta",
  AMERICAN_SAMOA: "American Samoa",
  ARIZONA: "Arizona",
  ARKANSAS: "Arkansas",
  BRITISH_COLUMBIA: "British Columbia",
  CALIFORNIA: "California",
  COLORADO: "Colorado",
  CONNECTICUT: "Connecticut",
  DELAWARE: "Delaware",
  DISTRICT_OF_COLUMBIA: "District of Columbia",
  FEDERATED_STATES_OF_MICRONESIA: "Federated States of Micronesia",
  FLORIDA: "Florida",
  GEORGIA: "Georgia",
  GUAM: "Guam",
  HAWAII: "Hawaii",
  IDAHO: "Idaho",
  ILLINOIS: "Illinois",
  INDIANA: "Indiana",
  IOWA: "Iowa",
  KANSAS: "Kansas",
  KENTUCKY: "Kentucky",
  LOUISIANA: "Louisiana",
  MAINE: "Maine",
  MANITOBA: "Manitoba",
  MARSHALL_ISLANDS: "Marshall Islands",
  MARYLAND: "Maryland",
  MASSACHUSETTS: "Massachusetts",
  MICHIGAN: "Michigan",
  MINNESOTA: "Minnesota",
  MISSISSIPPI: "Mississippi",
  MISSOURI: "Missouri",
  MONTANA: "Montana",
  NEBRASKA: "Nebraska",
  NEVADA: "Nevada",
  NEW_BRUNSWICK: "New Brunswick",
  NEW_HAMPSHIRE: "New Hampshire",
  NEW_JERSEY: "New Jersey",
  NEW_MEXICO: "New Mexico",
  NEW_YORK: "New York",
  NEWFOUNDLAND_AND_LABRADOR: "Newfoundland and Labrador",
  NORTH_CAROLINA: "North Carolina",
  NORTH_DAKOTA: "North Dakota",
  NORTHERN_MARIANA_ISLANDS: "Northern Mariana Islands",
  NORTHWEST_TERRITORIES: "Northwest Territories",
  NOVA_SCOTIA: "Nova Scotia",
  OHIO: "Ohio",
  OKLAHOMA: "Oklahoma",
  ONTARIO: "Ontario",
  OREGON: "Oregon",
  PALAU: "Palau",
  PENNSYLVANIA: "Pennsylvania",
  PRINCE_EDWARD_ISLAND: "Prince Edward Island",
  PUERTO_RICO: "Puerto Rico",
  QUEBEC: "Quebec",
  RHODE_ISLAND: "Rhode Island",
  SASKATCHEWAN: "Saskatchewan",
  SOUTH_CAROLINA: "South Carolina",
  SOUTH_DAKOTA: "South Dakota",
  TENNESSEE: "Tennessee",
  TEXAS: "Texas",
  UTAH: "Utah",
  VERMONT: "Vermont",
  VIRGIN_ISLANDS: "Virgin Islands",
  VIRGINIA: "Virginia",
  WASHINGTON: "Washington",
  WEST_VIRGINIA: "West Virginia",
  WISCONSIN: "Wisconsin",
  WYOMING: "Wyoming",
  YUKON: "Yukon",

  N_A: "Unknown",
});

const canadianProvincesCodes = [
  "ALBERTA",
  "BRITISH_COLUMBIA",
  "MANITOBA",
  "NEW_BRUNSWICK",
  "NEWFOUNDLAND_AND_LABRADOR",
  "NORTHWEST_TERRITORIES",
  "NOVA_SCOTIA",
  "ONTARIO",
  "PRINCE_EDWARD_ISLAND",
  "QUEBEC",
  "SASKATCHEWAN",
  "YUKON",
];

const { usaStates, canadianProvinces } = groupBy(
  stateFormatter.enums,
  (key) => {
    if (canadianProvincesCodes.includes(key)) {
      return "canadianProvinces";
    }

    return "usaStates";
  },
);

export function getStatesOrProvincesByCountry(
  country: PMBSchemas["CountryCodeEnum"],
) {
  if (countryRequiresState(country)) {
    return country === "USA" ? usaStates : canadianProvinces;
  }

  return [];
}

export function stateEnumToLabel(state: PMBStates) {
  if (state === "N_A") {
    return null;
  }

  return stateFormatter.get(state);
}
