import { Error } from "@mui/icons-material";
import {
  Alert,
  Button,
  ButtonProps,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { FormEventHandler, ReactNode, useId } from "react";

/**
 * Renders a Confirm Dialog.
 *
 * This component should be used when an action (typically an async request to
 * the server) needs to be confirmed by the user.
 *
 * After submission completes, the dialog will be automatically dismissed.
 */
export function FormDialog(props: {
  title: string;
  padding?: number;
  children: ReactNode;
  isOpen: boolean;
  maxWidth?: DialogProps["maxWidth"];
  buttonVariant?: ButtonProps["variant"];

  /**
   * The label of the primary call to action button. Should be a _directive_,
   * for example "Send", "Delete", "Create", etc...
   *
   * If possible try to avoid the word "Submit" as it is needlessly vague and
   * technical. A more concrete term is always better!
   */
  submitLabel: string;

  /**
   * Note that this is only a subset of the available colors. Notably, the
   * `"info"` option is missing to make sure that there is always a difference
   * between the main call to action and the dismiss button.
   *
   * @default "primary"
   */
  submitColor?: "primary" | "secondary" | "success" | "error" | "warning";

  onClose: () => void;

  /**
   * The form object holds the dialog's form state and handlers. Generally,
   * you probably want to pass here the return value of `useFormik`, but
   * it can be used with other/custom implementations if necessary.
   */
  form: {
    status?: string;
    isSubmitting: boolean;
    handleSubmit: FormEventHandler<HTMLFormElement>;
  };
}) {
  const {
    isOpen,
    onClose,
    title,
    children,
    submitLabel,
    submitColor = "primary",
    form,
  } = props;
  const titleId = useId();

  const closeUnlessSubmitting = () => {
    if (!form.isSubmitting) {
      onClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeUnlessSubmitting}
      fullWidth
      maxWidth={props.maxWidth}
      aria-labelledby={titleId}
    >
      <form onSubmit={form.handleSubmit} noValidate>
        <DialogTitle id={titleId} variant="h3" sx={{ padding: props.padding }}>
          {title}
        </DialogTitle>

        <DialogContent sx={{ paddingX: props.padding }}>
          {children}

          {form.status && (
            <Alert severity="error" sx={{ marginTop: 4 }}>
              {form.status}
            </Alert>
          )}
        </DialogContent>

        <DialogActions
          disableSpacing
          sx={{
            position: "sticky",
            bottom: 0,
            background: "linear-gradient(hsl(0 0% 100% / 0), white)",
            paddingX: props.padding,
            paddingBottom: props.padding,
            gap: 2,
          }}
        >
          {form.isSubmitting ? (
            <CircularProgress size="1em" sx={{ marginRight: 2 }} />
          ) : form.status ? (
            <Tooltip title={form.status}>
              <Error color="error" />
            </Tooltip>
          ) : null}

          <Button
            variant={props.buttonVariant ?? "text"}
            color="info"
            onClick={closeUnlessSubmitting}
            disabled={form.isSubmitting}
            autoFocus
          >
            Dismiss
          </Button>

          <Button
            variant={props.buttonVariant ?? "text"}
            color={submitColor}
            type="submit"
            disabled={form.isSubmitting}
          >
            {submitLabel}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
