import styled from "@emotion/styled";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Palette as PaletteIcon } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Drawer,
  Grid,
  ListItemButton,
  Fab as MuiFab,
} from "@mui/material";

const Fab = styled(MuiFab)`
  position: fixed;
  right: ${(props) => props.theme.spacing(8)};
  bottom: ${(props) => props.theme.spacing(8)};
  z-index: 1;
`;

const Wrapper = styled.div`
  width: 258px;
  overflow-x: hidden;
`;

const Heading = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;

function Demos() {
  return (
    <Wrapper>
      <Heading>Select a demo</Heading>

      <Box px={4} my={3}>
        <Alert icon={false} severity="info">
          <strong>Hello!</strong> Select your style below. Choose the ones that
          best fits your needs.
        </Alert>
      </Box>

      <Box px={4} my={3}>
        <Grid container spacing={3}></Grid>
      </Box>

      <Box my={3} mx={4}>
        <Button
          component={Link}
          to="/documentation/welcome"
          variant="outlined"
          size="large"
          target="_blank"
          fullWidth={true}
        >
          Documentation
        </Button>
      </Box>
      <Box my={3} mx={4}>
        <Button
          href="https://mui.com/store/items/mira-pro-react-material-admin-dashboard/"
          variant="contained"
          color="primary"
          size="large"
          target="_blank"
          fullWidth={true}
        >
          Get Mira Pro
        </Button>
      </Box>
    </Wrapper>
  );
}

function Settings() {
  const paletteIconVisible = false;
  const [state, setState] = useState({
    isOpen: false,
  });

  const toggleDrawer = (open: boolean) => () => {
    setState({ ...state, isOpen: open });
  };

  return (
    <React.Fragment>
      {paletteIconVisible ?? (
        <Fab color="primary" aria-label="Edit" onClick={toggleDrawer(true)}>
          <PaletteIcon />
        </Fab>
      )}

      <Drawer anchor="right" open={state.isOpen} onClose={toggleDrawer(false)}>
        <Demos />
      </Drawer>
    </React.Fragment>
  );
}

export default Settings;
