/**
 * This is a placeholder function that should be used in places where actual
 * implementation is not yet ready.
 *
 * The advantage of reusing this function instead of defining new placeholders
 * every time is to make it easy to trace back where these placeholders are
 * currently being used so that they can be safely removed before the production
 * release.
 */
export function notImplemented() {
  window.alert("Not yet implemented");
}

/**
 * A promise that waits for supplied number of milliseconds before resolving.
 *
 * This is useful when testing, for example, pending states, where it is helpful
 * to arteficially slow down the response time during development to make sure
 * everything behaves as it should.
 */
export function sleep(ms: number) {
  return new Promise<void>((resolve) => setTimeout(resolve, ms));
}

/**
 * We intentionally wait for at least 3s for this request, to give Stripe webhooks
 * a chance to deliver to our backend before we complete this action and revalidate
 * the underlying view.
 *
 * Note that the webhooks speed is not guaranteed, but based on our testing, a 3s
 * delay has been sufficient to make sure the webhooks arrive before revalidation
 * occurs.
 */
export function waitForStripeWebhooks() {
  return sleep(3000);
}
